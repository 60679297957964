import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import DataTable from "../Common/ReusableComponents/DataTable";
import Spinner from "../Common/ReusableComponents/Spinner";
import utility from "../../classes/utility";
import Config from "../../classes/config";
import Grid from "@mui/material/Grid";
import { Workbook } from "exceljs";
import { saveAs } from "file-saver-es";
import { exportDataGrid } from "devextreme/excel_exporter";
import ExportLogo from "../../images/Export.svg";
// import ViewMoreDialog from "../Common/ReusableComponents/ViewMore";

const AdminUsers = () => {
  const navigate = useNavigate();
  const [items, setItems] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  // const [showMoreAlert, setShowMoreAlert] = React.useState(false);
  // const [moreItem, setMoreItem] = React.useState([] as any);
  // const [morealertMessage, setMorealertMessage] = React.useState("");
  const dataGridRef = useRef(null);

  const columns = [
    {
      field: "id",
      title: "ID",
      type: "string",
      allowHiding: false,
    },
    {
      field: "fullName",
      title: "User",
      type: "string",
      allowHiding: false,
    },
    {
      field: "email",
      title: "Email",
      type: "string",
      allowHiding: false,
    },
    {
      field: "role",
      title: "Role",
      type: "string",
      allowHiding: true,
    },
    {
      field: "unitwithUnitId",
      title: "Unit/Unit ID",
      type: "html",
      allowHiding: false,
    },
    {
      field: "isActive",
      title: "Status",
      type: "html",
      allowHiding: false,
    },
  ];

  useEffect(() => {
    didMount();
  }, []);

  const didMount = () => {
    setLoading(true);
    getUserData();
  };

  const getUserData = async () => {
    try {
      const data: any = await utility.genericGetAPICall(
        `${Config.userUnitsRoles}`
      );
      let _items: any = [];
      let result = data.filter((a: any) => a.isDeleted === false);
      for (let r of result) {
        const statusHtml = `<Button style="background:${
          r.isActive === true ? "#4E93FC" : "#7E81A5"
        }; border: none;" class="datatable dummyButtons">${
          r.isActive === true ? "Active" : "InActive"
        }</Button>`;
        const unitHtml = `<p>${r.userUnits
          .map((u: any) => u.title)
          .join(", ")}</p><p class='subText'>${r.id}</p>`;
        _items.push({
          id: r.id,
          fullName: r.fullName,
          email: r.email,
          userUnits: r.userUnits,
          userRoleDetails: r.userRoleDetails,
          updatedBy: r.updatedByFullName,
          updatedOn: r.updatedOn,
          createdBy: r.createdBy,
          createdOn: r.createdOn,
          isActive: statusHtml,
          unitwithUnitId: unitHtml,
          role: r.userRoleDetails.map((u: any) => u.title).join(", "),
        });
      }
      _items = utility._sortItems(_items, "id", false);

      setItems(_items);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const handleTableActionsEdit = (action: any) => {
    navigate(`/AdminUserAddEdit?ID=${action.id}`);
  };

  const handleTableActionsDelete = (action: any) => {
    navigate(`/AdminUserAddEdit?ID=${action}`);
  };

  const handleExportButtonClick = async () => {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet("Main sheet");

    if (dataGridRef.current) {
      try {
        await exportDataGrid({
          component: (dataGridRef.current as any).instance,
          worksheet: worksheet,
        });
        const buffer = await workbook.xlsx.writeBuffer();
        saveAs(
          new Blob([buffer], { type: "application/octet-stream" }),
          "AdminUsers.xlsx"
        );
      } catch (error) {
        console.error("Error during export:", error);
      }
    }
  };

  // const subMenu = [
  //   {
  //     name: "Edit",
  //     link: "/AdminUserAddEdit?ID=" + moreItem.id,
  //   },
  //   {
  //     name: "Delete",
  //     link: "/AdminUserAddEdit?ID=" + moreItem.id,
  //   },
  // ];

  // const OnViewMoreClose = () => {
  //   setMorealertMessage("");
  //   setShowMoreAlert(false);
  // };

  // const MoreItemDialog = (item: any) => {
  //   setShowMoreAlert(true);
  //   setMoreItem(item);
  // };

  // const MoreItemPopUp = () => {
  //   return (
  //     <ViewMoreDialog
  //       showDialog={showMoreAlert}
  //       message={morealertMessage}
  //       title={""}
  //       onClose={() => OnViewMoreClose()}
  //       menuItems={subMenu}
  //     />
  //   );
  // };

  return (
    <div className="">
      {/* {showMoreAlert && MoreItemPopUp()} */}
      {!loading ? (
        <>
          <div className="search-container">
            <Grid container spacing={3}>
              <Grid item container xs={12} sm={9} spacing={2}></Grid>
              <Grid item xs={12} sm={3} container className="filter-buttons">
                <Grid item>
                  <button
                    className="btn btn-export"
                    onClick={handleExportButtonClick}
                  >
                    <img src={ExportLogo} alt="" />
                    Export
                  </button>
                </Grid>
              </Grid>
              {/* </div> */}
            </Grid>
          </div>
          <div className="adminUser-container">
            <DataTable
              style={{ width: "100%" }}
              filterable={false}
              groupable={false}
              rows={items}
              columns={columns}
              selectionMode={false}
              showHideColumnMenu={false}
              hideSearchPanel={true}
              hideExport={true}
              //moreItem={(a: any) => MoreItemDialog(a)}
              deleteItem={(a: any) => handleTableActionsDelete(a)}
              editItem={(a: any) => handleTableActionsEdit(a)}
              showCommandBtn={false}
              showMoreBtn={true}
              hideCommandViewBtn={true}
              dataGridRef={dataGridRef}
              allowExportSelectedData={false}
              showDetailTable={false}
            />
          </div>
        </>
      ) : (
        <Spinner size="large" />
      )}
    </div>
  );
};

export default AdminUsers;
