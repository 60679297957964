import React, { useEffect, useState, useRef } from "react";

import DataGrid, {
  Column,
  Export,
  Selection,
  SearchPanel,
  Sorting,
  GroupPanel,
  Grouping,
  FilterRow,
  HeaderFilter,
  FilterPanel,
  ColumnFixing,
  Pager,
  Paging,
  ColumnChooser,
  Editing,
  MasterDetail,
  Form,
  Lookup,
} from "devextreme-react/data-grid";
import { SpeedDialAction } from "devextreme-react/speed-dial-action";
import { Workbook } from "exceljs";
import { saveAs } from "file-saver-es";
import "../../../css/DataTable.css";
import Popup from "devextreme-react/popup";

// Our demo infrastructure requires us to use 'file-saver-es'. We recommend that you use the official 'file-saver' package in your applications.
import { exportDataGrid } from "devextreme/excel_exporter";
import ContextMenu from "devextreme/ui/context_menu";
import { ReactComponent as DeleteIcon } from "../../../images/TrashIcon.svg";
import { ReactComponent as EditIcon } from "../../../images/EditIcon.svg";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import "devextreme/dist/css/dx.light.css";

const allowedPageSizes = [10, 20, 40, 80];

interface DataTableProps {
  style?: any;
  rows: any[];
  columns: any[];
  id?: string;
  showCommandBtn?: boolean;
  showEditPlantBtn?: boolean;
  hideCommandDeleteBtn?: boolean;
  hideCommandEditBtn?: boolean;
  hideCommandViewBtn?: boolean;
  hideNewBtn?: boolean;
  showNewBtn?: boolean;
  showHeaderNewBtn?: boolean;
  editPopUp?: any;
  moreItem?: any;
  editItem?: any;
  deleteItem?: any;
  insertRow?: any;
  updateRow?: any;
  deleteRow?: any;
  showDetailTable?: boolean;
  detailColumns?: any;
  filterable?: boolean;
  groupable?: boolean;
  showHideColumnMenu?: boolean;
  showFilterPanel?: boolean;
  columnChooser?: string;
  fileName?: string;
  selectionMode?: boolean;
  callAfterInit?: boolean;
  stopCommsAfterInit?: any;
  addNewPage?: string;
  allowExportSelectedData?: boolean;
  hideExport?: boolean;
  hideSearchPanel?: boolean;
  displayMode?: any;
  currentFilter?: any;
  dataGridRef?: React.RefObject<DataGrid>;
  showMoreBtn?: boolean;
}

const DataTable = (props: DataTableProps) => {
  const [data, setData] = useState<any[]>(props.rows);
  const [columns, setColumns] = useState<any[]>(props.columns);
  const [showCommandBtn, setShowCommandBtn] = useState<boolean>(
    props.showCommandBtn ?? false
  );
  // const [hideCommandDeleteBtn, setHideCommandDeleteBtn] = useState<boolean>(
  //   props.hideCommandDeleteBtn ?? false
  // );
  // const [hideCommandEditBtn, setHideCommandEditBtn] = useState<boolean>(
  //   props.hideCommandEditBtn ?? false
  // );
  const [showEditPlantBtn, setShowEditPlantBtn] = useState<boolean>(false);
  const [currentFilter, setCurrentFilter] = useState<any>("auto");
  const [showPageSizeSelector, setShowPageSizeSelector] =
    useState<boolean>(true);
  const [showInfo, setShowInfo] = useState<boolean>(true);
  const [showNavButtons, setShowNavButtons] = useState<boolean>(true);
  const [showFilterRow, setShowFilterRow] = useState<boolean>(true);
  const [showHeaderFilter, setShowHeaderFilter] = useState<boolean>(true);
  const [showMoreBtn, setShowMoreBtn] = useState<boolean>(
    props.showMoreBtn ?? false
  );
  // const [showNewBtn, setShowNewBtn] = useState<boolean>(false);
  // const [mode, setMode] = useState<string>("widget");
  // const [gridWidth, setGridWidth] = useState<string>(
  //   window.innerWidth - 88 + "px"
  // );
  const [filterValue, setFilterValue] = useState<any[]>([]);
  const [searchValue, setSearchValue] = useState<string>("");
  // const [loadMasterDetail, setLoadMasterDetail] = useState<boolean>(true);

  // const [moreAlertMessage, setMoreAlertMessage] = useState<
  //   JSX.Element | string
  // >("");
  // const [displayMode, setDisplayMode] = useState<string>("full");
  // const [showMoreAlert, setShowMoreAlert] = useState<boolean>(false);
  const [columnList, setColumnList] = useState<any[]>([]);
  // const [loading, setLoading] = useState<boolean>(false);
  // const dataGridRef = useRef<DataGrid>(null);
  const selectionMode = props.selectionMode ? "multiple" : "single";
  const [isNarrow, setIsNarrow] = useState<boolean>(false);

  let filterValueStorage = localStorage.getItem("datagridFilterValue");
  let searchPanelValueStorage = localStorage.getItem(
    "datagridSearchPanelValue"
  );

  useEffect(() => {
    if (filterValueStorage) {
      let tempFilterValue = JSON.parse(filterValueStorage);

      if (tempFilterValue.listName === props.fileName) {
        setFilterValue(JSON.parse(tempFilterValue.value));
      } else {
        setFilterValue([]);
        localStorage.removeItem("datagridFilterValue");
      }
    }
    if (searchPanelValueStorage) {
      let tempSearchPanelValueStorageValue = JSON.parse(
        searchPanelValueStorage
      );
      if (tempSearchPanelValueStorageValue.listName === props.fileName) {
        setSearchValue(tempSearchPanelValueStorageValue.searchValue);
      } else {
        setSearchValue("");
        localStorage.removeItem("datagridSearchPanelValue");
      }
    }
  }, [filterValueStorage, searchPanelValueStorage, props.fileName]);

  useEffect(() => {
    const afterInit = () => {
      setData(props.rows);
      setColumns(props.columns);
      setShowCommandBtn(props.showCommandBtn ? props.showCommandBtn : false);
      // setHideCommandDeleteBtn(
      //   props.hideCommandDeleteBtn ? props.hideCommandDeleteBtn : false
      // );
      // setHideCommandEditBtn(
      //   props.hideCommandEditBtn ? props.hideCommandEditBtn : false
      // );
      //showNewBtn(true);
      props.stopCommsAfterInit();
    };

    if (props.callAfterInit) {
      afterInit();
    }
  }, [props.callAfterInit]);

  const Cell = (props: any, item: any) => {
    let URL = window.location.href;
    // let parentURL = URL.split("#");

    if (item.field === "Status") {
      return (
        <div
          className={`dataGridText status-style ${props.text
            .toLowerCase()
            .replaceAll(" ", "-")}`}
          style={{ color: props.text ? props.text : "#333" }}
        >
          {props.text}
        </div>
      );
    } else if (item.type === "html") {
      // Assuming that the HTML content is in the `htmlContent` property
      return (
        <div
          className={"dataGridText"}
          style={{ color: props.text ? props.text : "#333" }}
          dangerouslySetInnerHTML={{ __html: props.text }}
        />
      );
    } else {
      return (
        <div
          className={"dataGridText"}
          style={{ color: props.text ? props.text : "#333" }}
        >
          {props.text}
        </div>
      );
    }
  };

  const CommandCell = (item: any) => {
    // console.log(props.hideCommandViewBtn);
    return (
      <div>
        {!props.hideCommandEditBtn && (
          <EditIcon
            className={"primary dataGridIcon"}
            onClick={() => onClickEdit(item.data)}
          />
        )}
        {!props.hideCommandViewBtn && (
          <MoreVertIcon
            className={"primary dataGridIcon"}
            onClick={() => onClickMore(item.data)}
          />
        )}
        {!props.hideCommandDeleteBtn && (
          <DeleteIcon
            className={"dataTable danger dataGridIcon"}
            onClick={() =>
              props.deleteItem(item.data.id ? item.data.id : item.data)
            }
          />
        )}
      </div>
    );
  };

  // const OnViewMoreClose = () => {
  //   setMoreAlertMessage("");
  //   setShowMoreAlert(true);
  // };

  const onClickMore = (data: any) => {
    props.moreItem(data);
  };

  const onClickEdit = (data: any) => {
    props.editItem(data);
  };

  const EditItem = (props: any) => {
    return (
      <div>
        <EditIcon
          className={"primary"}
          onClick={() => props.editItem(props.data)}
        />
      </div>
    );
  };
  const rowClick = (event: any) => {
    if (props.showCommandBtn) {
      setShowCommandBtn(!showCommandBtn);
    }
    if (props.showEditPlantBtn) {
      setShowEditPlantBtn(!showEditPlantBtn);
    }
  };

  const showNewModal = () => {
    var page = props.addNewPage;
    window.open("#/" + page);
    // props.showNewModal(true);
    // let navigate = React.useNavigate();
  };

  const renderDetailTemplate = (e: any, data?: any, detailColumns?: any) => {
    // return <DetailTemplate {...e} columns={detailColumns} dataSource={data} />;
    return <></>;
  };
  const renderColums = () => {
    // console.log("rendercolumns");
    return (
      columns &&
      columns.map((item: any) => {
        return (
          <Column
            key={item.field}
            dataField={item.field}
            width={item.width}
            caption={item.title}
            dataType={item.type}
            visible={item.visibleColumn}
            visibleIndex={item.visibleIndex}
            allowHiding={item.allowHiding}
            format={
              item.type === "date"
                ? "dd/MM/yyyy"
                : item.type === "datetime"
                ? "dd/MM/yyyy h:mm a"
                : ""
            }
            // cellRender={WOLinkCell}
            cellRender={(e: any) => Cell(e, item)}
            sortOrder={item.sortOrder}
            // editorOptions={{showClearButton:true}}
          >
            {item.type === "select" && (
              <Lookup
                dataSource={item.dataSource}
                valueExpr="Id"
                displayExpr="Title"
              />
            )}
          </Column>
        );
      })
    );
  };
  
  const onExporting = async (e: any, name: any) => {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet("Main sheet");
    try {
      await exportDataGrid({
      component: e.component,
      worksheet: worksheet,
      autoFilterEnabled: true,
    });
    const buffer = await workbook.xlsx.writeBuffer();
        saveAs(
          new Blob([buffer], { type: "application/octet-stream" }),
          `${name}.xlsx`
        );
      } catch (error) {
        console.error("Error exporting data:", error);
      }
      e.cancel = true;
  };

  const onSelectionChanged = (e: any) => {
    const exportSelectedDisabled = !!e.component.getSelectedRowKeys().length;
    const exportMenu = e.element.querySelector(".dx-datagrid-export-menu");
    if (exportMenu) {
      const instance = ContextMenu.getInstance(exportMenu) as ContextMenu;
      instance.option("items[1].disabled", exportSelectedDisabled);
    }
  };
  // const onFilterValueChanged =(e:any)=>{
  //     setState({filterValue:e})
  // }
  const onSearchValueChange = (e: any) => {
    let tempSearchPanelValue: any = {
      listName: props.fileName,
      searchValue: e,
    };
    localStorage.setItem(
      "datagridSearchPanelValue",
      JSON.stringify(tempSearchPanelValue)
    );
    setSearchValue(e);
  };

  const onContentReady = (e: any) => {
    if (e.component.getCombinedFilter()) {
      let flatFilterValue = e.component.getCombinedFilter().join();

      if (flatFilterValue && flatFilterValue.includes(",or,") && !searchValue) {
        localStorage.removeItem("datagridFilterValue");
        localStorage.removeItem("datagridSearchPanelValue");
        e.component.clearFilter();
      }
    }
    // if (e.component.getCombinedFilter()) {
    let filterValue = {
      listName: props.fileName,
      value: e.component.getCombinedFilter()
        ? JSON.stringify(e.component.getCombinedFilter())
        : null,
    };
    localStorage.setItem("datagridFilterValue", JSON.stringify(filterValue));
    // }
    var curentColumns = e.component.getVisibleColumns();
    let columnlist = columnList;
    let tempColumns = props.columns;

    if (columnlist) {
      var modifiedColumn: any;

      if (curentColumns.length > columnlist.length) {
        //Added Column
        modifiedColumn = checkColumnChanged(curentColumns, columnlist);
        const index = tempColumns.findIndex(
          (c: any) => c.field === modifiedColumn
        );
        if (index >= 0) {
          //visibleIndex
          tempColumns[index].visible = true;
          if (props.columnChooser && props.columnChooser !== "") {
            localStorage.setItem(
              props.columnChooser,
              JSON.stringify(tempColumns)
            );
          }
        }
      } else {
        //remove columns
        modifiedColumn = checkColumnChanged(columnlist, curentColumns);

        const index = tempColumns.findIndex(
          (c: any) => c.field === modifiedColumn
        );
        if (index >= 0) {
          tempColumns[index].visible = false;
          if (props.columnChooser && props.columnChooser !== "") {
            localStorage.setItem(
              props.columnChooser,
              JSON.stringify(tempColumns)
            );
          }
        }
      }
      columnlist = e.component.getVisibleColumns();
      setColumnList(columnlist);
    } else {
      columnlist = e.component.getVisibleColumns();
      setColumnList(columnlist);
    }
    const exportSelectedDisabled = !!e.component.getSelectedRowKeys().length;
    const exportMenu = e.element.querySelector(".dx-datagrid-export-menu");
    if (exportMenu) {
      const instance = ContextMenu.getInstance(exportMenu) as ContextMenu;
      instance.option("items[1].disabled", exportSelectedDisabled);
    }
  };

  // this code is for show and hide columns
  const checkColumnChanged = (colList1: any, colList2: any) => {
    for (var i = 0; i < colList1.length; i++) {
      var col = colList1[i].dataField;
      if (col) {
        var foundcol = false;
        for (var a = 0; a < colList2.length; a++) {
          var colOld = colList2[a].dataField;
          if (col === colOld) {
            foundcol = true;
          }
        }
        if (!foundcol) {
          return col;
        }
      }
    }
    return "";
  };

  return (
    <div className={"dataTableDiv"}>
      <link
        rel="stylesheet"
        type="text/css"
        href="https://cdn3.devexpress.com/jslib/22.2.3/css/dx.light.css"
      />
      {data && (
        <>
          <DataGrid
            //  ref={dataGridRef}
            ref={props.dataGridRef}
            // style={{ width: gridWidth }}
            id="gridContainer"
            dataSource={data}
            keyExpr={props.id ? props.id : "id"}
            showBorders={false}
            showColumnLines={false}
            showRowLines={true}
            rowAlternationEnabled={false}
            allowColumnResizing={true}
            allowColumnReordering={true}
            //columnResizingMode={mode ? mode : "widget"}
            columnMinWidth={10}
            columnAutoWidth={true}
            onContentReady={onContentReady}
            onRowDblClick={rowClick}
            onSelectionChanged={onSelectionChanged}
            onExporting={(e: any) => onExporting(e, props.fileName)}
            columnHidingEnabled={false}
            defaultFilterValue={filterValue}
            // onFilterValueChange={(e:any)=>onFilterValueChanged(e)}
            remoteOperations={true}
            hoverStateEnabled={true}
            //hideSearchPanel={props.hideSearchPanel}
            onRowInserted={(e: any) => props.insertRow(e)}
            onRowUpdated={(e: any) => props.updateRow(e)}
            onRowRemoved={(e: any) => props.deleteRow(e)}
          >
            <Sorting mode="multiple" />
            {/* <Scrolling rowRenderingMode='virtual'></Scrolling> */}
            {props.showHideColumnMenu && (
              <ColumnChooser
                enabled={true}
                mode="select"
                allowSearch={true}
                height={400}
              />
            )}
            <ColumnFixing enabled={true} />
            {props.selectionMode && <Selection mode={selectionMode} />}
            {props.showHeaderNewBtn && (
              <Editing
                mode="popup"
                useIcons={true}
                allowAdding={true}
                allowUpdating={true}
                allowDeleting={true}
              >
                <Popup
                  title="Add/Edit"
                  showTitle={true}
                  width={500}
                  height={525}
                />
                <Form>{props.editPopUp()}</Form>
              </Editing>
            )}
            {!props.hideSearchPanel && (
              <SearchPanel
                visible={true}
                width={"100%"}
                defaultText={searchValue}
                onTextChange={(e: any) => onSearchValueChange(e)}
              />
            )}
            {columns && renderColums()}
            {showCommandBtn && props.showCommandBtn && (
              <Column
                caption="Action"
                width={100}
                cellRender={(e) => CommandCell(e)}
                fixed={true}
                fixedPosition={"right"}
                allowHiding={false}
                allowExporting={false}
              />
            )}
            {showEditPlantBtn && props.showEditPlantBtn && (
              <Column
                caption="Edit"
                width={100}
                cellRender={EditItem}
                fixed={true}
                fixedPosition={"right"}
                allowHiding={false}
                allowExporting={false}
              />
            )}
            {showMoreBtn && props.showMoreBtn && (
              <Column
                caption=""
                width={100000}
                cellRender={CommandCell}
                // fixed={true}
                // fixedPosition={'right'}
                allowHiding={true}
                allowExporting={false}
                hidingPriority={0}
              />
            )}
            <Paging defaultPageSize={10} />
            <Pager
              visible={true}
              allowedPageSizes={allowedPageSizes}
              displayMode={props.displayMode}
              showPageSizeSelector={showPageSizeSelector}
              showInfo={showInfo}
              showNavigationButtons={showNavButtons}
            />
            {props.filterable && (
              <FilterRow visible={showFilterRow} applyFilter={currentFilter} />
            )}

            {props.filterable && (
              <FilterPanel visible={props.showFilterPanel} />
            )}
            {props.filterable && <HeaderFilter visible={showHeaderFilter} />}

            {props.groupable && (
              <GroupPanel visible={isNarrow ? false : true} />
            )}
            {props.groupable && <Grouping autoExpandAll={true} />}
            {!props.hideExport && (
              <Export
                enabled={true}
                allowExportSelectedData={props.allowExportSelectedData}
              />
            )}
            {/* {props.customExport && (
            <Button
              text="Export to Excel"
              icon="exportxlsx"
              onClick={props.onExportButtonClick}
            />
          )} */}
            {props.showDetailTable && (
              <MasterDetail
                autoExpandAll={false}
                enabled={props.showNewBtn && !props.hideNewBtn}
                component={(e: any) =>
                  renderDetailTemplate(e, data, props.detailColumns)
                }
              />
            )}
          </DataGrid>
          {
            props.showNewBtn && !props.hideNewBtn && (
              // <NavLink to='/'>
              <SpeedDialAction
                icon="add"
                label="New"
                // visible={false}
                onClick={() => showNewModal()}
              />
            )
            // </NavLink>
          }
        </>
      )}
    </div>
  );
};

export default DataTable;
