import React, { useState, useEffect } from "react";
import {
  Chart,
  Series,
  Legend,
  ValueAxis,
  Point,
  Border,
  Grid as ChartGrid,
  CommonPaneSettings,
  Tooltip,
  ArgumentAxis,
  Label,
  Export,
  SeriesTemplate,
  CommonSeriesSettings,
  Margin,
  ChartTypes,
  Format,
} from "devextreme-react/chart";
import { ReactComponent as ArrowIcon } from "../../images/ArrowLine.svg";
import PieChart, {
  IPieChartOptions,
  Connector,
  Title,
} from "devextreme-react/pie-chart";
import "../../css/Dashboard.css";
import utility from "../../classes/utility";
import Config from "../../classes/config";
import Grid from "@mui/material/Grid";
import Funnel, {
  AdaptiveLayout,
  Item as FunnelItem,
  ILabelProps,
} from "devextreme-react/funnel";
import { Legend as FunnelLegend } from "devextreme/viz/funnel";

interface Item {
  key: string;
  id: string;
  tenantId: string;
  unitIndicatorId: string;
  score: number;
  year: number;
  status: string;
  statusId: string;
  percentComplete: number;
  updatedOn: Date | null;
  updatedBy: string;
  createdBy: string;
  createdOn: Date | null;
  unitName: string;
  genderLabelResponse: {
    label: string | null;
    value: string | null;
  };
}

interface TFDashboardProps {
  items: Item[];
}

interface UnitOverYearsPlotData {
  year: number;
  unitName: string;
  chartResponse: string;
}

const TFDashboard: React.FC<TFDashboardProps> = ({ items }) => {
  const [unitPerformancesChartData, setUnitPerformancesChartData] =
    useState<any>([]);
  const [unitsOverYearsChartData, setUnitsOverYearsChartData] = useState<any>(
    []
  );
  const [priorityAreasHightlightData, setPriorityAreasHightlightData] =
    useState<any>([]);
  const [priorityAreasProgressData, setPriorityAreasProgressData] =
    useState<any>([]);
  const globalScoringChartDataGU: any = [{ name: "Gender unaware", value: 1 }];

  const globalScoringChartDataPGA: any = [{ name: "Gender unaware", value: 1 }];
  const palette = ["#c3a2cc", "#b7b5e0", "#e48cba"];
  const colors = ["#FF5733", "#33FF57", "#3366FF", "#FFFF33", "#33FFFF"];
  let paletteIndex = 0;
  const currentYear = new Date().getFullYear();
  const previousYear = currentYear - 1;

  useEffect(() => {
    console.log("items", items);
    getChartData("chartResponse", items);
    getUnitOverYearsData(items);
    getPriorityAreasHighlightsData(items);
    getPriorityAreasProgressData(items, currentYear - 1);
  }, []);

  const getChartData = (chart: any, data: any) => {
    let groupedByData = utility.groupBy(data, chart);
    let groupedByDataKeys = Object.keys(groupedByData);
    let returnData: any = [];

    groupedByDataKeys &&
      groupedByDataKeys.forEach((item: any) => {
        returnData.push({
          name: item === "null" || "" ? "Yet to start" : item,
          value: groupedByData[item].length,
        });
      });
    setUnitPerformancesChartData(returnData);
    return returnData;
  };

  const getUnitOverYearsData = (chartData: any) => {
    const filteredData = chartData.filter(
      (entry: any) => entry.year === currentYear || entry.year === previousYear
    );

    const plotData = filteredData.map((entry: any) => {
      const chartResponse = Config.genderResponseNames.includes(
        entry.chartResponse
      )
        ? entry.chartResponse
        : undefined;
      return {
        unitName: entry.unitName,
        chartResponse,
      };
    });

    console.log(plotData);
    setUnitsOverYearsChartData(plotData);
  };

  const centerTemplateGU = (pieChart: any) => {
    return (
      <svg className="customClass">
        <text
          textAnchor="middle"
          style={{ fontStyle: "Rubik", fontSize: 18, fill: "#ffffff" }}
        >
          <tspan x="100" y="90">
            GENDER
          </tspan>
          <tspan x="100" y="120">
            UNAWARE
          </tspan>
        </text>
      </svg>
    );
  };

  const centerTemplatePGA = (pieChart: any) => {
    return (
      <svg className="customClass">
        <text textAnchor="middle" style={{ fontSize: 18, fill: "#ffffff" }}>
          <tspan x="100" y="90">
            PARTIALLY
          </tspan>
          <tspan x="100" y="120">
            GENDER AWARE
          </tspan>
          {/* <tspan x="100" y="150">AWARE</tspan> */}
        </text>
      </svg>
    );
  };

  const customizeSeries = (e: any) => {
    const colorIndex = Config.genderResponseNames.indexOf(e.originalArgument);
    if (colorIndex !== -1) {
      e.color = colors[colorIndex];
    }
  };

  const customLegendItemsGU = (e: any) => {
    return `${currentYear} Global Scoring [TBD]`;
  };

  const customLegendItemsPGA = (e: any) => {
    return `${previousYear} Global Scoring [TBD]`;
  };

  const getPriorityAreasHighlightsData = (data: any) => {
    let returnData: any = [];
    let allPriorityAreasScores: any = [];
    let submittedScoreCards = data.filter(
      (x: any) => x.status !== Config.DraftStatusId
    );
    submittedScoreCards.map((item: any) => {
      item.scorePriorityAreasList.map((area: any) => {
        allPriorityAreasScores.push({
          priorityArea: area.title,
          value: area.genderLabelResponse.value,
          year: item.year,
        });
      });
    });

    let priorityAreasWithValues = allPriorityAreasScores.filter(
      (v: any) => v.value !== null
    );

    let priorityAreas = Config.priorityAreasListNames;
    priorityAreas.map((area: any) => {
      let areaScores = priorityAreasWithValues.filter(
        (v: any) => v.priorityArea === area
      );
      let areaScoresValues = areaScores.map((v: any) => v.value);
      let areaScoresValuesSum = areaScoresValues.reduce(
        (a: any, b: any) => a + b,
        0
      );
      let areaScoresValuesAvgInDecimal =
        areaScoresValuesSum / areaScoresValues.length;
      let areaScoresValuesAvg =
        Math.round(areaScoresValuesAvgInDecimal * 10) / 10;
      returnData.push({
        priorityArea: area,
        value: Math.round(areaScoresValuesAvg * 10) / 10,
        genderLabel:
          Config.genderResponseNames[Math.round(areaScoresValuesAvg)],
      });
    });

    setPriorityAreasHightlightData(returnData);
    return returnData;
  };

  const getPriorityAreasProgressData = (data: any, yearFrom: number) => {
    let returnData: any = [];
    let allPriorityAreasScores: any = [];
    let submittedScoreCards = data.filter(
      (x: any) => x.status !== Config.DraftStatusId
    );
    submittedScoreCards.map((item: any) => {
      item.scorePriorityAreasList.map((area: any) => {
        allPriorityAreasScores.push({
          priorityArea: area.title,
          value: area.genderLabelResponse.value,
          year: item.year,
        });
      });
    });

    let priorityAreasWithValues = allPriorityAreasScores.filter(
      (v: any) =>
        v.value !== null && (v.year === yearFrom || v.year === currentYear)
    );

    let priorityAreas = Config.priorityAreasListNames;
    priorityAreas.map((area: any) => {
      let areaScores = priorityAreasWithValues.filter(
        (v: any) => v.priorityArea === area
      );

      //Current Year
      let areaScoresValuesCurrentYear = areaScores
        .filter((x: any) => x.year === currentYear)
        .map((v: any) => v.value);
      let areaScoresValuesSumCurrentYear = areaScoresValuesCurrentYear.reduce(
        (a: any, b: any) => a + b,
        0
      );
      let areaScoresValuesAvgInDecimalCurrentYear =
        areaScoresValuesSumCurrentYear / areaScoresValuesCurrentYear.length;
      let areaScoresValuesAvgCurrentYear =
        Math.round(areaScoresValuesAvgInDecimalCurrentYear * 10) / 10;

      //Previous Year
      let areaScoresValuesPreviousYear = areaScores
        .filter((x: any) => x.year === yearFrom)
        .map((v: any) => v.value);
      let areaScoresValuesSumPreviousYear = areaScoresValuesCurrentYear.reduce(
        (a: any, b: any) => a + b,
        0
      );
      let areaScoresValuesAvgInDecimalPreviousYear =
        areaScoresValuesSumPreviousYear / areaScoresValuesPreviousYear.length;
      let areaScoresValuesAvgPreviousYear =
        Math.round(areaScoresValuesAvgInDecimalPreviousYear * 10) / 10;

      returnData.push({
        priorityArea: area,
        currentYearValue: Math.round(areaScoresValuesAvgCurrentYear * 10) / 10,
        previousYearValue:
          Math.round(areaScoresValuesAvgPreviousYear * 10) / 10,
        CurrentYearGenderLabel:
          Config.genderResponseNames[
            Math.round(areaScoresValuesAvgCurrentYear)
          ],
        PreviousYearGenderLabel:
          Config.genderResponseNames[
            Math.round(areaScoresValuesAvgPreviousYear)
          ],
      });
    });

    setPriorityAreasProgressData(returnData);
    return returnData;
  };

  const formatLabel = (arg: any) => {
    let result = arg.item.data;
    return `<div class="label">${result.genderLabel}</div><br/><div>${result.value}</div>`;
  };

  const onPointClick = (e: ChartTypes.PointClickEvent) => {
    e.target.select();
  };

  // const priorityAreasProgressData = [
  //   {
  //     state: "Gender Representation",
  //     year2016: "Gender Unaware [1]",
  //     year2017: "Partially Gender Aware [2]",
  //   },
  //   {
  //     state: "Policy and Priority setting",
  //     year2016: "Gender Unaware [1]",
  //     year2017: "Partially Gender Aware [2]",
  //   },
  //   {
  //     state: "Capacity & Competence",
  //     year2016: "Gender Unaware [1]",
  //     year2017: "Gender Aware [3]",
  //   },
  //   {
  //     state: "Partnerships & Communications",
  //     year2016: "Gender Unaware [1]",
  //     year2017: "Gender Aware [3]",
  //   },
  //   {
  //     state: "Results & Accountability Framework",
  //     year2016: "Gender Unaware [1]",
  //     year2017: "Gender Aware [3]",
  //   },
  //   {
  //     state: "Leadership & Organisational Culture",
  //     year2016: "Gender Blind [0]",
  //     year2017: "Gender Unaware [1]",
  //   },
  // ];

  return (
    <>
      <div className="dashobaord-container">
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={6}>
            <div className="globalScores-container ui-container">
              <Grid container spacing={3}>
                <Grid item xs={12} sm={5}>
                  <PieChart
                    id="unitPerfLeft"
                    dataSource={globalScoringChartDataPGA}
                    centerRender={(e) => centerTemplateGU(e)}
                  >
                    <Series
                      argumentField="name"
                      valueField="value"
                      color="#CD4AB8"
                    >
                      {/* <Label visible={true}>
                        <Connector visible={true} width={1} />
                      </Label> */}
                    </Series>
                    <Legend
                      customizeText={customLegendItemsGU}
                      orientation="horizontal"
                      itemTextPosition="right"
                      horizontalAlignment="center"
                      verticalAlignment="bottom"
                      rowCount={2}
                    />
                    <Title
                      horizontalAlignment={"left"}
                      verticalAlignment={"top"}
                      text={`Global Scoring`}
                      subtitle="out of 4"
                    />
                  </PieChart>
                </Grid>
                <Grid item xs={12} sm={2} className="pieWithIcon">
                  <ArrowIcon className="arrowIcon" />
                </Grid>
                <Grid item xs={12} sm={5}>
                  <PieChart
                    id="unitPerfRight"
                    className=""
                    palette="Bright"
                    dataSource={globalScoringChartDataPGA}
                    centerRender={(e) => centerTemplatePGA(e)}
                  >
                    <Legend visible={false} />
                    <Margin top={40} />
                    <Series
                      argumentField="name"
                      valueField="value"
                      color="#00BF8A"
                    >
                      {/* <Label visible={false}>
                        <Connector visible={true} width={20} />
                      </Label> */}
                    </Series>
                    <Legend
                      customizeText={customLegendItemsPGA}
                      orientation="horizontal"
                      itemTextPosition="right"
                      horizontalAlignment="center"
                      verticalAlignment="bottom"
                    />
                  </PieChart>
                </Grid>
              </Grid>
            </div>
          </Grid>

          <Grid item xs={12} sm={12} md={6}>
            {unitPerformancesChartData &&
              unitPerformancesChartData.length > 0 && (
                <div className="pie-container ui-container">
                  <PieChart
                    id="unitPerf"
                    type="doughnut"
                    className=""
                    palette="Bright"
                    dataSource={unitPerformancesChartData}
                    innerRadius={0.65}
                    //centerRender={(e) => centerTemplate(e)}
                  >
                    <Series argumentField="name" valueField="value">
                      <Label visible={true}>
                        <Connector visible={true} width={1} />
                      </Label>
                    </Series>
                    <Title
                      horizontalAlignment={"left"}
                      text="Unit Performance"
                      subtitle="No. of units per result"
                    />
                    <Legend
                      orientation="horizontal"
                      itemTextPosition="right"
                      horizontalAlignment="center"
                      verticalAlignment="bottom"
                      //rowCount={2}
                    />
                    <Export enabled={true} />
                  </PieChart>
                </div>
              )}
          </Grid>

          <Grid item xs={12}>
            <div className="unitsOverYearsContainer ui-container">
              <Chart
                id="chart"
                dataSource={unitsOverYearsChartData}
                palette="Bright"
              >
                <CommonSeriesSettings
                  argumentField="unitName"
                  valueField="chartResponse"
                  type="scatter"
                  ignoreEmptyPoints={true}
                />
                <Margin bottom={20} />
                <SeriesTemplate nameField="unitName" />
                {/* <Series
                                argumentField="unitName"
                                valueField="chartResponse"
                                type="scatter"
                            >
                                <Point size={20} hoverMode="allArgumentPoints" />
                            </Series> */}
                <ArgumentAxis
                  title="Units"
                  valueMarginsEnabled={true}
                  discreteAxisDivisionMode="crossLabels"
                >
                  <Label rotationAngle={20} overlappingBehavior="rotate" />
                  <ChartGrid visible={true} />
                </ArgumentAxis>
                <CommonPaneSettings>
                  <Border visible={false} />
                </CommonPaneSettings>
                <Tooltip enabled={true} />
                <ValueAxis
                  type="discrete"
                  title="Gender Score"
                  tickInterval={3}
                  categories={Config.genderResponseNames}
                  valueMarginsEnabled={true}
                  discreteAxisDivisionMode="crossLabels"
                />
                <Title
                  text="Units over the years"
                  subtitle={`${previousYear} - ${currentYear}`}
                />
                <Legend
                  orientation="horizontal"
                  itemTextPosition="right"
                  horizontalAlignment="center"
                  verticalAlignment="bottom"
                  //rowCount={2}
                />
                <Export enabled={true} />
              </Chart>
            </div>
          </Grid>
          <Grid item xs={12}>
            {priorityAreasHightlightData &&
              priorityAreasHightlightData.length > 0 && (
                <div className="ui-container">
                  <div className="priorityAreaDataHighlightsContainer">
                    <Funnel
                      id="funnel"
                      dataSource={priorityAreasHightlightData}
                      palette="Bright"
                      argumentField="priorityArea"
                      valueField="value"
                    >
                      <Title
                        text="Priority Areas - Data Highlights"
                        subtitle={"Highest to Lowest"}
                        horizontalAlignment="left"
                        verticalAlignment="top"
                      />
                      <Export enabled={true} />
                      <Tooltip enabled={true} format="fixedPoint" />
                      <FunnelItem>
                        <Border visible={true} />
                      </FunnelItem>
                      <Label
                        visible={true}
                        position="inside"
                        backgroundColor="none"
                        customizeText={formatLabel}
                      />
                      <Legend
                        visible={true}
                        orientation="vertical"
                        itemTextPosition="right"
                        horizontalAlignment="left"
                        verticalAlignment="top"
                      />
                      <AdaptiveLayout width={300} keepLabels={true} />
                    </Funnel>
                  </div>
                </div>
              )}
          </Grid>
          <Grid item xs={12}>
            {priorityAreasProgressData &&
              priorityAreasProgressData.length > 0 && (
                <div className="ui-container">
                  <div className="priorityAreasProgressContainer">
                    <Chart
                      id="chart"
                      dataSource={priorityAreasProgressData}
                      onPointClick={onPointClick}
                      rotated={true}
                    >
                      <Title
                        text="Priority Areas - Progress"
                        subtitle={`${previousYear} - ${currentYear}`}
                      />
                      <CommonSeriesSettings
                        argumentField="priorityArea"
                        type="bar"
                        hoverMode="allArgumentPoints"
                        selectionMode="allArgumentPoints"
                      >
                        {/* <Label visible={true}>
                          <Format type="fixedPoint" precision={0} />
                        </Label> */}
                      </CommonSeriesSettings>
                      <Series
                        valueField="CurrentYearGenderLabel"
                        name={currentYear.toString()}
                      />
                      <Series
                        valueField="PreviousYearGenderLabel"
                        name={(currentYear - 1).toString()}
                      />
                      <Export enabled={true} />
                      <ArgumentAxis
                        title="Priority Areas"
                        valueMarginsEnabled={true}
                        discreteAxisDivisionMode="crossLabels"
                      >
                        <Label
                          rotationAngle={20}
                          overlappingBehavior="rotate"
                        />
                        <ChartGrid visible={true} />
                      </ArgumentAxis>

                      <ValueAxis
                        // type="discrete"
                        title="Gender Equality Scorecard"
                        tickInterval={3}
                        categories={Config.genderResponseNames}
                        valueMarginsEnabled={true}
                        discreteAxisDivisionMode="crossLabels"
                      />
                      <Legend
                        orientation="horizontal"
                        itemTextPosition="right"
                        horizontalAlignment="center"
                        verticalAlignment="bottom"
                      />
                    </Chart>
                  </div>
                </div>
              )}
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default TFDashboard;
