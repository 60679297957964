export const Config: any = {
  productTitle: "AKF-GE",
  users: "Users",
  userUnitsRoles: "Users/GetUserUnitsRoles",
  getUserByEmail: "Users/GetUserByEmail",
  createUserWithRole: "Users/CreateUserWithRole",
  updateUserWithRole: "Users/UpdateUserWithRole",
  roles: "Roles",
  units: "Units",
  getUnitDetails: "Units/GetUnitDetails",
  getUnits: "Units",
  scoresList: "Scores",
  searchScores: "Scores/SearchScore",
  updateScore: "Scores/UpdateScore",
  createScore: "Scores/CreateScore",
  deleteScore: "Scores/DeleteScore",
  statusList: "Status",
  priorityAreasList: "PriorityAreas",
  resultAreasList: "ResultAreas",
  searchUnitIndicatorDetails: "UnitIndicators/SearchUnitIndicatorDetails",
  priorityAreasListNames: [
    "Policy & Priority Setting",
    "Gender Representation",
    "Capacity & Competence",
    "Partnerships & Communications",
    "Results & Accountability Framework",
    "Leadership & Organisational Culture",
  ],
  chartPriorityAreasListNames: [
    "PRIORITY 1: Policy & Priority Setting",
    "PRIORITY 2: Gender Representation",
    "PRIORITY 3: Capacity & Competence",
    "PRIORITY 4: Partnerships & Communications",
    "PRIORITY 5: Results & Accountability Framework",
    "PRIORITY 6: Leadership & Organisational Culture",
  ],
  genderResponseNames : [
    "Gender Blind [0]",
    "Gender Unaware [1]",
    "Partially Gender Aware [2]",
    "Gender Aware [3]",
    "Gender Transformative [4]"
  ],
  country: "Country",
  priorityAreaDetails: "PriorityAreas/PriorityAreaDetails",
  createUnitWithUnitIndicators: "Units/CreateUnitWithUnitIndicators",
  updateUnitWithUnitIndicators: "Units/UpdateUnitWithUnitIndicators",
  duedate: "duedate",
};

export default Config;
