import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import DateBox from "devextreme-react/date-box";
import utility from "../../classes/utility";
import Config from "../../classes/config";
import { useLocation, useNavigate } from "react-router-dom";
import Spinner from "../Common/ReusableComponents/Spinner";
import Button from "@mui/material/Button";
import { ReactComponent as SubmitAlertIcon } from "../../images/SubmitAlert.svg";
import AlertDialog from "../Common/AlertDialog";
import DeleteAlert from "../Common/DeleteAlert";
import { ReactComponent as WarningIcon } from "../../images/Warning.svg";

const AdminDueDateAddEdit = () => {
  const [year, setYear] = useState<string>(new Date().getFullYear().toString());
  const [dueDate, setDueDate] = useState<Date>(new Date());
  const [loading, setLoading] = useState<boolean>(false);
  const [dueDateItem, setDueDateItem] = useState<any>({});
  const [navigateToDueDatesScreen, setNavigateToDueDatesScreen] =
    useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<JSX.Element | string>("");
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [hideAlertButton, setHideAlertButton] = useState<boolean>(false);
  const [deleteMessage, setDeleteMessage] = useState<JSX.Element | string>("");
  const [showDeleteAlert, setShowDeleteAlert] = useState<boolean>(false);
  const [deleteSuccessMessage, setDeleteSuccessMessage] = useState<
    JSX.Element | string
  >("");
  const [showDeleteSuccessAlert, setShowDeleteSuccessAlert] =
    useState<boolean>(false);
  const [validated, setValidated] = useState<boolean>(false);

  const location = useLocation();
  const queryParameters = new URLSearchParams(location.search);
  const dueDateId = queryParameters.get("ID");

  const getDueDateData = async (dueDateId: number) => {
    let email: string = "";
    try {
    const r = await utility.genericGetAPICall(`${Config.duedate}/${dueDateId}`);
        let _duedate: any = [];
        _duedate = {
          id: r.id,
          year: r.year,
          due_Date: r.due_Date,
          updatedBy: r.updatedBy,
          updatedOn: r.updatedOn,
          createdBy: r.createdBy,
          createdOn: r.createdOn,
          isDeleted: r.isDeleted,
        };
        setYear(r.year.toString());
        setDueDate(r.due_Date);
        setDueDateItem(_duedate);
    } catch (err) {
      console.log("Error: " + err);
    }
  };

  useEffect(() => {
    didMount();
  }, []);

  const didMount = () => {
    setLoading(true);

    if (dueDateId !== "0") {
      getDueDateData(Number(dueDateId));
    }

    setLoading(false);
  };

  const validate = () => {
    if (year === null || year === "" || year === undefined) {
      setAlertMessage(
        <>
          <p className="AlertIcon">
            {" "}
            <WarningIcon />
          </p>
          <p className="AlertTitle">Error!</p>
          <p className="AlertMessage">Please enter year.</p>
        </>
      );
      setShowAlert(true);
      setLoading(false);
      return false;
    }

    if (dueDate === undefined || dueDate === null) {
      setAlertMessage(
        <>
          <p className="AlertIcon">
            {" "}
            <WarningIcon />
          </p>
          <p className="AlertTitle">Error!</p>
          <p className="AlertMessage">Please enter Due Date.</p>
        </>
      );
      setShowAlert(true);
      setLoading(false);
      return false;
    }

    setValidated(true);
    return true;
  };

  const handleSaveClick = async() => {
    setValidated(false);

    let result = validate();

    if (result) {
      let currentUserEmail: string = localStorage.getItem("currentUserEmail")!;

      let dataToPost: any = {};
      dataToPost = {
        id: dueDateId,
        createdBy: currentUserEmail,
        updatedBy: currentUserEmail,
        createdOn: new Date(),
        updatedOn: new Date(),
        year: year,
        due_Date: dueDate,
        tenantId: 1,
        isDeleted: false,
        deletedBy: null,
        deletedOn: null,
      };
      
      try {
      if (dueDateId === "0") {
        const data = await utility.genericPostAPIcall(`${Config.duedate}`, dataToPost);
            console.log("data: " + data);
            setNavigateToDueDatesScreen(true);
            setAlertMessage(
              <>
                <p className="AlertIcon">
                  {" "}
                  <SubmitAlertIcon />
                </p>
                <p className="AlertTitle">Success!</p>
                <p className="AlertMessage">
                  Due Date has been created successfully.
                </p>
              </>
            );
            setShowAlert(true);
            setLoading(false);
      } else {
        const data = await utility.genericPutByIdAPICall(`${Config.duedate}/${dueDateId}`, dataToPost);
              console.log("data: " + data);
              setNavigateToDueDatesScreen(true);
              setAlertMessage(
                <>
                  <p className="AlertIcon">
                    {" "}
                    <SubmitAlertIcon />
                  </p>
                  <p className="AlertTitle">Success!</p>
                  <p className="AlertMessage">
                    Due Date has been updated successfully.
                  </p>
                </>
              );
              setShowAlert(true);
              setLoading(false);
            }
           } catch (err) {
              console.error("data: " + err);
              setAlertMessage(
                <>
                  <p className="AlertIcon">
                    {" "}
                    <WarningIcon />
                  </p>
                  <p className="AlertTitle">Error!</p>
                  <p className="AlertMessage">Something went wrong.</p>
                </>
              );
              setShowAlert(true);
              setLoading(false);
            }
          }
        };

  const deleteAlert = () => (
    <DeleteAlert
      showDialog={showDeleteAlert}
      message={deleteMessage}
      title={dueDateItem.year}
      onClose={() => setShowDeleteAlert(false)}
      onOkButton={() => {
        setShowDeleteAlert(false);
        deleteDueDate();
      }}
      onCancelButton={() => setShowDeleteAlert(false)}
      buttonText="DELETE"
    />
  );

  const deleteSuccessAlert = () => (
    <AlertDialog
      showDialog={showDeleteSuccessAlert}
      message={deleteSuccessMessage}
      title={""}
      onClose={() => {
        setDeleteSuccessMessage("");
        setShowDeleteSuccessAlert(false);
        navigate(`/AdminDueDates`);
      }}
      hideAlertButton={hideAlertButton}
      buttonText="OKAY"
    />
  );

  const deleteDueDate = async () => {
    try {
      let currentUserEmail: string = localStorage.getItem("currentUserEmail")!;
  
      let dataToPost: any = {
        id: dueDateId,
        deletedBy: currentUserEmail,
      };
  
      const result = await utility.genericDeleteAPICall(`${Config.duedate}`, dataToPost);
  
      if (result.ok) {
        console.log("data OK: " + result);
      } else {
        const text = await result.text();
        console.log("data NOT OK: " + text);
        throw new Error("Network response was not ok.");
      }
  
      console.log("data: " + result);
  
      setDeleteSuccessMessage(
        <>
          <p className="AlertIcon">
            {" "}
            <SubmitAlertIcon />
          </p>
          <p className="AlertTitle">Successfull!</p>
          <p className="AlertMessage">
            The due date with Id: {dueDateId} has been deleted.
          </p>
        </>
      );
      setShowDeleteSuccessAlert(true);
      setLoading(false);
    } catch (err) {
      console.error(err);
      // Handle error if needed
    }
  };
  

  const handleDeleteClick = () => {
    setDeleteMessage(
      <>
        <p className="AlertIcon">
          {" "}
          <WarningIcon />
        </p>
        <p className="AlertTitle">Alert!</p>
        <p className="AlertMessage">
          Are you sure that you want to delete this item?.
        </p>
      </>
    );
    setShowDeleteAlert(true);
    setLoading(false);
  };

  let navigate = useNavigate();
  const handleCancelClick = () => {
    let path = `/AdminDueDates`;
    navigate(path);
  };

  const navigateDueDatesScreen = (redirect?: any) => {
    if (navigateToDueDatesScreen || redirect) navigate(`/AdminDueDates`);
  };

  const alert = () => (
    <AlertDialog
      showDialog={showAlert}
      message={alertMessage}
      title={""}
      onClose={() => {
        setAlertMessage("");
        setShowAlert(false);
        navigateDueDatesScreen();
      }}
      hideAlertButton={hideAlertButton}
      buttonText="OKAY"
    />
  );

  const handleDateBoxValueChanged = (e: any) => {
    let y = new Date(e.value).getFullYear();
    setYear(y.toString());
  };

  return (
    <div className="ui-container admin-user">
      {showAlert && alert()}
      {showDeleteAlert && deleteAlert()}
      {showDeleteSuccessAlert && deleteSuccessAlert()}
      {!loading ? (
        <>
          {dueDateItem ? (
            <>
              <Grid container spacing={3}>
                <Grid item container xs={12} sm={10} spacing={2}>
                  <Grid item xs={12} sm={4}>
                    <DateBox
                      stylingMode="outlined"
                      label="Year"
                      type="date"
                      value={year}
                      onValueChanged={(e: any) => handleDateBoxValueChanged(e)}
                      calendarOptions={{
                        zoomLevel: "decade",
                        minZoomLevel: "decade",
                        maxZoomLevel: "decade",
                      }}
                      displayFormat={"yyyy"}
                      showClearButton={true}
                    />
                  </Grid>
                </Grid>
                <Grid item container xs={12} sm={10} spacing={2}>
                  <Grid item xs={12} sm={4}>
                    <DateBox
                      stylingMode="outlined"
                      label="Due Date"
                      type="date"
                      value={dueDate}
                      onValueChanged={(e: any) => setDueDate(e.value)}
                      displayFormat={"dd-MM-yyyy"}
                      showClearButton={true}
                    />
                  </Grid>
                </Grid>
                <Grid item container xs={12} sm={10} spacing={2}>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    container
                    className="filter-buttons"
                  >
                    <Grid item>
                      <div className="confirm-action-buttons">
                        <Button
                          className="btn btn-primary"
                          onClick={handleSaveClick}
                        >
                          Save
                        </Button>
                      </div>
                    </Grid>
                    <Grid item>
                      <div className="confirm-action-buttons">
                        <Button
                          className="btn btn-secondary"
                          onClick={handleCancelClick}
                        >
                          Cancel
                        </Button>
                      </div>
                    </Grid>
                    <Grid item>
                      <div className="confirm-action-buttons">
                        <Button
                          className="btn btn-danger"
                          onClick={handleDeleteClick}
                        >
                          Delete
                        </Button>
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </>
          ) : (
            <div className="no-results">
              <h2 className="main-title">No Results Found</h2>
            </div>
          )}
        </>
      ) : (
        <Spinner size="large" />
      )}
    </div>
  );
};

export default AdminDueDateAddEdit;
