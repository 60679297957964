import { Configuration, PopupRequest, LogLevel } from "@azure/msal-browser";
import global from "./global";
import config from "./config";

// Config object to be passed to MSAL on creation
export const msalConfig: Configuration = {
  auth: {
    clientId: global.AzureADClientId,
    authority: "https://login.microsoftonline.com/" + global.AzureADTenantId,
    redirectUri: config.homeURL,
  },
  cache: {
    cacheLocation: "sessionStorage",
    // cacheLocation: "localStorage",
    storeAuthStateInCookie: true,
  },
  system: {
    loggerOptions: {
      loggerCallback: (level: any, message: any, containsPii: any) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            //console.info(message);
            return;
          case LogLevel.Verbose:
            //console.debug(message);
            return;
          case LogLevel.Warning:
            //console.warn(message);
            return;
          default:
            return;
        }
      },
    },
  },
};

// Add here scopes for id token to used at MS Identity platforms endpoints
export const loginRequest: PopupRequest = {
  scopes: [global.AzureADScope],
};
