import React, { useState, useEffect } from "react";
import { useLocation, useParams, useNavigate } from "react-router-dom";

import utility from "../../classes/utility";
import config from "../../classes/config";
import TabPanel from "../Common/ReusableComponents/TabPanel";
import Spinner from "../Common/ReusableComponents/Spinner";
import TextAreaInput from "../Common/ReusableComponents/TextArea";
import AlertDialog from "../Common/AlertDialog";
import DeleteDialog from "../Common/DeleteAlert";

import { ReactComponent as RadioUncheckedIcon } from "../../images/RadioUnchecked.svg";
import { ReactComponent as RadioCheckedIcon } from "../../images/RadioChecked.svg";
import { ReactComponent as DottedLine } from "../../images/dottedLine.svg";
import { ReactComponent as Chat } from "../../images/Chat.svg";
import { ReactComponent as RightArrowIcon } from "../../images/RightArrow.svg";
import { ReactComponent as WarningIcon } from "../../images/Warning.svg";
import { ReactComponent as HorizontalDottedLine } from "../../images/HorizontalDottedLine.svg";
import { ReactComponent as DeleteWarningIcon } from "../../images/DeleteWarning.svg";
import { ReactComponent as SubmitAlertIcon } from "../../images/SubmitAlert.svg";

import Grid from "@mui/material/Grid";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/system";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Radio, RadioGroup, FormControlLabel, Button } from "@mui/material";

const GESRadioOptionsList = [0, 1, 2, 3, 4];

interface summaryStateProps {
  unitId: string;
  unitName: string;
  percentComplete: string;
  totalScore: string;
  maxTotalScore: string;
  statusId: number;
  genderLabelResponse: { label: string; value: string };
}

interface HomeProps {
  unitId: number | null;
  roleId: number | null;
  currentYearRecordExists: boolean;
}

interface GESScoreItemsType {
  scorePriorityAreasList: {
    title: string;
  }[];
  scoreResultAreasList: {
    title: string;
  }[];
  scoreIndicatorList: {
    title: string;
    indicatorId: number;
  }[];
  // other properties if any
}

const CEOGES = (homeProps: HomeProps) => {
  const location = useLocation();
  const summaryTab = { id: -1, title: "Summary" };
  const [loading, setLoading] = useState<boolean>(false);
  const [scoreYear, setScoreYear] = useState<string>("");
  const [scoreId, setScoreId] = useState<string>("");
  const [selectedTab, setSelectedTab] = useState(0);
  const [selectedRadioButton, setSelectedRadioButton] = useState(null);
  const [GESItems, setGESItems] = useState<any[]>([]);
  const [GESScoreItems, setGESScoreItems] = useState<GESScoreItemsType[]>([]);
  const [comments, setComments] = useState({});
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const [selectedRadio, setSelectedRadio] = useState({});
  const [isDratftEnabled, setIsDraftEnabled] = useState<boolean>(false);
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<JSX.Element | string>("");
  const [scoreCardExists, setScoreCardExists] = useState<boolean>(false);
  const [scorecardSaved, setScorecardSaved] = useState<boolean>(false);
  const [tempScoreId, setTempScoreId] = useState(null);
  const [isScoreCardUnderReview, setIsScoreCardUnderReview] =
    useState<boolean>(false);
  const [validationChecksForSubmission, setValidationChecksForSubmission] =
    useState<boolean>(false);
  const [data, setData] = useState<any[]>([]);
  const [deleteInfo, setDeleteInfo] = useState<any>({});
  const [indicatorResponseItems, setIndicatorResponseItems] = useState<any[]>(
    []
  );
  const [selectedResponse, setSelectedResponse] = useState<string | null>(null);
  const tabsWithSummary = [...GESScoreItems, summaryTab];
  const [previousYearScoresList, setPreviousYearScoresList] = useState<any[]>(
    []
  );
  const [showActionButtons, setShowActionButtons] = useState<boolean>(false);
  const [disableSummarySection, setDisableSummarySection] =
    useState<boolean>(false);

  const [expandedState, setExpandedState] = useState<
    Record<number, Record<number, { expanded: boolean }>>
  >({});
  const [accordionTitles, setAccordionTitles] = useState<
    Record<string, string>
  >({});
  const currentYear = new Date().getFullYear();
  const [hideAlertButton, setHideAlertButton] = useState<boolean>(false);
  const [showDeleteAlert, setShowDeleteAlert] = useState<boolean>(false);
  const [deleteAlertMessage, setDeleteAlertMessage] = useState<
    JSX.Element | string
  >("");
  const [showApproveAlert, setShowApproveAlert] = useState<boolean>(false);
  const [showRejectAlert, setShowRejectAlert] = useState<boolean>(false);
  const [approveAlertMessage, setApproveAlertMessage] = useState<
    JSX.Element | string
  >("");
  const [rejectAlertMessage, setRejectAlertMessage] = useState<
    JSX.Element | string
  >("");

  const [state, setState] = useState<summaryStateProps>({
    unitId: "",
    unitName: "",
    percentComplete: "",
    totalScore: "",
    maxTotalScore: "",
    statusId: 0,
    genderLabelResponse: { label: "", value: "" },
  });

  const navigate = useNavigate();

  useEffect(() => {
    const search = location.search;
    const params = new URLSearchParams(search);
    const searchYear = params.get("year");
    const searchScoreId = params.get("scoreId");

    if (searchYear) {
      setScoreYear(searchYear);
    }

    if (searchScoreId) {
      setScoreId(searchScoreId);
    }

    const fetchData = async () => {
      setLoading(true);
      await getPreviousYearScoresData();
      const descriptionsData = await getGESDescriptionsData();
      await getScoresData(searchYear, searchScoreId, descriptionsData);
    };

    if (scoreId || scoreYear) {
      fetchData();
    }
  }, [scoreYear]);

  const getScoresData = async (
    year: any,
    scoreID?: any,
    indicatorDesc?: any
  ) => {
    setLoading(true);
    let dataToPassToService: any = {};
    dataToPassToService.unitId = homeProps.unitId;
    dataToPassToService.scoreId = scoreID ? +scoreID : 0;
    dataToPassToService.yearFrom = +year;
    dataToPassToService.yearTo = +year;
    try {
      const data = (await utility.genericPostAPIcall(
        `${config.searchScores}?showAll=true&pageSize=-1&pageNumber=-1`,
        dataToPassToService
      )) as any[];

      const activeRecords = data?.filter(
        (item: any) => item.isDeleted === false
      );

      await displayScoreCards(activeRecords);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const displayScoreCards = async (activeRecords: any) => {
    setScoreCardExists(true);
    let _items: any = [];
    if (activeRecords && activeRecords.length > 0) {
      _items = activeRecords[0];
      if (_items.statusId === 3) {
        setIsScoreCardUnderReview(true);
      }
      setState((prevState) => ({
        ...prevState,
        unitId: _items.unitId,
        unitName: _items.unitName,
        percentComplete: _items.percentComplete,
        statusId: _items.statusId,
        totalScore: _items.totalScore,
        maxTotalScore: _items.maxTotalScore,
        year: _items.year,
        genderLabelResponse: {
          label: _items.genderLabelResponse?.label,
          value: _items.genderLabelResponse?.value,
        },
      }));

      let _gesScoreData: any = [];
      let priorityAreaData: any = {};
      let resultAreaData: any = {};
      let indicatorData: any = {};
      let _indicatorResponseData: any = [];

      if (_items.scorePriorityAreasList) {
        _items.scorePriorityAreasList.forEach((priorityArea: any) => {
          priorityAreaData = {
            id: priorityArea.id,
            title: priorityArea.title,
            maxTotal: priorityArea.maxTotal,
            subTotal: priorityArea.subTotal,
            genderLabelResponse: {
              label: priorityArea.genderLabelResponse?.label,
              value: priorityArea.genderLabelResponse?.value,
            },
            scoreResultAreasList: [],
          };

          if (_items.scoreResultAreasList) {
            const relevantResultAreas = _items.scoreResultAreasList.filter(
              (resultArea: any) => resultArea.priorityAreaId === priorityArea.id
            );

            relevantResultAreas.map((resultArea: any) => {
              resultAreaData = {
                id: resultArea.id,
                title: resultArea.title,
                maxTotal: resultArea.maxTotal,
                subTotal: resultArea.subTotal,
                genderLabelResponse: {
                  label: resultArea.genderLabelResponse?.label,
                  value: resultArea.genderLabelResponse?.value,
                },
                scoreIndicatorList: [],
              };

              if (_items.scoreIndicatorList) {
                const relevantIndicators = _items.scoreIndicatorList.filter(
                  (indicator: any) =>
                    indicator.resultAreaId === resultArea.id &&
                    indicator.priorityAreaId === priorityArea.id
                );

                relevantIndicators.forEach((indicator: any) => {
                  indicatorData = {
                    id: indicator.id,
                    indicatorId: indicator.indicatorId,
                    indicatorTitle: indicator.indicatorTitle,
                    priorityAreaId: indicator.priorityAreaId,
                    priorityAreaTitle: indicator.priorityAreaTitle,
                    resultAreaId: indicator.resultAreaId,
                    resultAreaTitle: indicator.resultAreaTitle,
                    response: indicator.response,
                    comments: indicator.comments,
                  };

                  resultAreaData.scoreIndicatorList.push(indicatorData);

                  _indicatorResponseData.push({
                    id: indicator.id,
                    indicatorId: indicator.indicatorId,
                    response: indicator.response?.toString() || null,
                    comments: indicator.comments,
                  });
                });

                resultAreaData.scoreIndicatorList = utility._sortItems(
                  resultAreaData.scoreIndicatorList,
                  "id",
                  false
                );
              }

              priorityAreaData.scoreResultAreasList.push(resultAreaData);
            });

            _gesScoreData.push(priorityAreaData);
          }
        });

        //sort based on priority area neames defined in config file
        const titleIndexMap = {};
        config.priorityAreasListNames.forEach((title: any, index: any) => {
          titleIndexMap[title] = index;
        });

        _gesScoreData.sort(
          (a: any, b: any) => titleIndexMap[a.title] - titleIndexMap[b.title]
        );

        setGESScoreItems(_gesScoreData);
        setIndicatorResponseItems(_indicatorResponseData);
        setDisableSummarySection(false);

        // console.log("score data :::", _gesScoreData);
        // console.log("score responses :: ", _indicatorResponseData);
      }
    }
    setLoading(false);
  };

  const getGESDescriptionsData = async () => {
    try {
      const dataToPassToService: any = {};
      dataToPassToService.unitId = homeProps.unitId;
      dataToPassToService.scoreId = scoreId ? +scoreId : 0;

      const data: any = await utility.genericPostAPIcall(
        `${config.searchUnitIndicatorDetails}`,
        dataToPassToService
      );

      const GESItemsData: any[] = [];

      if (data && data.length > 0) {
        data.forEach((r: any) => {
          const unitIndicatorDescriptionList: any[] = [];

          r.unitIndicatorDescriptionList?.forEach((item: any) => {
            unitIndicatorDescriptionList.push({
              response: item?.response,
              description: item?.description,
            });
          });

          GESItemsData.push({
            key: r.indicatorId,
            indicatorId: r.indicatorId,
            unitIndicatorId: r.unitIndicatorId,
            scoreIndicatorResponse: r.scoreIndicatorResponse,
            unitIndicatorDescriptionList: unitIndicatorDescriptionList,
          });
        });
      }

      setGESItems(GESItemsData);
      // console.log("descriptions data", GESItemsData);
      setLoading(false);

      return data; // Explicitly return the data
    } catch (error) {
      console.error("Error fetching priorityAreas data:", error);
      throw error; // Rethrow the error if needed
    }
  };

  const getPreviousYearScoresData = async () => {
    let dataToPassToService: any = {};
    dataToPassToService.unitId = homeProps.unitId;
    // dataToPassToService.scoreId = scoreID ? +scoreID : 0;
    dataToPassToService.yearFrom = +scoreYear - 1;
    dataToPassToService.yearTo = +scoreYear - 1;

    try {
      const data: any = await utility.genericPostAPIcall(
        `${config.searchScores}?showAll=true&pageSize=-1&pageNumber=-1`,
        dataToPassToService
      );
      const activeRecords = data?.filter((r: any) => r.isDeleted === false);
      let _items: any = [];
      if (activeRecords && activeRecords.length === 1) {
        let prevItems = activeRecords[0].scoreIndicatorList;
        if (prevItems) {
          prevItems.forEach((r: any) => {
            _items.push({
              indicatorId: r.id,
              response: r.response,
            });
          });
          prevItems = utility._sortItems(prevItems, "indicatorId", false);
        }
        setPreviousYearScoresList(prevItems);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleAccordionChange = (
    tabIndex: number,
    resultAreaId: number,
    indicatorId: number
  ) => {
    setExpandedState((prev) => {
      const newExpandedState: Record<
        number,
        Record<number, { expanded: boolean }>
      > = { ...prev };

      if (!newExpandedState[tabIndex]) {
        newExpandedState[tabIndex] = {};
      }

      if (!newExpandedState[tabIndex][resultAreaId]) {
        newExpandedState[tabIndex][resultAreaId] = { expanded: false };
      }

      newExpandedState[tabIndex][resultAreaId][indicatorId] = {
        expanded:
          !newExpandedState[tabIndex][resultAreaId][indicatorId]?.expanded,
      };

      // Update the accordion title based on the expanded state
      // const accordionTitle = newExpandedState[tabIndex][resultAreaId][indicatorId].expanded
      //   ? `*Response Descriptions for Result Area ${resultAreaId} - Indicator ${indicatorId}:*`
      //   : `*Click Here to view response Descriptions for Result Area ${resultAreaId} - Indicator ${indicatorId}*`;

      const accordionTitle = newExpandedState[tabIndex][resultAreaId][
        indicatorId
      ].expanded
        ? `*Response Descriptions*`
        : `* response Descriptions*`;

      setAccordionTitles((prevTitles) => ({
        ...prevTitles,
        [`tab-${tabIndex}-accordion-${resultAreaId}-${indicatorId}`]:
          accordionTitle,
      }));

      return newExpandedState;
    });
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  };

  const goToPreviousTab = () => {
    setSelectedTab((prevTab) => Math.max(prevTab - 1, 0));
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }, 0);
  };

  const goToNextTab = () => {
    if (disableSummarySection) {
      setSelectedTab((prevTab) =>
        Math.min(prevTab + 1, tabsWithSummary.length - 2)
      );
    } else {
      setSelectedTab((prevTab) =>
        Math.min(prevTab + 1, tabsWithSummary.length - 1)
      );
    }
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  const handleApproveButtonClick = () => {
    setApproveAlertMessage(
      <>
        <p className="SubmitAlertIcon">
          {" "}
          <SubmitAlertIcon />
        </p>
        <p className="AlertTitle">Final Approval!</p>
        <p className="AlertMessage">
          Are you sure you want to Approve? Scores post approval will be locked
          for any further edits.
        </p>
      </>
    );
    setShowApproveAlert(true);
  };

  const handleRejectButtonClick = () => {
    setRejectAlertMessage(
      <>
        <p className="SubmitAlertIcon">
          {" "}
          <DeleteWarningIcon />
        </p>
        <p className="AlertTitle">Reject Scorecard?</p>
        <p className="AlertMessage">
          Are you sure you want to proceed? Please contact GRT to resubmit
          scorecard.
        </p>
      </>
    );
    setShowRejectAlert(true);
  };

  // const goToSummaryTab = () => {
  //   setSelectedTab(tabsWithSummary.length - 1);
  // };

  // const alert = () => (
  //   <AlertDialog
  //     showDialog={showAlert}
  //     message={alertMessage}
  //      title={config.productTitle}
  //     onClose={() => {
  //       setAlertMessage("");
  //       setShowAlert(false);
  //     }}
  //     hideAlertButton={hideAlertButton}
  //     buttonText='OKAY'
  //   />
  // );

  const toggleCommentVisibility = (
    indicatorId: any,
    indicatorResponse: any
  ) => {
    setComments((prevComments) => ({
      ...prevComments,
      [indicatorId]: {
        ...prevComments[indicatorId],
        isVisible: !prevComments[indicatorId]?.isVisible,
      },
    }));
    // updateIndicatorResponses(indicatorId, indicatorResponse, comments[indicatorId]);
  };

  const handleScroll = () => {
    setShowActionButtons(window.scrollY > 100);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    const checkScroll = () => {
      const hasScrollbar = document.body.scrollHeight > window.innerHeight;
      const container = document.querySelector(".gesButtonContainer");

      if (container) {
        if (hasScrollbar) {
          container.classList.remove("floatingButtons");
        } else {
          container.classList.add("floatingButtons");
        }

        // Check if scroll has reached the bottom
        const isAtBottom =
          window.innerHeight + window.scrollY >= document.body.offsetHeight;

        if (isAtBottom) {
          // Scroll has reached the bottom, add both classes
          container.classList.add("bottom");
          container.classList.add("floatingButtons");
        } else {
          // If not at bottom, remove bottom class
          container.classList.remove("bottom");
          container.classList.add("floatingButtons");
        }
      }
    };

    window.addEventListener("resize", checkScroll);
    window.addEventListener("scroll", checkScroll); // Add scroll event listener

    return () => {
      window.removeEventListener("resize", checkScroll);
      window.removeEventListener("scroll", checkScroll); // Remove scroll event listener
    };
  }, []);

  const ApproveAlert = () => {
    return (
      <DeleteDialog
        showDialog={showApproveAlert}
        message={approveAlertMessage}
        title={config.productTitle}
        onClose={() => setShowApproveAlert(false)}
        onOkButton={() => {
          actionScoreCard("approve");
          setApproveAlertMessage("");
          setShowApproveAlert(false);
        }}
        onCancelButton={() => setShowApproveAlert(false)}
        buttonText="APPROVE"
      />
    );
  };

  const RejectAlert = () => {
    return (
      <DeleteDialog
        showDialog={showRejectAlert}
        message={rejectAlertMessage}
        title={config.productTitle}
        onClose={() => setShowRejectAlert(false)}
        onOkButton={() => {
          actionScoreCard("reject");
          setRejectAlertMessage("");
          setShowRejectAlert(false);
        }}
        onCancelButton={() => setShowRejectAlert(false)}
        buttonText="REJECT"
      />
    );
  };

  const actionScoreCard = async (actionType: string) => {
    setLoading(true);
    const dataToPassToService: any = {};
    dataToPassToService.updatedBy = "naga@atensystems.com";
    dataToPassToService.updatedOn = new Date();
    dataToPassToService.id = scoreId;
    dataToPassToService.statusId = actionType === "approve" ? 1 : 2;
    dataToPassToService.updateScoreIndicatorRequests = [];

    if (indicatorResponseItems && indicatorResponseItems.length > 0) {
      indicatorResponseItems.forEach((item: any) => {
        dataToPassToService.updateScoreIndicatorRequests.push({
          id: item.id,
          response: item.response,
          comments: item.comments ? item.comments : "",
          updatedBy: "naga@atensystems.com",
          updatedOn: new Date(),
        });
      });
    }
    try {
      const data: any = await utility.genericPostAPIcall(
        `${config.updateScore}`,
        dataToPassToService
      );

      if (data) {
        // console.log("score card updated");
        setScorecardSaved(true);

        if (actionType === "approve") {
          setLoading(false);
          navigate("/CEOConfirmation", { state });
        } else {
          setAlertMessage(
            <>
              <p className="AlertIcon">
                {" "}
                <SubmitAlertIcon />
              </p>
              <p className="AlertTitle">Success!</p>
              <p className="AlertMessage">
                Scorecard has been Rejected successfully.
              </p>
            </>
          );
          setShowAlert(true);
          navigate("/CEOHome");
          setLoading(false);
        }
      }
    } catch (error: any) {
      console.error("Error actioning score card", error);
      setAlertMessage(
        <>
          <p className="AlertIcon">
            {" "}
            <WarningIcon />
          </p>
          <p className="AlertTitle">Error!</p>
          <p className="AlertMessage">
            Something went wrong. Please try again later.
          </p>
        </>
      );
      setShowAlert(true);
      setLoading(false);
    }
  };

  return (
    <>
      {showApproveAlert && <ApproveAlert />}
      {showRejectAlert && <RejectAlert />}
      {!loading ? (
        <>
          {GESScoreItems && GESScoreItems.length > 0 ? (
            <>
              <div className="mainContent">
                {/* <h2 className="main-title">Priority Areas</h2> */}

                <div className="">
                  <Grid container>
                    <Grid item xs={12}>
                      <div className="tabs-container">
                        <Tabs
                          value={selectedTab}
                          onChange={handleTabChange}
                          className="tabs-section"
                          variant="scrollable"
                          scrollButtons="auto"
                        >
                          {tabsWithSummary.map((tab: any, index: number) => (
                            <Tab
                              key={tab.id}
                              label={
                                tab.title
                                // <div
                                //   className={`tab-label ${
                                //     selectedTab === index ? "active" : ""
                                //   } ${tab.error ? "error" : ""}`}
                                // >
                                //   <span className="tab-number">
                                //     {index + 1}
                                //   </span>
                                //   {tab.title}
                                // </div>
                              }
                              disabled={
                                tab.title === "Summary" && disableSummarySection
                              }
                            />
                          ))}
                        </Tabs>
                      </div>
                    </Grid>

                    <Grid item xs={12}>
                      {tabsWithSummary.map((tab: any, index: any) => (
                        <TabPanel
                          key={tab.id}
                          value={selectedTab}
                          index={index}
                        >
                          {tab.id === -1 ? (
                            <div className="GESSummaryContainer">
                              <h2 className="main-title">Summary</h2>
                              <Grid container spacing={3}>
                                {GESScoreItems &&
                                  GESScoreItems.length > 0 &&
                                  GESScoreItems.map(
                                    (pItem: any, pIndex: any) => (
                                      <Grid item xs={12} sm={6} key={pIndex}>
                                        <Paper
                                          key={pIndex}
                                          className="GESSummaryPaper"
                                        >
                                          <h4>{`Priority Area ${pIndex + 1}: ${
                                            pItem.title
                                          }`}</h4>
                                          {pItem.scoreResultAreasList &&
                                            pItem.scoreResultAreasList.length >
                                              0 &&
                                            pItem.scoreResultAreasList.map(
                                              (rItem: any, rIndex: any) => (
                                                <div key={rIndex}>
                                                  <div className="raNameWithScores">
                                                    <p className="raTitle">{`${
                                                      rIndex + 1
                                                    }. ${rItem.title}`}</p>
                                                    <p className="dottedSvgContainer">
                                                      <HorizontalDottedLine />
                                                    </p>
                                                    <p className="raScores">{`${rItem.subTotal}/${rItem.maxTotal}`}</p>
                                                  </div>
                                                </div>
                                              )
                                            )}

                                          <div className="paNameWithScores">
                                            <p className="ScoresTopLine">
                                              <HorizontalDottedLine />
                                            </p>
                                            <div className="scoresContent">
                                              <p className="paTitle">
                                                {
                                                  pItem?.genderLabelResponse
                                                    ?.label
                                                }
                                                <span className="paScoreTitle">
                                                  [
                                                  {
                                                    pItem?.genderLabelResponse
                                                      .value
                                                  }
                                                  ]
                                                </span>
                                              </p>
                                              <p className="paScores">{`${pItem.subTotal}/${pItem.maxTotal}`}</p>
                                            </div>
                                          </div>
                                        </Paper>
                                      </Grid>
                                    )
                                  )}
                              </Grid>
                              <h2 className="main-title">Total </h2>
                              <Grid container spacing={3}>
                                {state.statusId && state.statusId === 3 && (
                                  <>
                                    <Grid item xs={12} sm={9}>
                                      <div className="total-section">
                                        <p>
                                          Your unit is:{" "}
                                          <span>{`${
                                            state.genderLabelResponse?.value ??
                                            "TBD"
                                          } [${state.genderLabelResponse
                                            ?.label}]`}</span>
                                        </p>
                                        <p>
                                          Total Score:{" "}
                                          <span>{`${state.totalScore}/${state.maxTotalScore}`}</span>
                                        </p>
                                      </div>
                                    </Grid>
                                    <Grid item xs={12} sm={3}>
                                      <div className="actions-section">
                                        <Button
                                          onClick={() =>
                                            handleRejectButtonClick()
                                          }
                                          className="secondary"
                                        >
                                          Reject
                                        </Button>
                                        <Button
                                          onClick={() =>
                                            handleApproveButtonClick()
                                          }
                                          className="primary"
                                        >
                                          Approve
                                        </Button>
                                      </div>
                                    </Grid>
                                  </>
                                )}

                                {state.statusId !== 3 && (
                                  <>
                                    <Grid item xs={12} sm={12}>
                                      <div className="total-section">
                                        <p>
                                          Your unit is:{" "}
                                          <span>{`${
                                            state.genderLabelResponse?.value ??
                                            "TBD"
                                          } [${state.genderLabelResponse
                                            ?.label}]`}</span>
                                        </p>
                                        <p>
                                          Total Score:{" "}
                                          <span>{`${state.totalScore}/${state.maxTotalScore}`}</span>
                                        </p>
                                      </div>
                                    </Grid>
                                  </>
                                )}
                              </Grid>
                            </div>
                          ) : (
                            <>
                              {index === selectedTab && (
                                <div className="GESDetailsContainer">
                                  <div>
                                    {tab.scoreResultAreasList &&
                                      tab.scoreResultAreasList.map(
                                        (resultArea: any, rindex: any) => (
                                          <div key={resultArea.id}>
                                            {/* Add a key to the parent element */}
                                            <h2 className="main-title">
                                              {resultArea.title}
                                            </h2>
                                            {resultArea.scoreIndicatorList &&
                                              resultArea.scoreIndicatorList.map(
                                                (
                                                  indicator: any,
                                                  indIndex: any
                                                ) => (
                                                  <Paper
                                                    key={indicator.id}
                                                    className="GESDetailsPaper"
                                                  >
                                                    <div className="raWithScores">
                                                      <h4>
                                                        {
                                                          indicator.indicatorTitle
                                                        }

                                                        {validationChecksForSubmission &&
                                                          indicatorResponseItems &&
                                                          indicatorResponseItems
                                                            .filter(
                                                              (res: any) =>
                                                                res.indicatorId ===
                                                                indicator.indicatorId
                                                            )
                                                            .map(
                                                              (item: any) =>
                                                                item.response ===
                                                                  null && (
                                                                  <span
                                                                    key={
                                                                      item.id
                                                                    }
                                                                    className="response-error-message"
                                                                  >
                                                                    <WarningIcon />{" "}
                                                                    Mandatory to
                                                                    answer
                                                                  </span>
                                                                )
                                                            )}
                                                      </h4>
                                                      <div className="response-section">
                                                        <div className="prev-response">
                                                          <p>
                                                            [
                                                            {`${
                                                              +scoreYear - 1
                                                            } SCORE: ${
                                                              (
                                                                previousYearScoresList.find(
                                                                  (ps: any) =>
                                                                    ps.indicatorId ===
                                                                    indicator.indicatorId
                                                                ) || {}
                                                              ).response ||
                                                              "N/A"
                                                            }`}
                                                            ]
                                                          </p>
                                                        </div>
                                                        <div className="current-response">
                                                          <p>
                                                            Your Current
                                                            Response :
                                                          </p>
                                                          <div className="radio-container tf">
                                                            <RadioGroup
                                                              aria-label="response-options"
                                                              name="response-options"
                                                              value={
                                                                indicator.response ||
                                                                ""
                                                              }
                                                              row
                                                            >
                                                              {GESRadioOptionsList.map(
                                                                (option) => (
                                                                  <FormControlLabel
                                                                    key={option}
                                                                    value={option.toString()}
                                                                    control={
                                                                      <Radio
                                                                        color="primary"
                                                                        icon={
                                                                          <RadioUncheckedIcon />
                                                                        }
                                                                        checkedIcon={
                                                                          <RadioCheckedIcon />
                                                                        }
                                                                      />
                                                                    }
                                                                    label={option.toString()}
                                                                  />
                                                                )
                                                              )}
                                                            </RadioGroup>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                    <div className="accordion-container tf">
                                                      <Accordion
                                                        expanded={
                                                          !!expandedState[
                                                            index
                                                          ]?.[resultArea.id]?.[
                                                            indicator.id
                                                          ]?.expanded
                                                        }
                                                        onChange={() =>
                                                          handleAccordionChange(
                                                            index,
                                                            resultArea.id,
                                                            indicator.id
                                                          )
                                                        }
                                                        id={`accordion-${index}-${resultArea.resultAreaId}-${indicator.id}`}
                                                        className="indicator-accordion"
                                                        key={`accordion-${index}-${resultArea.resultAreaId}-${indicator.id}`}
                                                      >
                                                        <AccordionSummary
                                                          expandIcon={
                                                            <ExpandMoreIcon />
                                                          }
                                                          aria-controls={`panel-${index}-${resultArea.resultAreaId}-${indicator.indicatorId}-content`}
                                                          id={`panel-${index}-${resultArea.id}-${indicator.indicatorId}-header`}
                                                          key={`accordion-summary-${index}-${resultArea.resultAreaId}-${indicator.id}`}
                                                        >
                                                          <div>
                                                            <p className="indicator-desc-titles">
                                                              {accordionTitles[
                                                                `tab-${index}-accordion-${resultArea.id}-${indicator.indicatorId}`
                                                              ] ||
                                                                `*Click Here to view response Descriptions*`}
                                                            </p>

                                                            {/* Render the selected option outside of AccordionDetails */}
                                                            {!expandedState[
                                                              index
                                                            ]?.[
                                                              resultArea.id
                                                            ]?.[indicator.id]
                                                              ?.expanded &&
                                                              GESItems.filter(
                                                                (indDes: any) =>
                                                                  indDes.indicatorId ===
                                                                  indicator.indicatorId
                                                              ).flatMap(
                                                                (
                                                                  matchingIndDes: any
                                                                ) =>
                                                                  matchingIndDes.unitIndicatorDescriptionList.map(
                                                                    (
                                                                      descriptionItem: any
                                                                    ) =>
                                                                      descriptionItem.response ==
                                                                        indicator.response && (
                                                                        <p
                                                                          key={
                                                                            descriptionItem.id
                                                                          } // Assuming there is a unique ID for each descriptionItem
                                                                          className="selected"
                                                                        >
                                                                          {
                                                                            descriptionItem.response
                                                                          }{" "}
                                                                          -{" "}
                                                                          {
                                                                            descriptionItem.description
                                                                          }
                                                                        </p>
                                                                      )
                                                                  )
                                                              )}
                                                          </div>
                                                        </AccordionSummary>
                                                        <AccordionDetails>
                                                          {GESItems && (
                                                            <>
                                                              {GESItems.filter(
                                                                (indDes: any) =>
                                                                  indDes.indicatorId ===
                                                                  indicator.indicatorId
                                                              ).map(
                                                                (
                                                                  matchingIndDes: any
                                                                ) =>
                                                                  matchingIndDes.unitIndicatorDescriptionList.map(
                                                                    (
                                                                      descriptionItem: any,
                                                                      index: number
                                                                    ) => (
                                                                      <p
                                                                        key={
                                                                          index
                                                                        }
                                                                        className={
                                                                          descriptionItem.response ==
                                                                          indicator.response
                                                                            ? "selected"
                                                                            : ""
                                                                        }
                                                                      >
                                                                        {
                                                                          descriptionItem.response
                                                                        }{" "}
                                                                        -{" "}
                                                                        {
                                                                          descriptionItem.description
                                                                        }
                                                                      </p>
                                                                    )
                                                                  )
                                                              )}
                                                            </>
                                                          )}
                                                        </AccordionDetails>
                                                      </Accordion>
                                                    </div>
                                                    {indicatorResponseItems &&
                                                      indicatorResponseItems
                                                        .filter(
                                                          (res: any) =>
                                                            res.indicatorId ===
                                                            indicator.indicatorId
                                                        )
                                                        .map((item) => (
                                                          <Grid
                                                            item
                                                            xs={12}
                                                            sm={12}
                                                          >
                                                            <div
                                                              className="comments-section"
                                                              style={{
                                                                display: "flex",
                                                                justifyContent:
                                                                  "space-between",
                                                                alignItems:
                                                                  "center",
                                                              }}
                                                            >
                                                              <p
                                                                className="commentsTitle"
                                                                onClick={() =>
                                                                  toggleCommentVisibility(
                                                                    indicator.indicatorId,
                                                                    indicator.response?.toString()
                                                                  )
                                                                }
                                                                style={{
                                                                  cursor:
                                                                    "pointer",
                                                                }}
                                                              >
                                                                {item.comments && (
                                                                  <>
                                                                    <Chat />{" "}
                                                                    {comments[
                                                                      indicator
                                                                        .indicatorId
                                                                    ]?.isVisible
                                                                      ? "Comment: "
                                                                      : "View Comment"}
                                                                  </>
                                                                )}
                                                              </p>
                                                            </div>

                                                            {comments[
                                                              indicator
                                                                .indicatorId
                                                            ]?.isVisible && (
                                                              <Grid
                                                                item
                                                                xs={12}
                                                                sm={12}
                                                              >
                                                                <TextAreaInput
                                                                  value={
                                                                    item.comments ||
                                                                    ""
                                                                  }
                                                                  stylingMode="outlined"
                                                                  label="comment"
                                                                  minHeight={50}
                                                                  onValueChanged={() => {}}
                                                                  readOnly={
                                                                    true
                                                                  }
                                                                />
                                                              </Grid>
                                                            )}
                                                          </Grid>
                                                        ))}
                                                  </Paper>
                                                )
                                              )}
                                          </div>
                                        )
                                      )}
                                  </div>
                                </div>
                              )}
                            </>
                          )}
                        </TabPanel>
                      ))}
                    </Grid>
                  </Grid>
                </div>

                {showActionButtons &&
                  !(selectedTab === tabsWithSummary.length - 1) && (
                    <div className="gesButtonContainer floatingButtons tf">
                      <div className="navigate-section-tf">
                        <Button
                          className="primary"
                          onClick={goToPreviousTab}
                          disabled={selectedTab === 0}
                          fullWidth
                        >
                          Back
                        </Button>

                        <Button
                          className="primary"
                          onClick={goToNextTab}
                          disabled={
                            disableSummarySection
                              ? selectedTab === tabsWithSummary.length - 2
                              : selectedTab === tabsWithSummary.length - 1
                          }
                          fullWidth
                        >
                          Next
                        </Button>
                      </div>
                    </div>
                  )}
              </div>
            </>
          ) : (
            <div className="no-results">
              <h2 className="main-title">No Results Found</h2>
            </div>
          )}
        </>
      ) : (
        <div className="loader-container">
          <Spinner size="large" label="Loading ..." />
        </div>
      )}
    </>
  );
};

export default CEOGES;
