import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import SelectBoxInput from "../Common/ReusableComponents/SelectInputBox";
import SelectBox from "devextreme-react/select-box";
import DateBox from "devextreme-react/date-box";
import TextBox from "../Common/ReusableComponents/TextBox";
import utility from "../../classes/utility";
import Config from "../../classes/config";
import FormControlLabel from "@mui/material/FormControlLabel/FormControlLabel";
import Switch from "@mui/material/Switch/Switch";
import Typography from "@mui/material/Typography/Typography";
import FormGroup from "@mui/material/FormGroup/FormGroup";
import FormControl from "@mui/material/FormControl/FormControl";
import FormLabel from "@mui/material/FormLabel/FormLabel";
import FormHelperText from "@mui/material/FormHelperText/FormHelperText";
import { useLocation, useNavigate } from "react-router-dom";
import Spinner from "../Common/ReusableComponents/Spinner";
// import MultiSelectBox from "../Common/ReusableComponents/MultiSelectBox";
import { TagBox } from "devextreme-react/tag-box";
import Button from "@mui/material/Button";
import { ReactComponent as WarningIcon } from "../../images/Warning.svg";
import { ReactComponent as SubmitAlertIcon } from "../../images/SubmitAlert.svg";
import AlertDialog from "../Common/AlertDialog";
import DeleteAlert from "../Common/DeleteAlert";

interface RoleType {
  id: number;
  title: string;
}

const AdminUserAddEdit = () => {
  const navigate = useNavigate();
  const [role, setRole] = useState<RoleType | null>(null);
  const [roleItems, setRoleItems] = useState<any[]>([]);
  const [unit, setUnit] = useState<RoleType[]>([]);
  const [unitItems, setUnitItems] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [active, setActive] = useState(false);
  const [user, setUser] = useState<any>({});
  const [userEmail, setUserEmail] = useState<string>("");
  const [fullName, setFullName] = useState<string>("");
  const [alertMessage, setAlertMessage] = useState<JSX.Element | string>("");
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [hideAlertButton, setHideAlertButton] = useState<boolean>(false);
  const [deleteMessage, setDeleteMessage] = useState<JSX.Element | string>("");
  const [showDeleteAlert, setShowDeleteAlert] = useState<boolean>(false);
  const [deleteSuccessMessage, setDeleteSuccessMessage] = useState<
    JSX.Element | string
  >("");
  const [showDeleteSuccessAlert, setShowDeleteSuccessAlert] =
    useState<boolean>(false);
  const [validated, setValidated] = useState<boolean>(false);
  const [navigateToUsersScreen, setNavigateToUsersScreen] =
    useState<boolean>(false);
    const [isUnitDisabled, setIsUnitDisabled] = useState<boolean>(false);

  const location = useLocation();
  const queryParameters = new URLSearchParams(location.search);
  const userId = queryParameters.get("ID");

  useEffect(() => {
    setLoading(true);
    getRoleData();
    getUnitData();

    if (userId !== "0") {
      getUserData(Number(userId));
    }

    setLoading(false);
  }, []);

  const getUserData = async (userId: number) => {
    try {
      let email: string = "";

      const r: any = await utility.genericGetAPICall(
        `${Config.users}/${userId}`
      );
      let _user: any = [];
      if (!r.isDeleted) {
        _user = {
          id: r.id,
          fullName: r.fullName,
          email: r.email,
          units: r.units,
          isActive: r.isActive,
          updatedBy: r.updatedBy,
          updatedOn: r.updatedOn,
          createdBy: r.createdBy,
          createdOn: r.createdOn,
          isDeleted: r.isDeleted,
        };
        setUserEmail(_user.email);
        email = _user.email;
      }

      const data = await utility.genericGetAPICall(`${Config.userUnitsRoles}`);
      console.log("email: " + email);

      let result = data.filter((x: any) => x.email === email);
      for (let r of result) {
        // let userRoles: any;
        if (r.userRoleDetails.length > 0) {
          setRole(r.userRoleDetails[0]);
          // for (let role of r.userRoleDetails) {
          //   userRoles.push(role.id);
          // }
        }
        // const userUnitObject =
        //   Array.isArray(r.userUnits) && r.userUnits.length > 0
        //     ? r.userUnits[0]
        //     : null;

        let userUnitObject: any = [];
        if (r.userUnits.length > 0) {
          for (let unit of r.userUnits) {
            userUnitObject.push(unit.id);
          }
        }

        _user = {
          id: r.id,
          fullName: r.fullName,
          email: r.email,
          units: r.units,
          userUnits: r.userUnits,
          userRoleDetails: r.userRoleDetails,
          isActive: r.isActive,
          updatedBy: r.updatedBy,
          updatedOn: r.updatedOn,
          createdBy: r.createdBy,
          createdOn: r.createdOn,
          isDeleted: r.isDeleted,
        };
        // setRole(userRoles);

        setUnit(userUnitObject);
        console.log("units: " + userUnitObject);
        setActive(r.isActive);
        setFullName(r.fullName);
      }
      setUser(_user);
      setLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  const getRoleData = async () => {
    try {
      const data: any = await utility.genericGetAPICall(
        `${Config.roles}?pageSize=-1&pageNumber=-1&showAll=true&previousId=-1`
      );
      let _roleItems: any = [];
      for (let r of data) {
        _roleItems.push({
          id: r.id,
          title: r.title,
          // updatedBy: r.updatedBy,
          // updatedOn: r.updatedOn,
          // createdBy: r.createdBy,
          // createdOn: r.createdOn,
          // isDeleted: r.isDeleted,
        });
      }
      setRoleItems(_roleItems);
    } catch (err) {
      console.log(err);
    }
  };

  const getUnitData = async () => {
    try {
      const data: any = await utility.genericGetAPICall(
        `${Config.units}?pageSize=-1&pageNumber=-1&showAll=true&previousId=-1`
      );

      let _unitItems: any = [];
      for (let r of data) {
        _unitItems.push({
          id: r.id,
          title: r.title,
          // updatedBy: r.updatedBy,
          // updatedOn: r.updatedOn,
          // createdBy: r.createdBy,
          // createdOn: r.createdOn,
          // isDeleted: r.isDeleted,
        });
      }
      setUnitItems(_unitItems);
    } catch (err) {
      console.log(err);
    }
  };

  const handleActiveChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setActive(event.target.checked);
  };

  const navigateUsersScreen = (redirect?: any) => {
    if (navigateToUsersScreen || redirect) navigate(`/AdminUsers`);
  };

  const alert = () => (
    <AlertDialog
      showDialog={showAlert}
      message={alertMessage}
      title={""}
      onClose={() => {
        setAlertMessage("");
        setShowAlert(false);
        navigateUsersScreen();
      }}
      hideAlertButton={hideAlertButton}
      buttonText="OKAY"
    />
  );

  const deleteAlert = () => (
    <DeleteAlert
      showDialog={showDeleteAlert}
      message={deleteMessage}
      title={user.fullName}
      onClose={() => setShowDeleteAlert(false)}
      onOkButton={() => {
        setShowDeleteAlert(false);
        deleteUser();
      }}
      onCancelButton={() => setShowDeleteAlert(false)}
      buttonText="Delete"
    />
  );

  const deleteSuccessAlert = () => (
    <AlertDialog
      showDialog={showDeleteSuccessAlert}
      message={deleteSuccessMessage}
      title={""}
      onClose={() => {
        setDeleteSuccessMessage("");
        setShowDeleteSuccessAlert(false);
        navigate(`/AdminUsers`);
      }}
      hideAlertButton={hideAlertButton}
      buttonText="OKAY"
    />
  );

  const validate = () => {
    if (fullName === "") {
      setAlertMessage(
        <>
          <p className="AlertIcon">
            {" "}
            <WarningIcon />
          </p>
          <p className="AlertTitle">Error!</p>
          <p className="AlertMessage">Please enter Full Name.</p>
        </>
      );
      setShowAlert(true);
      setLoading(false);
      return false;
    }

    if (userEmail === "") {
      setAlertMessage(
        <>
          <p className="AlertIcon">
            {" "}
            <WarningIcon />
          </p>
          <p className="AlertTitle">Error!</p>
          <p className="AlertMessage">Please enter Email.</p>
        </>
      );
      setShowAlert(true);
      setLoading(false);
      return false;
    }

    if (role === null) {
      setAlertMessage(
        <>
          <p className="AlertIcon">
            {" "}
            <WarningIcon />
          </p>
          <p className="AlertTitle">Error!</p>
          <p className="AlertMessage">Please select Role.</p>
        </>
      );
      setShowAlert(true);
      setLoading(false);
      return false;
    }

    if (unit === null) {
      setAlertMessage(
        <>
          <p className="AlertIcon">
            {" "}
            <WarningIcon />
          </p>
          <p className="AlertTitle">Error!</p>
          <p className="AlertMessage">Please select Unit.</p>
        </>
      );
      setShowAlert(true);
      setLoading(false);
      return false;
    }

    setValidated(true);
    return true;
  };

  const handleSaveClick = async () => {
    try {
      setValidated(false);
      let result = validate();

      if (result) {
        setLoading(true);
        let currentUserEmail: string =
          localStorage.getItem("currentUserEmail")!;
        //const roleIdsList = role.map((r: RoleType) => r.id);

        let dataToPost: any = {};
        dataToPost = {
          id: Number(userId),
          createdBy: currentUserEmail,
          updatedBy: currentUserEmail,
          createdOn: new Date(),
          updatedOn: new Date(),
          units: (unit && !isUnitDisabled)? unit : [],
          tenantId: 1,
          isDeleted: false,
          deletedBy: null,
          deletedOn: null,
          fullName: fullName,
          email: userEmail,
          isActive: true,
          roleIds: role ? [role.id] : null,
        };

        if (userId === "0") {
          const data: any = await utility.genericPostAPIcall(
            `${Config.createUserWithRole}`,
            dataToPost
          );
          console.log("data: " + data);
          setAlertMessage(
            <>
              <p className="AlertIcon">
                {" "}
                <SubmitAlertIcon />
              </p>
              <p className="AlertTitle">Success!</p>
              <p className="AlertMessage">
                User has been created successfully.
              </p>
            </>
          );
          setShowAlert(true);
          navigate(`/AdminUsers`);
          setLoading(false);
        } else {
          const data: any = await utility.genericPostAPIcall(
            `${Config.updateUserWithRole}/${userId}`,
            dataToPost
          );
          console.log("data: " + data);
          setNavigateToUsersScreen(true);
          setAlertMessage(
            <>
              <p className="AlertIcon">
                {" "}
                <SubmitAlertIcon />
              </p>
              <p className="AlertTitle">Success!</p>
              <p className="AlertMessage">
                User has been updated successfully.
              </p>
            </>
          );
          setShowAlert(true);
          setLoading(false);
        }
      }
    } catch (err) {
      console.log(err);
      setAlertMessage(
        <>
          <p className="AlertIcon">
            {" "}
            <WarningIcon />
          </p>
          <p className="AlertTitle">Error!</p>
          <p className="AlertMessage">
            Something went wrong. Please try again later.
          </p>
        </>
      );
      setShowAlert(true);
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteClick = () => {
    setDeleteMessage(
      <>
        <p className="AlertIcon">
          {" "}
          <WarningIcon />
        </p>
        <p className="AlertTitle">Alert!</p>
        <p className="AlertMessage">
          Are you sure that you want to delete this item?.
        </p>
      </>
    );
    setShowDeleteAlert(true);
    setLoading(false);
  };

  const deleteUser = async () => {
    try {
      let currentUserEmail: string = localStorage.getItem("currentUserEmail")!;

      let dataToPost: any = {};
      dataToPost = {
        id: userId,
        deletedBy: currentUserEmail,
      };

      const result: any = await utility.genericDeleteAPICall(
        `${Config.users}`,
        dataToPost
      );
      if (result.ok) {
        // resolve(result.json());
        console.log("data OK: " + result);
      } else {
        let errorText = await result.text();
        throw new Error(errorText);
      }
      console.log("data: " + result);
    } catch (err) {
      console.log(err);
    }

    setDeleteSuccessMessage(
      <>
        <p className="AlertIcon">
          {" "}
          <WarningIcon />
        </p>
        <p className="AlertTitle">Successfull!</p>
        <p className="AlertMessage">
          The user with Id: {userId} has been deleted.
        </p>
      </>
    );
    setShowDeleteSuccessAlert(true);
    setLoading(false);
  };

  const handleCancelClick = () => {
    //window.history.back();
    // let path = `/AdminUsers`;
    // navigate(path);
    navigateUsersScreen(true);
  };

  const _handleFullNameChanged = (user: any, value: string) => {
    user.fullName = value;

    setUser(user);
    setFullName(value);
  };

  const handleRolechange = (e: any) => {
    if(e.selectedItem.id === 2 || e.selectedItem.title === 'Task Force'){
      setUnit([]);
      setIsUnitDisabled(true);
    }else{
      setIsUnitDisabled(false);
    }
    setRole(e.selectedItem);
  };

  const _handleEmailChanged = (user: any, value: string) => {
    user.email = value;

    setUser(user);
    setUserEmail(value);
  };

  return (
    <div className="ui-container admin-user">
      {showAlert && alert()}
      {showDeleteAlert && deleteAlert()}
      {showDeleteSuccessAlert && deleteSuccessAlert()}
      {/* {userId !== undefined ? <h1>Edit User</h1> : <h1>Add User</h1>} */}
      {!loading ? (
        <>
          {user ? (
            <>
              <Grid container spacing={3}>
                <Grid item container xs={12} sm={10} spacing={2}>
                  <Grid item xs={12} sm={4}>
                    <TextBox
                      stylingMode={"text"}
                      value={user.fullName}
                      onValueChanged={(e: any) =>
                        _handleFullNameChanged(user, e.value)
                      }
                      //onKeyUp={(e: any) => setFullName(e.value)}
                      label={"Full Name"}
                    />
                  </Grid>
                </Grid>
                <Grid item container xs={12} sm={10} spacing={2}>
                  <Grid item xs={12} sm={4}>
                    <TextBox
                      stylingMode={"text"}
                      value={user.email}
                      onValueChanged={(e: any) =>
                        _handleEmailChanged(user, e.value)
                      }
                      label={"Email"}
                    />
                  </Grid>
                </Grid>
                <Grid item container xs={12} sm={10} spacing={2}>
                  <Grid item xs={12} sm={4}>
                    {/* <SelectBoxInput
                      stylingMode="outlined"
                      displayExpr="title"
                      valueExpr="id"
                      showClearButton={true}
                      label="Role"
                      items={roleItems}
                      selectedItem={role}
                      //onSelectionChanged={(e: any) => setRole(e.selectedItem)}
                      onSelectionChanged={(e: any) => handleRolechange(e)}
                    /> */}
                    <SelectBox
                          stylingMode="outlined"
                          showClearButton={true}
                          label="Role"
                          valueExpr="id"
                          displayExpr="title"
                          dataSource={roleItems}
                          selectedItem={role}
                          defaultValue={role}
                          onSelectionChanged={(e: any) => {
                            handleRolechange(e);
                          }}
                        />
                  </Grid>
                </Grid>
                <Grid item container xs={12} sm={10} spacing={2}>
                  <Grid item xs={12} sm={4}>
                    <TagBox
                      dataSource={unitItems}
                      valueExpr="id"
                      value={unit ? unit : []}
                      searchEnabled={true}
                      displayExpr="title"
                      onValueChanged={(e: any) => setUnit(e.value)}
                      label="Unit"
                      disabled={isUnitDisabled || role?.title === 'Task Force'}
                    />
                  </Grid>
                </Grid>
                <Grid item container xs={12} sm={10} spacing={2}>
                  <Grid item xs={12} sm={4} className="toggle">
                    <span className="header-values toggle-buttons">
                      <FormControl component="fieldset" variant="standard">
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <Switch
                                checked={active}
                                onChange={handleActiveChange}
                                name="active"
                              />
                            }
                            label="Active"
                          />
                        </FormGroup>
                      </FormControl>
                    </span>
                  </Grid>
                </Grid>
                <Grid item container xs={12} sm={10} spacing={2}>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    container
                    className="filter-buttons"
                  >
                    <Grid item>
                      <div className="confirm-action-buttons">
                        <Button
                          className="btn btn-primary"
                          onClick={handleSaveClick}
                        >
                          Save
                        </Button>
                      </div>
                    </Grid>
                    <Grid item>
                      <div className="confirm-action-buttons">
                        <Button
                          className="btn btn-secondary"
                          onClick={handleCancelClick}
                        >
                          Cancel
                        </Button>
                      </div>
                    </Grid>
                    <Grid item>
                      <div className="confirm-action-buttons">
                        <Button
                          className="btn btn-danger"
                          onClick={handleDeleteClick}
                        >
                          Delete
                        </Button>
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </>
          ) : (
            <div className="no-results">
              <h2 className="main-title">No Results Found</h2>
            </div>
          )}
        </>
      ) : (
        <Spinner size="large" />
      )}
    </div>
  );
};

export default AdminUserAddEdit;
