import React, { useState, useEffect } from "react";
import "./App.css";
import Home from "./Home";
import {
  AuthenticatedTemplate,
  // UnauthenticatedTemplate,
} from "@azure/msal-react";
import { useMsal, MsalProvider } from "@azure/msal-react";
import utility from "./classes/utility";
import config from "./classes/config";
import {
  PublicClientApplication,
  AccountInfo,
  PopupRequest,
} from "@azure/msal-browser";
import { msalConfig } from "./classes/authConfig";
import Spinner from "./components/Common/ReusableComponents/Spinner";

// export const msalInstance = new PublicClientApplication(msalConfig);

/**
 * If a user is authenticated the Home component above is rendered. Otherwise a message indicating a user is not authenticated is rendered.
 */

export const pca = new PublicClientApplication(msalConfig);
await pca.initialize();
const accounts: AccountInfo[] = pca.getAllAccounts();
if (accounts && accounts[0]) {
  const account: AccountInfo = accounts[0];
  if (account) {
    pca.setActiveAccount(account);
    // acquireTokenAndCallApi(pca, account);
  }
} else {
  const popupRequest: PopupRequest = { scopes: [] };
  // const res = await pca.acquireTokenPopup(popupRequest);
  // if (res && res.accessToken && res.account) {
  //   pca.setActiveAccount(res.account);
  //   // acquireTokenAndCallApi(pca, res.account);
  // }

  //Msal Redirect Flow
  let tokenResponse = await pca.handleRedirectPromise();

  let accountObj;
  if (tokenResponse) {
    accountObj = tokenResponse.account;
    pca.setActiveAccount(accountObj);
  } else {
    accountObj = pca.getAllAccounts()[0];
  }

  if (accountObj && tokenResponse) {
    console.log("[AuthService.init] Got valid accountObj and tokenResponse");
  } else if (accountObj) {
    console.log("[AuthService.init] User has logged in, but no tokens.");
    try {
      tokenResponse = await pca.acquireTokenSilent({
        account: pca.getAllAccounts()[0],
        scopes: ["user.read"],
      });
    } catch (err) {
      await pca.acquireTokenRedirect({ scopes: ["user.read"] });
    }
  } else {
    console.log(
      "[AuthService.init] No accountObject or tokenResponse present. User must now login."
    );
    await pca.loginRedirect({ scopes: ["user.read"] });
  }
}

function MainComponent() {
  const { accounts } = useMsal();
  const [roleId, setRoleId] = useState(null);
  const [unitId, setUnitId] = useState<number | null>(null);
  const [currentYearRecordExists, setCurrentYearRecordExists] = useState(false);
  const [loading, setLoading] = useState(false);

  localStorage.setItem("currentDetail", accounts[0]?.username);
  const currentYear = new Date().getFullYear();

  let currentUser = {
    DisplayName: accounts[0]?.name,
    Email: accounts[0]?.username,
    LoginName: accounts[0]?.username,
  };

  useEffect(() => {
    if (accounts && accounts.length > 0) {
      currentUser = {
        DisplayName: accounts[0]?.name,
        Email: accounts[0]?.username,
        LoginName: accounts[0]?.username,
      };
      onInit();
    }
  }, [accounts]);

  const onInit = async() => {
    let dataToPassToService: any = {};
    setLoading(true);

    if (currentUser && currentUser.Email) {
      try {
        const userData = await utility.genericGetAPICall(`${config.getUserByEmail}/${currentUser.Email}`);
        if (userData) {
          const currentUser = userData;

          if (currentUser && currentUser.userRoles) {
            setRoleId(currentUser.userRoles[0].roleId);
            localStorage.setItem("roleId", currentUser.userRoles[0].roleId);
          }

          if (currentUser.units && currentUser.units.length > 0) {
            setUnitId(currentUser.units[0]);
            dataToPassToService.unitId = currentUser.units[0];
            localStorage.setItem("unitId", currentUser.units[0]);

            // setUnitId(4);
            // dataToPassToService.unitId = 4;
            // localStorage.setItem("unitId", '4');

            const searchScoresData = await utility.genericPostAPIcall(`${config.searchScores}?showAll=true&pageSize=-1&pageNumber=-1`,dataToPassToService);
              
            if (searchScoresData) {
              const activeRecordsForThisYear: any =
                searchScoresData.filter(
                  (r: any) =>
                    r.isDeleted === false && r.year === currentYear
                );

              if (
                activeRecordsForThisYear &&
                activeRecordsForThisYear.length > 0
              ) {
                setCurrentYearRecordExists(true);
              }
            }
          }
        }
      }
        catch(err: any) {
            console.log(err);
          }
        finally{
          setLoading(false);
        };
    } else {
      console.log("No user found");
      setLoading(false);
    }
  };

  return (
    <>
      <AuthenticatedTemplate>
        <>
          {!loading ? (
            <Home
              roleId={roleId}
              unitId={unitId}
              currentYearRecordExists={currentYearRecordExists}
            />
          ) : (
            <div>
              {" "}
              <br /> <Spinner size="large" label="" />
            </div>
          )}
        </>
      </AuthenticatedTemplate>
      {/* <UnauthenticatedTemplate>
        <LoginPage />
      </UnauthenticatedTemplate> */}
    </>
  );
}

function App() {
  return (
    <>
      <MsalProvider instance={pca}>
        <MainComponent />
      </MsalProvider>

      {/* <AuthenticatedTemplate>
        <Home />
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <LoginPage />
      </UnauthenticatedTemplate> */}
    </>
  );
}

export default App;
