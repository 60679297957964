import React, { useState, useEffect } from "react";
import CircularProgressInput from "../Common/ReusableComponents/CircularProgressInput";
import Button from "@mui/material/Button";
import { useNavigate, useLocation } from "react-router-dom";
import Spinner from "../Common/ReusableComponents/Spinner";

const GRTConfirmation = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { state } = location;
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        // Check if the necessary state values are available
        if (state && state.totalScore !== undefined && state.maxTotalScore !== undefined && state.percentComplete !== undefined) {
            // If state values are available, set loading to false
            setLoading(false);
        }
    }, [state]);

    const gotoHomePage = () => {
        navigate('/');
    };

    const gotoScoreCardsPage = () => {
        navigate('/GRTScoreCards');
    };

    return (
        <div className="confirm-section">
            {loading ? (
                // Display the Spinner while loading
                <Spinner size="large" label="Submitting scores..." />
            ) : (
                <>
                    <h2 className="main-title">Your Scores have been submitted!</h2>
                    <div className="unit-details">
                        <p>Your unit is</p>
                        <p><b>{`${state.totalScore}/${state.maxTotalScore}`}</b></p>
                        <div className="progress-section">
                            <CircularProgressInput value={`${state.percentComplete}%\n COMPLETE`} circleColor="#D9E1E1" progressColor="#8ED914" size="100" />
                        </div>
                    </div>

                    <div className="confirm-action-buttons">
                        <Button className="btn btn-secondary" onClick={gotoScoreCardsPage}>Check Previous scores</Button>
                        <Button className="btn btn-primary" onClick={gotoHomePage}>Go Home</Button>
                    </div>
                </>
            )}
        </div>
    );
};

export default GRTConfirmation;
