import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import clsx from "clsx";
import Drawer from "@mui/material/Drawer";
import ListItem from "@mui/material/ListItem";
import { styled } from "@mui/system";
import ListItemText from "@mui/material/ListItemText";

//images
// import { ReactComponent as GRTMenuLogo } from "../../images/GRTMenuLogo.svg";
import { ReactComponent as GRTMenuLogo } from "../../images/Logo akf -01.svg";
import { ReactComponent as MenuCloseLogo } from "../../images/MenuClose.svg";
import { ReactComponent as MenuOpenIcon } from "../../images/MenuOpen.svg";

const drawerWidth = 230;

const StyledDrawer: any = styled(Drawer)(({ theme }) => ({
  width: drawerWidth,
  flexShrink: 0,
}));

const DrawerPaper: any = styled("div")(({ theme }) => ({
  width: drawerWidth,
}));

const DrawerContainer: any = styled("div")(({ theme }) => ({
  overflow: "auto",
}));

//route
interface NavigationProps {
  roleId: number | null;
  unitId: number | null;
  hideDrawer: Boolean;
  hideDetail?: Boolean;
  currentYearRecordExists: boolean;
}

const Navigation = (props: NavigationProps) => {
  const [open, setOpen] = useState(true);

  const roleId = props.roleId;

  const handleToggleDrawer = () => {
    setOpen(!open);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  let isNarrow = false;
  if (window.innerWidth <= 1024) {
    isNarrow = true;
  }

  const renderCEOList = () => {
    return (
      <>
        {/* CEO List */}
        <NavLink className="is-active sideMenu subNavItem" to="/CEOHome" onClick={handleDrawerClose}>
          <ListItem className={"nested"}>
            <ListItemText primary={"Home"} className={"ml-2"} />
          </ListItem>
        </NavLink>
        <NavLink className="is-active sideMenu subNavItem" to="/CEOScoreCards" onClick={handleDrawerClose}>
          <ListItem className={"nested"}>
            <ListItemText primary={"Score Cards"} className={"ml-2"} />
          </ListItem>
        </NavLink>
        <NavLink className="is-active sideMenu subNavItem" to="/CEOReports" onClick={handleDrawerClose}>
          <ListItem className={"nested"}>
            <ListItemText primary={"Reports"} className={"ml-2"} />
          </ListItem>
        </NavLink>
      </>
    );
  };

  const renderGRTList = () => {
    return (
      <>
        {/* GRT List */}
        <NavLink className="is-active sideMenu subNavItem" to="/GRTHome" onClick={handleDrawerClose}>
          <ListItem className={"nested"}>
            {/* <ListItemIcon /> */}
            <ListItemText primary={"Home"} className={"ml-2"} />
          </ListItem>
        </NavLink>
        <NavLink className="is-active sideMenu subNavItem" to="/GRTScoreCards" onClick={handleDrawerClose}>
          <ListItem className={"nested"}>
            {/* <ListItemIcon /> */}
            <ListItemText primary={"Score Cards"} className={"ml-2"} />
          </ListItem>
        </NavLink>
        <NavLink className="is-active sideMenu subNavItem" to="/GRTReports" onClick={handleDrawerClose}>
          <ListItem className={"nested"}>
            {/* <ListItemIcon /> */}
            <ListItemText primary={"Reports"} className={"ml-2"} />
          </ListItem>
        </NavLink>
        {/* <NavLink className="is-active sideMenu subNavItem" to="/GRTGES">
          <ListItem className={"nested"}>
            <ListItemIcon />
            <ListItemText primary={"GES"} className={"ml-2"} />
          </ListItem>
        </NavLink> */}
      </>
    );
  };

  const renderTFList = () => {
    return (
      <>
        {/* TF List */}
        <NavLink className="is-active sideMenu subNavItem" to="/TFHome" onClick={handleDrawerClose}>
          <ListItem className={"nested"}>
            <ListItemText primary={"Home"} className={"ml-2"} />
          </ListItem>
        </NavLink>
        <NavLink className="is-active sideMenu subNavItem" to="/TFScoreCards" onClick={handleDrawerClose}>
          <ListItem className={"nested"}>
            <ListItemText primary={"Score Cards"} className={"ml-2"} />
          </ListItem>
        </NavLink>
        <NavLink className="is-active sideMenu subNavItem" to="/TFReports" onClick={handleDrawerClose}>
          <ListItem className={"nested"}>
            <ListItemText primary={"Reports"} className={"ml-2"} />
          </ListItem>
        </NavLink>
        <NavLink className="is-active sideMenu subNavItem" to="/AdminUsers" onClick={handleDrawerClose}>
          <ListItem className={"nested"}>
            <ListItemText primary={"Users"} className={"ml-2"} />
          </ListItem>
        </NavLink>
        <NavLink className="is-active sideMenu subNavItem" to="/AdminUnits" onClick={handleDrawerClose}>
          <ListItem className={"nested"}>
            <ListItemText primary={"Units"} className={"ml-2"} />
          </ListItem>
        </NavLink>
        <NavLink className="is-active sideMenu subNavItem" to="/AdminDueDates" onClick={handleDrawerClose}>
          <ListItem className={"nested"}>
            <ListItemText primary={"Due Dates"} className={"ml-2"} />
          </ListItem>
        </NavLink>
        {/* <NavLink className="is-active sideMenu subNavItem" to="/TFGES?year=2027&scoreId=61">
          <ListItem className={"nested"}>
            <ListItemText primary={"TF GES"} className={"ml-2"} />
          </ListItem>
        </NavLink> */}
      </>
    );
  };

  const renderDrawerList = (
    <>
      <div className="navigation-header">
        <GRTMenuLogo className="logoIcon" />
        {isNarrow && (
          <MenuCloseLogo className="closeIcon" onClick={handleDrawerClose} />
        )}
      </div>
      {roleId === 3 && renderCEOList()}

      {roleId === 1 && renderGRTList()}

      {roleId === 2 && renderTFList()}
    </>
  );

  return (
    <>
      {!props.hideDrawer && (
        <>
          {isNarrow ? (
            <>
              <StyledDrawer
                variant="temporary"
                anchor="left"
                open={open}
                onClose={handleDrawerClose}
              >
                {renderDrawerList}
              </StyledDrawer>
              <MenuOpenIcon className="openIcon" onClick={handleToggleDrawer} />
            </>
          ) : (
            <StyledDrawer
              variant="permanent"
              className={clsx("drawer", {
                drawerOpen: open,
                drawerClose: !open,
              })}
            >
              <DrawerPaper>
                <DrawerContainer>{renderDrawerList}</DrawerContainer>
              </DrawerPaper>
            </StyledDrawer>
          )}
        </>
      )}
    </>
  );
};
export default Navigation;
