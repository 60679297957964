import React from 'react'
import TextArea from 'devextreme-react/text-area'

interface Props {
  label?: string
  stylingMode?: string | any
  placeholder?: string | any
  value: string
  showClearButton?: boolean | any
  readOnly?: boolean | any
  disabled?: boolean | any
  onValueChanged: (e: any) => void
  spellcheck?: boolean
  inputRef?: React.RefObject<TextArea>
  className?: any
  onKeyUp?: (e: any) => void
  minHeight?: number
}

const TextAreaInput: React.FC<Props> = ({
  label,
  stylingMode,
  placeholder,
  value,
  showClearButton,
  readOnly,
  disabled,
  onValueChanged,
  spellcheck,
  inputRef,
  className,
  onKeyUp,
  minHeight
}) => {

  React.useEffect(() => {
    if (!disabled && !readOnly) {
      if (
        inputRef &&
        inputRef.current &&
        inputRef.current.instance
      ) {
        inputRef.current.instance.focus();
      }
    }
  }, [inputRef]);

  return (
    <TextArea
      placeholder={placeholder}
      value={value}
      focusStateEnabled={true}
      // stylingMode={stylingMode}
      // height={90}
      minHeight={minHeight || 90}
      maxHeight={120}
      spellcheck={spellcheck}
      onValueChanged={onValueChanged}
      showClearButton={showClearButton}
      labelMode={'floating'}
      label={label}
      readOnly={readOnly}
      disabled={disabled}
      autoResizeEnabled={true}
      ref={inputRef}
      className={className}
      onKeyUp={onKeyUp}
    />
  )
}

export default TextAreaInput
