import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import utility from "../../classes/utility";
import Config from "../../classes/config";
import DataTable from "../Common/ReusableComponents/DataTable";
import Spinner from "../Common/ReusableComponents/Spinner";
import ViewMoreDialog from "../Common/ReusableComponents/ViewMore";

const AdminUnits = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const [items, setItems] = useState<any[]>([]);
  const [showMoreAlert, setShowMoreAlert] = React.useState(false);
  const [moreItem, setMoreItem] = React.useState([] as any);
  const [morealertMessage, setMorealertMessage] = React.useState("");

  const columns = [
    // {
    //   field: "id",
    //   title: "ID",
    //   type: "string",
    //   allowHiding: false,
    // },
    {
      field: "titleWithId",
      title: "Unit / Id",
      type: "html",
      allowHiding: false,
    },
    {
      field: "country",
      title: "Country",
      type: "html",
      allowHiding: false,
    },
    {
      field: "createdBy",
      title: "CreatedBy",
      type: "string",
      allowHiding: true,
    },
    {
      field: "updatedBy",
      title: "UpdatedBy",
      type: "string",
      allowHiding: false,
    },
    {
      field: "indicatorsSelected",
      title: "IndicatorsSelected",
      type: "html",
      allowHiding: false,
    },
  ];

  useEffect(() => {
    didMount();
  }, []);

  const didMount = () => {
    setLoading(true);
    getUnitData();
  };

  const getUnitData = async() => {
    try{
    const data:any = await utility.genericGetAPICall(`${Config.getUnitDetails}`);
        let _items: any = [];
        let result = data.filter((x: any) => x.isDeleted === false);
        for (let r of result) {
          const indicatorHtml = `<Button style="background:${
            r.indicatorsSelected === "Full" ? "#AB6AFF" : "#FC8800"
          }; border: 2px solid ${
            r.indicatorsSelected === "Full" ? "#AB6AFF" : "#FC8800"
          }" class="datatable dummyButtons">${r.indicatorsSelected}</Button>`;
          const countryHtml = `
          <p>${r.unitCountryDetails?.title}</p>
          <p class='subText'>${r.unitCountryDetails?.id}</p>`;
          const unitHtml = `<p>${r.title}</p><p class='subText'>${r.id}</p>`;
          _items.push({
            key: r.id,
            id: r.id,
            titleWithId: unitHtml,
            indicatorsSelected: indicatorHtml,
            country: countryHtml,
            updatedBy: r.updatedBy,
            updatedOn: r.updatedOn,
            createdBy: r.createdBy,
            createdOn: r.createdOn,
          });
        }
        _items = utility._sortItems(_items, "id", false);

        setItems(_items);
        setLoading(false);
      }catch (err) {
        setLoading(false);
      }
  };

  const handleTableActionsEdit = (action: any) => {
    navigate(`/AdminUnitAddEdit?ID=${action.id}`);
  };

  const handleTableActionsDelete = (action: any) => {
    navigate(`/AdminUnitAddEdit?ID=${action}`);
  };

  // const subMenu = [
  //   {
  //     name: "Edit",
  //     link: "/AdminUnitAddEdit?ID=" + moreItem.id,
  //   },
  //   {
  //     name: "Delete",
  //     link: "/AdminUnitAddEdit?ID=" + moreItem.id,
  //   },
  // ];

  // const OnViewMoreClose = () => {
  //   setMorealertMessage("");
  //   setShowMoreAlert(false);
  // };

  // const MoreItemDialog = (item: any) => {
  //   setShowMoreAlert(true);
  //   setMoreItem(item);
  // };

  // const MoreItemPopUp = () => {
  //   return (
  //     <ViewMoreDialog
  //       showDialog={showMoreAlert}
  //       message={morealertMessage}
  //       title={""}
  //       onClose={() => OnViewMoreClose()}
  //       menuItems={subMenu}
  //     />
  //   );
  // };

  return (
    <div className="">
      {/* {showMoreAlert && MoreItemPopUp()} */}
      {!loading ? (
        <div className="adminUser-container">
          <DataTable
            style={{ width: "100%" }}
            filterable={false}
            groupable={false}
            rows={items}
            columns={columns}
            hideSearchPanel={true}
            fileName={"Units"}
            // columnChooser={"UnitColumns"}
            showHideColumnMenu={false}
            hideExport={true}
            // moreItem={(a: any) => MoreItemDialog(a)}
            deleteItem={(a: any) => handleTableActionsDelete(a)}
            editItem={(a: any) => handleTableActionsEdit(a)}
            showCommandBtn={false}
            showMoreBtn={true}
            hideCommandViewBtn={true}
            hideCommandDeleteBtn={true}
          />
        </div>
      ) : (
        <Spinner size="large" />
      )}
    </div>
  );
};

export default AdminUnits;
