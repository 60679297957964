import React from "react";
import { CheckBox } from "devextreme-react/check-box";

interface Props {
  text?: any;
  defaultValue?: string | any;
  value: any;
  disabled?: boolean | any;
  onValueChanged: any;
  className?: string;
}

const CheckBoxInput: React.FC<Props> = (props: Props) => {
  return (
    <CheckBox
      defaultValue={props.defaultValue}
      value={props.value}
      text={props.text}
      onValueChanged={(e: any) => props.onValueChanged(e)}
      disabled={props.disabled}
      className={props.className}
    />
  );
};

export default CheckBoxInput;
