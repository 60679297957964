import React, { useState, useEffect } from "react";
import { TextBox } from "devextreme-react/text-box";

interface Props {
  label?: string;
  stylingMode: string | any;
  placeholder?: string | any;
  mask?: string | any;
  useMaskedValue?: string | any;
  mode?: string | any;
  value: string;
  showClearButton?: boolean | any;
  readOnly?: boolean | any;
  disabled?: boolean | any;
  onValueChanged?: any;
  onKeyDown?: any;
  required?: any;
  spellcheck?: boolean | any;
  inputRef?: React.RefObject<TextBox>;
  focusStateEnabled?: boolean;
  className?: any;
  focused?: boolean;
  onKeyUp?: any;
  multiline?: boolean;
  wrapEnabled?: boolean;
}

const TextBoxInput: React.FC<Props> = (props) => {
  // const [isFocused, setIsFocused] = useState(false);

  useEffect(() => {
    if (props.focused) {
      // setIsFocused(true);
      if (
        props.inputRef &&
        props.inputRef.current &&
        props.inputRef.current.instance
      ) {
        props.inputRef.current.instance.focus();
      }
    }
  }, [props.focused, props.inputRef]);

  return (
    <TextBox
      labelMode="floating"
      label={props.label}
      stylingMode={props.stylingMode}
      placeholder={props.placeholder}
      value={props.value}
      onValueChanged={props.onValueChanged}
      showClearButton={props.showClearButton}
      onEnterKey={props.onKeyDown}
      mask={props.mask}
      useMaskedValue={props.useMaskedValue}
      mode={props.mode}
      readOnly={props.readOnly}
      disabled={props.disabled}
      spellcheck={props.spellcheck}
      ref={props.inputRef}
      focusStateEnabled={props.focusStateEnabled}
      className={props.className}
      // onFocusIn={() => setIsFocused(true)}
      // onFocusOut={() => setIsFocused(false)}
      onKeyUp={props.onKeyUp}
    ></TextBox>
  );
};

export default TextBoxInput;
