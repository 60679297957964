import React, { FC } from 'react';
import LoadIndicator from 'devextreme-react/load-indicator';

interface SpinnerProps {
  size?: string;
  height?: number;
  width?: number;
  label?: string;
}

const Spinner: FC<SpinnerProps> = ({ size = 'medium', height = 40, width = 40, label }) => {
  return (
    <div className='loader'>
      <LoadIndicator id={`${size}-indicator`} height={height} width={width} /><br />
      {label && <span>{label}</span>}
    </div>
  );
};

export default Spinner;
