import React, { useState, useEffect } from "react";
import {
  Chart,
  Series,
  Legend,
  ValueAxis,
  Point,
  Border,
  Grid as ChartGrid,
  CommonPaneSettings,
  Tooltip,
  ArgumentAxis,
  Label,
  Export,
  SeriesTemplate,
  CommonSeriesSettings,
  Margin,
  Title,
} from "devextreme-react/chart";
import "../../css/Dashboard.css";
import utility from "../../classes/utility";
import Config from "../../classes/config";
import Grid from "@mui/material/Grid";

interface Item {
  key: string;
  id: string;
  tenantId: string;
  unitIndicatorId: string;
  score: number;
  year: number;
  status: string;
  statusId: string;
  percentComplete: number;
  updatedOn: Date | null;
  updatedBy: string;
  createdBy: string;
  createdOn: Date | null;
  unitName: string;
  genderLabelResponse: {
    label: string | null;
    value: string | null;
  };
}

interface GRTDashboardProps {
  items: Item[];
}

const CEODashboard: React.FC<GRTDashboardProps> = ({ items }) => {
  const [GRTData, setGRTData] = useState<Item[]>(items);
  const [avgTotalScoreChartData, setAvgTotalScoreChartData] = useState<any[]>(
    []
  );
  const [priorityAreasProgressChartData, setPriorityAreasProgressChartData] =
    useState<any[]>([]);
    const [paProgressChartData, setPaProgressChartData] = useState<any[]>([]);
  const [years, setYears] = useState<string[]>([]);
  const [minYear, setMinYear] = useState<string | null>(null);
  const [maxYear, setMaxYear] = useState<string | null>(null);

  useEffect(() => {
    getAvgTotalScoresData();
    getPriorityAreasProgressData();
  }, []);

  useEffect(() => {
    updateMinMaxYears();
  }, [years]);

  const getAvgTotalScoresData = () => {
    let _items: any = [];
    const filteredData = GRTData.filter(
      (entry: any) => entry.statusId === 1 || entry.status === "Approved"
    );
    //restrict to show last 5 years data only
    filteredData.sort((a, b) => b.year - a.year);
    const plotData = filteredData.slice(0, 5).map((entry: any) => {
      const chartResponse = Config.genderResponseNames.includes(
        entry.chartResponse
      )
        ? entry.chartResponse
        : undefined;
      return {
        year: entry.year,
        chartResponse,
      };
    });
    setAvgTotalScoreChartData(plotData);
  };

  const getPriorityAreasProgressData = () => {
    // Filter the data to include only records from the last 5 years
    const filteredData = GRTData.filter(
      (entry: any) =>
        entry.statusId === 1 && entry.year >= new Date().getFullYear() - 4
    );
    setPaProgressChartData(filteredData);
    const dataByYear = filteredData
      .filter((entry: any) => entry.statusId === 1)
      .reduce((acc, entry) => {
        acc[entry.year] = acc[entry.year] || [];
        acc[entry.year].push(entry);
        return acc;
      }, {});

    const years = Object.keys(dataByYear);
    setYears(years);

    // Prepare series data for each year
    const seriesDataByYear = years.map((year) => {
      const yearData = dataByYear[year];

      // Initialize series data
      const seriesData = Config.priorityAreasListNames.map(
        (priorityArea: any, index: any) => {
          // Find the first priority area item that matches the current priority area
          const priorityAreaItem = yearData.find((item: any) => {
            return item.priorityAreaItems.some(
              (area: any) => area.title === priorityArea
            );
          });

          // Get the priority score chart response if the priority area item is found
          const priorityScoreChartResponse = priorityAreaItem
            ? priorityAreaItem.priorityAreaItems.find(
                (area: any) => area.title === priorityArea
              )?.priorityScoreChartResponse
            : null;

          return {
            priorityAreas: `PRIORITY ${index + 1}: ${priorityArea}`,
            [`year${year}`]: priorityScoreChartResponse,
          };
        }
      );

      return seriesData;
    });

    // Merge seriesDataByYear to get the desired format
    const _priorityAreasProgressChartData = Config.priorityAreasListNames.map(
      (priorityArea: any, index: number) => {
        const priorityAreasData = {
          priorityAreas: priorityArea,
        };

        seriesDataByYear.forEach((seriesData: any) => {
          const yearData = seriesData[index];
          Object.assign(priorityAreasData, yearData);
        });

        return priorityAreasData;
      }
    );
    setPriorityAreasProgressChartData(_priorityAreasProgressChartData);
  };

  const customizeLabelText = (info: any) => {
    const value = info.valueText;
    const maxWidth = 20;
    const regex = new RegExp(
      `(?![^\\n]{1,${maxWidth}}$)([^\\n]{1,${maxWidth}})\\s`,
      "g"
    );
    const wrappedText = value.replace(regex, "$1\n");
    return wrappedText;
  };

  const customizeText = (arg: any) => {
    return `${arg.value}`;
  };

  const customizeLabel = (arg: any) => {
    return {
      visible: true,
      customizeText(e: any) {
        return `${e.valueText}`;
      },
    };
  };

  const customizePriorityAreaChartLabel = (arg: any) => {
    return {
      visible: true,
      customizeText(e: any) {
        return `${e.seriesName}`;
      },
    };
  };

  const updateMinMaxYears = () => {
    if (years.length > 0) {
      setMinYear(Math.min(...years.map(Number)).toString());
      setMaxYear(Math.max(...years.map(Number)).toString());
    }
  };

  return (
    <>
      <h2 className="main-title">Over the years</h2>
      <div className="dashobaord-container">
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <div className="avgTotalScores-container ui-container">
            {avgTotalScoreChartData.length === 0 && <p className="no-content">No data available</p>}
            {avgTotalScoreChartData.length > 0 && (
              <Chart
                id="chart"
                dataSource={avgTotalScoreChartData}
                rotated={true}
                customizeLabel={customizeLabel}
              >
                <CommonSeriesSettings
                  argumentField="year"
                  valueField="chartResponse"
                  type="bar"
                  ignoreEmptyPoints={true}
                  minBarSize={4}
                />
                <Margin bottom={20} />
                <SeriesTemplate nameField="chartResponse" />
                <ArgumentAxis
                  title="year"
                  type="discrete"
                >
                  <Label rotationAngle={20} customizeText={customizeText} />
                </ArgumentAxis>
                <ValueAxis
                  title="Score"
                  categories={Config.genderResponseNames}
                  aggregatedPointsPosition="crossTicks"
                ></ValueAxis>
                <Title
                  horizontalAlignment={"left"}
                  text="Average Total Score over the years"
                  subtitle={items ? items[0].unitName : ""}
                />
                <Legend visible={false} />
                <Export enabled={true} />
              </Chart>
            )}
            </div>
          </Grid>
          <Grid item xs={12}>
            <div className="priorityAreasProgress-container ui-container">
            {paProgressChartData.length === 0 && <p className="no-content">No data available</p>}
            {paProgressChartData.length > 0 && (
              <Chart
                id="chart"
                dataSource={priorityAreasProgressChartData}
                customizeLabel={customizePriorityAreaChartLabel}
              >
                <CommonSeriesSettings type="bar" ignoreEmptyPoints={true} />
                <Margin bottom={20} />
                {years.map((year, index) => (
                  <Series
                    key={index}
                    valueField={`year${year}`}
                    argumentField="priorityAreas"
                    name={year}
                  />
                ))}
                <ArgumentAxis title="priority Areas" />
                <ValueAxis
                  title="score"
                  categories={Config.genderResponseNames}
                >
                  <Label customizeText={customizeLabelText} />
                </ValueAxis>
                <Title
                  horizontalAlignment={"left"}
                  text="Priotity Areas - Progress"
                  subtitle={`${minYear || "N/A"} - ${maxYear || "N/A"}`}
                />
                <Legend visible={false} />
                <Export enabled={true} />
              </Chart>
            )}
            </div>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default CEODashboard;
