import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { NavLink } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import MenuItem from "@mui/material/MenuItem";
import Popover from "@mui/material/Popover";
import Menu from "@mui/material/Menu";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import { Tooltip } from "devextreme-react/tooltip";
import { pca } from "../../App";

import { ReactComponent as Plus } from "../../images/Plus.svg";
import { ReactComponent as InfoIcon } from "../../images/InfoIcon.svg";
import utility from "../../classes/utility";
import config from "../../classes/config";

interface HomeProps {
  roleId: number | null;
  unitId: number | null;
  currentYearRecordExists: boolean;
}

const AppBarPage = (props: HomeProps) => {
  const [roleId, setRoleId] = useState(props.roleId);
  const location = useLocation();
  const isCreateScoreCardVisible =
    (location.pathname === "/" ||
      location.pathname === "/GRTHome" ||
      location.pathname.includes("/GRTScoreCards")) &&
    roleId === 1;

  const isCreateUserVisible =
    location.pathname === "/AdminUsers" && props.roleId === 2;

  const isCreateUnitVisible =
    location.pathname === "/AdminUnits" && props.roleId === 2;

  const isCreateDueDateVisible =
    location.pathname === "/AdminDueDates" && props.roleId === 2;

  const [pageTitle, setPageTitle] = useState<string>("");
  const [pageSubtitle, setPageSubtitle] = useState<string>("");
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [isScrolled, setIsScrolled] = useState(false);
  const [unitName, setUnitName] = useState("");
  const [chosenUnitName, setChosenUnitName] = useState("");
  // const [countryId, setCountryId] = useState("");
  // const [scoreYear, setScoreYear] = useState("");
  const [scoreId, setScoreId] = useState("");
  const [year, setYear] = useState<string | null>(null);
  // const [percentComplete, setPercentComplete] = useState(0);
  // const [totalScoreVal, setTotalScoreVal] = useState(0);
  // const [maxTotalScore, setMaxTotalScore] = useState(0);
  // const [isGRTGESPage, setIsGRTGESPage] = useState(false);
  const [showToolTipIcon, setShowToolTipIcon] = useState(false);
  const [isMobileDevice, setIsMobileDevice] = useState(false);
  const [userDetailsOpen, setUserDetailsOpen] = useState(false);
  const [userInitials, setUserInitials] = useState<string | null>(""); 
  const [userEmailAddress, setUserEmailAddress] = useState<string | null>("");
  const [userFullName, setUserFullName] = useState<string | null>("");

  const isMenuOpen = Boolean(anchorEl);
  const currentYear = new Date().getFullYear();

  useEffect(() => {
    if (window.innerWidth <= 1024) {
      setIsMobileDevice(true);
    }

    const handleScroll = () => {
      if (window.scrollY > 0) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    const storedRoleId = localStorage.getItem("roleId");
    const storedUnitId = localStorage.getItem("unitId");
    const accountInfo = pca.getActiveAccount();
    const userEmail = accountInfo?.username || null;
    const userName = accountInfo?.name || null;

    const getInitials = (name: string) => {
      const words = name.split(" ");
      const initials = words.map((word) => word.charAt(0)).join("");
      return initials.slice(0, 2).toUpperCase(); // Limit to 2 characters and convert to uppercase
    };

    setUserFullName(userName);
    setUserEmailAddress(userEmail);
    const userInitials = userName ? getInitials(userName) : null;
     setUserInitials(userInitials);
     

    
    // Use stored values if available, otherwise, use props
    const roleIdValue =
      storedRoleId !== null ? parseInt(storedRoleId, 10) : props.roleId;
    const unitIdValue =
      storedUnitId !== null ? parseInt(storedUnitId, 10) : props.unitId;

    
    if (roleIdValue !== null && roleIdValue !== 2 && unitIdValue !== null) {
      getUnitName(unitIdValue);
    }
    else if(roleIdValue === 2){
      setUnitName("Admin");
    }
  }, []);

  useEffect(() => {
    // Function to extract the last part of the pathname (after the last '/')
    const extractPageName = (pathname: string) => {
      const parts = pathname.split("/");
      return parts[parts.length - 1];
    };

    const currentPageName = extractPageName(location.pathname);

    // Map page names to corresponding titles and subtitles
    const pageTitleMap: { [key: string]: { title: string; subtitle: string } } =
      {
        "": {
          title: "Home / Dashboard",
          subtitle: `GES Stats for ${unitName}`,
        },
        GRTHome: {
          title: "Home / Dashboard",
          subtitle: `GES Stats for ${unitName}`,
        },
        GRTScoreCards: {
          title: "Scorecards",
          subtitle: `GES Over The Years For ${unitName}`,
        },
        GRTReports: { title: "Reports", subtitle: "All ScoreCards" },
        GRTGES: {
          title: "Gender Equality Scorecard",
          subtitle: `${scoreId} | ${unitName} | ${year}`,
        },
        GRTConfirmation: {
          title: "Gender Equality Scorecard",
          subtitle: `${scoreId} | ${unitName} | ${year}`,
        },
        // TF
        TFHome: {
          title: "Home / Dashboard",
          subtitle: `GES Stats for All Units`,
        },
        TFScoreCards: {
          title: "Scorecards",
          subtitle: "GES Over The Years For All Units",
        },
        TFReports: { title: "Reports", subtitle: "All ScoreCards" },
        TFGES: {
          title: "Gender Equality Scorecard",
          subtitle: `${scoreId} | ${chosenUnitName} | ${year}`,
        },
        TFConfirmation: {
          title: "Gender Equality Scorecard",
          subtitle: `${scoreId} | ${chosenUnitName} | ${year}`,
        },
        AdminUsers: { title: "Users", subtitle: "Details" },
        AdminUserAddEdit: {
          title: "Add/Edit Users",
          subtitle: "User Controls",
        },
        AdminUnits: { title: "Unit Indicators", subtitle: "Details" },
        AdminUnitAddEdit: {
          title: "Add/Edit Unit Indicators",
          subtitle: "Category Controls",
        },
        AdminDueDates: { title: "Due Dates", subtitle: "Details" },
        AdminDueDateAddEdit: {
          title: "Add/Edit Due Dates",
          subtitle: "Due Date Controls",
        },
        //CEO
        CEOHome: {
          title: "Home / Dashboard",
          subtitle: `GES Stats for ${unitName}`,
        },
        CEOScoreCards: {
          title: "Scorecards",
          subtitle: `GES Over The Years For ${unitName}`,
        },
        CEOReports: { title: "Reports", subtitle: "All ScoreCards" },
        CEOGES: {
          title: "Gender Equality Scorecard",
          subtitle: `${scoreId} | ${unitName} | ${year}`,
        },
        CEOConfirmation: {
          title: "Gender Equality Scorecard",
          subtitle: `${scoreId} | ${unitName} | ${year}`,
        },
      };

    // Set the page title and subtitle based on the mapping or use default values
    const { title, subtitle } = pageTitleMap[currentPageName] || {
      title: "",
      subtitle: "",
    };
    setPageTitle(title);
    setPageSubtitle(subtitle);

    if (currentPageName === "GRTGES" || currentPageName === "TFGES") {
      // setIsGRTGESPage(true);
      const search = location.search;
      const params = new URLSearchParams(search);
      // const searchYear = params.get("year");
      const searchScoreId = params.get("scoreId");
      const searchYear = params.get("year");
      const searchUnit = params.get("unit");

      if (searchScoreId && searchYear) {
        setScoreId(searchScoreId);
        setYear(searchYear);
        let dynamicSubtitle = "";
        if (searchUnit) {
          setChosenUnitName(searchUnit);
          dynamicSubtitle = `${searchScoreId} | ${searchUnit} | ${searchYear}`;
        } else {
          dynamicSubtitle = `${searchScoreId} | ${unitName} | ${searchYear}`;
        }
        setPageSubtitle(dynamicSubtitle);
      }
    }

    if (currentPageName === "GRTGES") {
      setShowToolTipIcon(true);
    } else {
      setShowToolTipIcon(false);
    }
  }, [location.pathname, location.search, unitName]);

  // const getScoresData = async (year: any, scoreId: any) => {
  //   let dataToPassToService: any = {};
  //   dataToPassToService.unitId = props.unitId;
  //   dataToPassToService.scoreId = scoreId ? +scoreId : 0;
  //   dataToPassToService.yearFrom = +year;
  //   dataToPassToService.yearTo = +year;

  //   try {
  //     const data = (await utility.genericPostAPIcall(
  //       `${config.searchScores}?showAll=true&pageSize=-1&pageNumber=-1`,
  //       dataToPassToService
  //     )) as any[];

  //     if (data) {
  //       const activeRecords: any[] = data.filter(
  //         (item: any) => item.isDeleted === false
  //       );
  //       let _items: any = [];
  //       if (activeRecords && activeRecords.length > 0) {
  //         _items = activeRecords[0];
  //         setPercentComplete(_items.percentComplete);
  //         setTotalScoreVal(_items.totalScore);
  //         setMaxTotalScore(_items.maxTotalScore);
  //         setScoreId(_items.id);
  //       }
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  const getUnitName = async(unitIdVal: any) => {
    try{
    const data: any = await utility.genericGetDataByIdAPICall(`${config.getUnits}`, unitIdVal);
        if (data) {
          const unitName = data.title;
          setUnitName(unitName);
        }
      } catch (error) {
        console.log(error);
      }
  };

  const handleUserDetailsOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setUserDetailsOpen(true);
  };

  const handleUserDetailsClose = () => {
    setAnchorEl(null);
    setUserDetailsOpen(false);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const logOut = () => {
    const logoutRequest = {
      account: pca.getActiveAccount(),
      postLogoutRedirectUri: "/",
    };
    pca.logoutRedirect(logoutRequest);
  };

  const renderTooltipContent = () => {
    return (
      <div className="tooltip-content-container">
        <p>
          Please select your responses. 0 being the lowest and 4 being the
          highest.
        </p>
        <p className="highlight">
          Your responses are recorded once the circles for each question are
          highlighted in black.
        </p>
        <p>
          You can change your responses anytime during the process or save it
          for later too.
        </p>
      </div>
    );
  };

  const menuId = "primary-search-account-menu";
  const renderMenu = (
    <Popover
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      {/* <NavLink className="is-active" to="/Profile">
        <MenuItem onClick={handleMenuClose}>Profile</MenuItem>
      </NavLink>

       */}
       <p> {userFullName}</p>
      <p> {userEmailAddress}</p>
      <p> {unitName}</p>
      <MenuItem onClick={logOut}>Logout</MenuItem>

      {isCreateScoreCardVisible && (
        <div
          className={`button-container${
            !props.currentYearRecordExists ? "" : " disabled"
          }`}
        >
          <Plus />{" "}
          <NavLink
            to={`/GRTGES?year=${currentYear}`}
            className="CreateSCButton"
          >
            Create ScoreCard
          </NavLink>
        </div>
      )}
      {isCreateUserVisible && (
        <div className="button-container">
          <Plus /> <NavLink to="/AdminUserAddEdit">Create User</NavLink>
        </div>
      )}
      {isCreateUnitVisible && (
        <div className="button-container">
          <Plus /> <NavLink to="/AdminUnitAddEdit?ID=0">Create Unit</NavLink>
        </div>
      )}
      {isCreateDueDateVisible && (
        <div className="button-container">
          <Plus />{" "}
          <NavLink to="/AdminDueDateAddEdit?ID=0">Create Due Date</NavLink>
        </div>
      )}
    </Popover>
  );

  // const mobileMenuId = "primary-search-account-menu-mobile";

  return (
    <>
      <AppBar
        position="fixed"
        className={`${isScrolled ? "scrolledAppBar" : ""}`}
      >
        <Toolbar>
          <Grid container>
            <Grid item xs={6} sm={6} md={6}>
              <div className="appBarTitle">
                <h2>{pageTitle}</h2>
                <p>{pageSubtitle}</p>
              </div>
            </Grid>
            <Grid item xs={6} sm={6} md={6}>
              <>
                <div className={"sectionDesktop"}>
                  {isCreateScoreCardVisible && (
                    <div
                      className={`button-container${
                        !props.currentYearRecordExists ? "" : " disabled"
                      }`}
                    >
                      <NavLink
                        to={`/GRTGES?year=${currentYear}`}
                        className="CreateSCButton"
                      >
                        <Plus />
                        Create ScoreCard
                      </NavLink>
                    </div>
                  )}
                  {isCreateUserVisible && (
                    <div className="button-container">
                      <Plus />{" "}
                      <NavLink to="/AdminUserAddEdit?ID=0">Create User</NavLink>
                    </div>
                  )}
                  {isCreateUnitVisible && (
                    <div className="button-container">
                      <Plus />{" "}
                      <NavLink to="/AdminUnitAddEdit?ID=0">Create Unit</NavLink>
                    </div>
                  )}
                  {isCreateDueDateVisible && (
                    <div className="button-container">
                      <Plus />{" "}
                      <NavLink to="/AdminDueDateAddEdit?ID=0">
                        Create Due Date
                      </NavLink>
                    </div>
                  )}
                  {showToolTipIcon && (
                    <div>
                      <InfoIcon className="info-icon-tooltip" />
                      <Tooltip
                        target=".info-icon-tooltip"
                        showEvent={isMobileDevice ? "dxclick" : "dxhoverstart"}
                        hideEvent="dxhoverend"
                        hideOnOutsideClick={isMobileDevice ? true : false}
                        contentRender={renderTooltipContent}
                      ></Tooltip>
                    </div>
                  )}
            <div className="userInfo">
              <Button
                  onClick={handleUserDetailsOpen}
                  aria-controls="user-details-menu"
                  aria-haspopup="true"
                  color="inherit"
                >
                  {userInitials}
              </Button>
            </div>
                </div>
              </>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <Popover
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <p>{userFullName}</p>
      <p>{userEmailAddress}</p>
      <p>{unitName}</p>
      <MenuItem onClick={logOut}>Logout</MenuItem>
    </Popover>
    </>
  );
};

export default AppBarPage;
