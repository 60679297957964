import React, { useState } from "react";
import { HashRouter, Routes, Route, Navigate } from "react-router-dom";
import Navigation from "./components/Navigation/Navigation";
import AppBar from "./components/Navigation/AppBar";
import "devextreme/dist/css/dx.light.css";
import AdminDueDates from "./components/Admin/AdminDueDates";
import AdminDueDateAddEdit from "./components/Admin/AdminDueDateAddEdit";
import AdminUsers from "./components/Admin/AdminUsers";
import AdminUnits from "./components/Admin/AdminUnits";
import AdminUserAddEdit from "./components/Admin/AdminUserAddEdit";
import GRTHome from "./components/GRT/GRTHome";
import TFHome from "./components/TF/TFHome";
import GRTScoreCards from "./components/GRT/GRTScoreCards";
import TFScoreCards from "./components/TF/TFScoreCards";
import GRTReports from "./components/GRT/GRTReports";
import TFReports from "./components/TF/TFReports";
import GRTGES from "./components/GRT/GRTGES";
import TFGES from "./components/TF/TFGES";
import CEOHome from "./components/CEO/CEOHome";
import CEOScoreCards from "./components/CEO/CEOScoreCards";
import CEOReports from "./components/CEO/CEOReports";
import CEOGES from "./components/CEO/CEOGES";
import CEOConfirmation from "./components/CEO/CEOConfirmation";
import AdminUnitAddEdit from "./components/Admin/AdminUnitAddEdit";
import GRTConfirmation from "./components/GRT/GRTConfirmation";
import TFConfirmation from "./components/TF/TFConfirmation";
import NotFound from "./components/NotFound/NotFound";

import GRTFooterLogo from "./images/aten_final_1000.png";

interface HomeProps {
  roleId: number | null;
  unitId: number | null;
  currentYearRecordExists: boolean;
}

const Home: React.FC<HomeProps> = ({
  roleId,
  unitId,
  currentYearRecordExists,
}) => {
  const homeProps = { roleId, unitId, currentYearRecordExists };

  // const [isGRTConfirmationPage, setIsGRTConfirmationPage] = useState(false);

  return (
    <HashRouter>
      <div className="page-layout">
        <div className="layout-navigation">
          <Navigation hideDrawer={false} {...homeProps} />
        </div>
        <div className="layout-content-wrapper">
          <AppBar {...homeProps} />
          <main className={`layout-page-content`}>
          {roleId !== null && (
            <Routes>
              {roleId === 1 && (
                <>
                  <Route
                    path="/"
                    element={<Navigate to="/GRTHome" replace />}
                  />
                  <Route path="/GRTHome" element={<GRTHome {...homeProps} />} />
                  <Route
                    path="/GRTScoreCards"
                    element={<GRTScoreCards {...homeProps} />}
                  />
                  <Route
                    path="/GRTReports"
                    element={<GRTReports {...homeProps} />}
                  />
                  <Route path="/GRTGES" element={<GRTGES {...homeProps} />} />
                  <Route
                    path="/GRTConfirmation"
                    element={<GRTConfirmation />}
                  />
                </>
              )}
              {roleId === 2 && (
                <>
                  <Route path="/" element={<Navigate to="/TFHome" replace />} />
                  <Route path="/TFHome" element={<TFHome {...homeProps} />} />
                  <Route
                    path="/TFScoreCards"
                    element={<TFScoreCards {...homeProps} />}
                  />
                  <Route
                    path="/TFReports"
                    element={<TFReports {...homeProps} />}
                  />
                  <Route path="/TFGES" element={<TFGES {...homeProps} />} />
                  <Route path="/TFConfirmation" element={<TFConfirmation />} />
                  <Route path="/AdminUsers" element={<AdminUsers />} />
                  <Route
                    path="/AdminUserAddEdit"
                    element={<AdminUserAddEdit />}
                  />
                  <Route path="/AdminUnits" element={<AdminUnits />} />
                  <Route
                    path="/AdminUnitAddEdit"
                    element={<AdminUnitAddEdit />}
                  />
                  <Route path="/AdminDueDates" element={<AdminDueDates />} />
                  <Route
                    path="/AdminDueDateAddEdit"
                    element={<AdminDueDateAddEdit />}
                  />
                </>
              )}
              {roleId === 3 && (
                <>
                  <Route
                    path="/"
                    element={<Navigate to="/CEOHome" replace />}
                  />
                  <Route path="/CEOHome" element={<CEOHome {...homeProps} />} />
                  <Route
                    path="/CEOScoreCards"
                    element={<CEOScoreCards {...homeProps} />}
                  />
                  <Route
                    path="/CEOReports"
                    element={<CEOReports {...homeProps} />}
                  />
                  <Route path="/CEOGES" element={<CEOGES {...homeProps} />} />
                  <Route
                    path="/CEOConfirmation"
                    element={<CEOConfirmation />}
                  />
                </>
              )}
              <Route path="*" element={<NotFound />} />
            </Routes>
          )}
           {roleId === null && <NotFound />} 
            <>
              <div className="footer">
                <img
                  alt={"Aten Systems Pty Ltd Logo"}
                  className="footer-logo"
                  src={GRTFooterLogo}
                />
                <div>Version 1.0.0</div>
              </div>
            </>
          </main>
        </div>
      </div>
    </HashRouter>
  );
};

export default Home;
