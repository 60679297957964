import React from 'react';
import { CircularProgress, styled } from '@mui/material';

interface CircularProgressInputProps {
  value: number | string;
  circleColor: string;
  progressColor: string;
  size: string;
}

const CircleContainer = styled('div')(({ size }: { size: string | undefined }) => ({
  position: 'relative',
  width: size ? `${size}px` : '54px',
  height: size ? `${size}px` : '54px'
}));

const Circle = styled(CircularProgress)({
  position: 'absolute',
  top: 0,
  left: 0,
});

const Progress = styled(CircularProgress)({
  position: 'absolute',
  top: 0,
  left: 0,
});

const Text = styled('div')({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  fontSize: '12px',
});

const CircularProgressInput: React.FC<CircularProgressInputProps> = ({ value, circleColor, progressColor, size }) => {
  const numericValue = typeof value === 'string' ? parseFloat(value.replace(/%/g, '')) : value;

  return (
    <div>
      <CircleContainer size={size}>
        <Circle
          variant="determinate"
          value={100}
          size={size ? +size : 54}
          thickness={5}
          style={{ color: circleColor }}
        />
      

       <Progress
          variant="determinate"
          value={numericValue}
          size={size ? parseInt(size, 10) : 54}
          thickness={5}
          style={{ color: progressColor }}
          />

        <Text>{value}</Text>
      </CircleContainer>
    </div>
  );
};

export default CircularProgressInput;
