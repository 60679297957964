import React, { useState, useEffect } from "react";
import utility from "../../classes/utility";
import config from "../../classes/config";
import GRTDashboard from "./GRTDashboard";

import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import CircularProgressInput from "../Common/ReusableComponents/CircularProgressInput";
import { ReactComponent as VerticalDottedLine } from "../../images/VerticalDottedLine.svg";
import Spinner from "../Common/ReusableComponents/Spinner";

interface HomeProps {
  roleId: number | null;
  unitId: number | null;
  currentYearRecordExists: boolean;
}

const GRTHome = (props: HomeProps) => {
  const [items, setItems] = useState<any>([]);
  const [warningMessage, setWarningMessage] = useState<JSX.Element | string>(
    ""
  );
  const [showWarning, setShowWarning] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  // const [callAfterInit, setCallAfterInit] = useState<boolean>(false);
  const [statusItems, setStatusItems] = useState<any>([]);
  const [dataLoading, setDataLoading] = useState<boolean>(false);

  useEffect(() => {
    const getStatusData = async () => {
      try {
        const data: any = await utility.genericGetAPICall(
          `${config.statusList}?showAll=true&pageSize=-1&pageNumber=-1`
        );
        let _items: any = [];
        if (data || data.length > 0) {
          data.forEach((r: any) => {
            _items.push({
              key: r.id,
              id: r.id,
              color: r.color,
              title: r.title,
            });
          });
          _items = utility._sortItems(_items, "id", true);
          setStatusItems(_items);
          setLoading(false);
        }
      } catch (err: any) {
        console.log(err);
        setLoading(false);
      }
    };

    const getItemsData = async () => {
      let dataToPassToService: any = {};
      dataToPassToService.unitId = props.unitId;
      try {
        const data: any = await utility.genericPostAPIcall(
          `${config.searchScores}?showAll=true&pageSize=-1&pageNumber=-1`,
          dataToPassToService
        );
        const activeRecords = data?.filter((r: any) => r.isDeleted === false);
        let _items: any = [];
        if (!activeRecords || activeRecords.length === 0) {
          if (_items.length <= 0) {
            setWarningMessage("No Scorecards found");
            setShowWarning(true);
          }
          setItems(_items);
        } else {
          activeRecords.forEach((r: any) => {
            let _scorePriorityItems: any = [];
            if (
              r.scorePriorityAreasList &&
              r.scorePriorityAreasList.length > 0
            ) {
              r.scorePriorityAreasList.forEach((s: any) => {
                _scorePriorityItems.push({
                  id: s.id,
                  title: s.title,
                  genderLabelResponse: {
                    label: s.genderLabelResponse?.label,
                    value: s.genderLabelResponse?.value,
                  },
                  priorityScoreChartResponse: `${
                    s.genderLabelResponse?.label || "TBD"
                  } [${
                    s.genderLabelResponse?.value !== null &&
                    s.genderLabelResponse?.value !== undefined
                      ? s.genderLabelResponse?.value
                      : "-"
                  }]`,
                });
              });
              _scorePriorityItems = utility._sortItems(
                _scorePriorityItems,
                "id",
                false
              );
            }

            _items.push({
              id: r.id,
              tenantId: r.tenantId,
              unitIndicatorId: r.unitId,
              score: r.totalScore,
              year: r.year,
              status: r.status,
              statusId: r.statusId,
              percentComplete: r.percentComplete,
              updatedOn: r.updatedOn ? r.updatedOn : null,
              updatedBy: r.updatedBy,
              createdBy: r.createdBy,
              createdOn: r.createdOn ? r.createdOn : null,
              unitName: r.unitName,
              genderLabelResponse: {
                label: r.genderLabelResponse?.label,
                value: r.genderLabelResponse?.value,
              },
              chartResponse: `${r.genderLabelResponse?.label || "TBD"} [${
                r.genderLabelResponse?.value !== null &&
                r.genderLabelResponse?.value !== undefined
                  ? r.genderLabelResponse?.value
                  : "-"
              }]`,
              priorityAreaItems: _scorePriorityItems,
            });
          });
        }
        _items = utility._sortItems(_items, "id", true);
        setItems(_items);
        // setCallAfterInit(true);
        setDataLoading(false);
        setLoading(false);
        //setPreItems(_items);
      } catch (err: any) {
        console.log(err);
        setDataLoading(false);
        setLoading(false);
      }
    };

    const fetchData = () => {
      try {
        getStatusData();
        setDataLoading(true);
        getItemsData();
      } catch (error) {
        console.error("Error fetching data:", error);
        setDataLoading(false);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  // <GRTScoreCards
  //   items={items}
  //   loading={loading}
  //   callAfterInit={callAfterInit}
  //   getItemsData={getItemsData}
  // />;

  const displayWarning = () => {
    return (
      <div className="no-results">
        <h2 className="main-title">{warningMessage}</h2>
      </div>
    );
  };

  return (
    <>
      {showWarning && displayWarning()}
      {!loading ? (
        <>
          {dataLoading ? (
            <div className="loader-container">
              <Spinner size="large" label="Loading Scorecards..." />
            </div>
          ) : (
            <>
              {items && items.length > 0 && (
                <div className="score-heading">
                  <h1 className="main-heading">
                    Drafts/Previous Scores [{items ? items.length : 0}]
                  </h1>
                  <span className="view-all-scorecards p-20">
                    {items?.length > 2 ? (
                      <a href="#/GRTScoreCards">See All</a>
                    ) : (
                      ""
                    )}
                  </span>
                </div>
              )}
              {items &&
                items.length > 0 &&
                items.slice(0, 3).map((item: any, index: any) => (
                  <div className="ui-container score-card" key={index}>
                    <Grid
                      container
                      spacing={3}
                      key={index}
                      className="pl-10 pr-10"
                    >
                      <Grid item xs={12} sm={8}>
                        <span className="header pr-10">
                          Year / ScoreCard ID:{" "}
                        </span>
                        <span className="header-values pr-20">
                          <a
                            href={`#/GRTGES?year=${item.year}&scoreId=${item.id}`}
                            className="score-item-year"
                          >
                            {item.year}
                          </a>
                          <span className="solidVerticalDivider"> | </span>
                          {item.id}
                          <VerticalDottedLine className="vetlicalLineIcon" />
                        </span>

                        <span className="header pr-10">Progress Made: </span>
                        <span className="header-values">
                          <CircularProgressInput
                            value={`${item.percentComplete}%`}
                            circleColor="#D9E1E1"
                            progressColor="#8ED914"
                            size="54"
                          />
                        </span>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={4}
                        className="align-right status-button"
                      >
                        <span className="header pr-10">Status: </span>
                        <span className="header-values status-buttons">
                          <Button
                            variant="contained"
                            style={{
                              backgroundColor:
                                statusItems && statusItems.length > 0
                                  ? statusItems.filter(
                                      (s: any) => s.id === item.statusId
                                    )[0]?.color || "blue"
                                  : "primary",
                            }}
                            className={"dummyButtons"}
                          >
                            {item.status}
                          </Button>
                        </span>
                      </Grid>
                    </Grid>
                  </div>
                ))}
              {/* Dashboard for GRT */}
              {items && items.length > 0 && <GRTDashboard items={items} />}
            </>
          )}
        </>
      ) : (
        <div className="loader-container">
          <Spinner
            size="large"
            label="Please wait. Loading all components..."
          />
        </div>
      )}
    </>
  );
};

export default GRTHome;
