interface GlobalProps {
  homeURL: string;
  spAPIURL: string;
  AuthAPIURL: string;
  accessToken: string;
  spaToken: string;
  // AzureClientAppId: string;
  // AzureScope: string;
  // ClientSecret: string
  akf_Admin: string;
  akf_GRT: string;
  akf_TF: string;
  // singInB2C: string;
  // singInB2CKnownAuthority: string;
  // AzureTenantId: string;
  currentUser: string;
  AzureADClientId: string;
  AzureADScope: string;
  //ClientSecretAppReg: string;
  AzureADTenantId: string;
  DraftStatusId: number;
}

const Global: GlobalProps = {
  //Aten UAT
  homeURL: "https://akfge.worx.online",
  spAPIURL: "https://akfge.worx.online/api",

  //Local
  // homeURL: "http://localhost:3000/",
  //spAPIURL: "https://localhost:44342/api",

  //Aten AD App Registration
  AuthAPIURL:
    "https://login.microsoftonline.com/4697d2f3-d3b7-4bd0-8bc6-e243f4f71d7c",
  AzureADClientId: "6110beb0-bf5b-43b6-9011-1a57fa46acda",
  AzureADScope: "api://4da0464d-6e45-4a14-9a63-a294fba85c9b/access_as_user",
  AzureADTenantId: "4697d2f3-d3b7-4bd0-8bc6-e243f4f71d7c",

  // //Aten AD B2C
  // singInB2C:
  //   "https://w0rx.b2clogin.com/w0rx.onmicrosoft.com/B2C_1_AKF_GE_SPA_SignUpSignin",
  // singInB2CKnownAuthority: "w0rx.b2clogin.com",
  // AzureClientAppId: "2fa32808-b318-4b64-8bc7-2f31e541274b",
  // AzureTenantId: "777c851c-c2df-4f6c-b1ad-0ba6f8a4059e",
  // AzureScope: "https://w0rx.onmicrosoft.com/akf-spa/.default",
  // // accessToken: "msal.token.keys.2fa32808-b318-4b64-8bc7-2f31e541274b",

  accessToken: "akf-ge-access-token",
  spaToken: "akf-spa-token",
  akf_Admin: "Admin",
  akf_GRT: "GRT",
  akf_TF: "TF",
  currentUser: "",
  DraftStatusId: 2,
};

export default Global;
