import React, { useState, useEffect } from "react";
import utility from "../../classes/utility";
import config from "../../classes/config";
import Spinner from "../Common/ReusableComponents/Spinner";
import CEODashboard from "./CEODashboard";

import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import CircularProgressInput from "../Common/ReusableComponents/CircularProgressInput";
import Divider from "@mui/material/Divider";
import { ReactComponent as VerticalDottedLine } from "../../images/VerticalDottedLine.svg";

interface HomeProps {
  roleId: number | null;
  unitId: number | null;
  currentYearRecordExists: boolean;
}

const CEOHome = (props: HomeProps) => {
  const [items, setItems] = useState<any>([]);
  const [alertMessage, setAlertMessage] = useState<JSX.Element | string>("");
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [dataLoading, setDataLoading] = useState<boolean>(false);
  const [callAfterInit, setCallAfterInit] = useState<boolean>(false);
  const [statusItems, setStatusItems] = useState<any>([]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      await getStatusData();
      setDataLoading(true);
      await getItemsData();
    } catch (error) {
      console.error("Error fetching data:", error);
      setDataLoading(false);
      setLoading(false);
    }
  };

  const getStatusData = async () => {
    try {
      const data: any = await utility.genericGetAPICall(
        `${config.statusList}?showAll=true&pageSize=-1&pageNumber=-1`
      );
      let _items: any = [];
      if (data || data.length > 0) {
        data.forEach((r: any) => {
          _items.push({
            key: r.id,
            id: r.id,
            color: r.color,
            title: r.title,
          });
        });
        _items = utility._sortItems(_items, "id", true);
        setStatusItems(_items);
        setLoading(false);
      }
    } catch (err: any) {
      console.log(err);
      setLoading(false);
    }
  };

  const getItemsData = async () => {
    let dataToPassToService: any = {};
    dataToPassToService.unitId = props.unitId;
    try {
      const data: any = await utility.genericPostAPIcall(
        `${config.searchScores}?showAll=true&pageSize=-1&pageNumber=-1`,
        dataToPassToService
      );
      const activeRecords = data?.filter((r: any) => r.isDeleted === false);
      let _items: any = [];
      if (!activeRecords || activeRecords.length === 0) {
        if (_items.length <= 0) {
          setAlertMessage("No results found for this criteria");
          setShowAlert(true);
        }
        setItems(_items);
      } else {
        activeRecords.forEach((r: any) => {
          let _scorePriorityItems: any = [];
          if (r.scorePriorityAreasList && r.scorePriorityAreasList.length > 0) {
            r.scorePriorityAreasList.forEach((s: any) => {
              _scorePriorityItems.push({
                id: s.id,
                title: s.title,
                genderLabelResponse: {
                  label: s.genderLabelResponse?.label,
                  value: s.genderLabelResponse?.value,
                },
                priorityScoreChartResponse: `${
                  s.genderLabelResponse?.label || "TBD"
                } [${
                  s.genderLabelResponse?.value !== null &&
                  s.genderLabelResponse?.value !== undefined
                    ? s.genderLabelResponse?.value
                    : "-"
                }]`,
              });
            });
            _scorePriorityItems = utility._sortItems(
              _scorePriorityItems,
              "id",
              false
            );
          }
          _items.push({
            key: r.id,
            id: r.id,
            tenantId: r.tenantId,
            unitIndicatorId: r.unitId,
            score: r.totalScore,
            year: r.year,
            status: r.status,
            statusId: r.statusId,
            percentComplete: r.percentComplete,
            updatedOn: r.updatedOn ? r.updatedOn : null,
            updatedBy: r.updatedBy,
            createdBy: r.createdBy,
            createdOn: r.createdOn ? r.createdOn : null,
            unitName: r.unitName,
            submitDaysLeft: r.submitDaysLeft,
            genderLabelResponse: {
              label: r.genderLabelResponse?.label,
              value: r.genderLabelResponse?.value,
            },
            chartResponse: `${r.genderLabelResponse?.label || "TBD"} [${
              r.genderLabelResponse?.value !== null &&
              r.genderLabelResponse?.value !== undefined
                ? r.genderLabelResponse?.value
                : "-"
            }]`,
            priorityAreaItems: _scorePriorityItems,
          });
        });
      }
      _items = utility._sortItems(_items, "id", true);
      setItems(_items);
      setCallAfterInit(true);
      setDataLoading(false);
      setLoading(false);
      //setPreItems(_items);
    } catch (err: any) {
      console.log(err);
      setDataLoading(false);
      setLoading(false);
    }
  };

  return (
    <>
      {!loading ? (
        <>
          <div className="score-heading">
            <h1 className="main-heading">
              Pending For Review [{items ? items.length : 0}]
            </h1>
            <span className="view-all-scorecards p-20">
              {items?.length > 2 ? <a href="#/CEOScoreCards">See All</a> : ""}
            </span>
          </div>
          {dataLoading ? (
            <div className="loader-container">
              <Spinner size="large" label="Loading Scorecards..." />
            </div>
          ) : (
            <>
              {items &&
                items.length > 0 &&
                items.slice(0, 3).map((item: any, index: any) => (
                  <div className="ui-container score-card" key={index}>
                    <Grid
                      container
                      spacing={3}
                      key={index}
                      className="pl-10 pr-10"
                    >
                      <Grid item xs={12} sm={9}>
                        <span className="header pr-10">
                          Year / ScoreCard ID:{" "}
                        </span>
                        <span className="header-values pr-10">
                          <a
                            href={`#/CEOGES?year=${item.year}&scoreId=${item.id}`}
                            className="score-item-year"
                          >
                            {item.year}
                          </a>
                          <span className="solidVerticalDivider"> | </span>
                          <span className="score-item-id">{item.id}</span>
                          <VerticalDottedLine className="vetlicalLineIcon" />
                        </span>

                        {item.statusId === 2 && (
                          <>
                            <span className="header pr-10">
                              {" "}
                              Days left to Submit:{" "}
                            </span>
                            <span className="header-values days pr-10">
                              {" "}
                              {item.submitDaysLeft}
                            </span>
                          </>
                        )}
                        {item.statusId !== 2 && (
                          <>
                            <span className="header pr-10">
                              {" "}
                              Score Obtained:{" "}
                            </span>
                            <span className="header-values score pr-20">
                              {`${
                                item.genderLabelResponse?.label
                                  ? item.genderLabelResponse.label
                                  : ""
                              } [${
                                item.genderLabelResponse?.value
                                  ? item.genderLabelResponse.value
                                  : ""
                              }]`}
                            </span>
                          </>
                        )}
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={3}
                        className="align-right status-button"
                      >
                        <span className="header pr-10">Status: </span>
                        <span className="header-values status-buttons">
                          <Button
                            variant="contained"
                            style={{
                              backgroundColor:
                                statusItems && statusItems.length > 0
                                  ? statusItems.filter(
                                      (s: any) => s.id === item.statusId
                                    )[0]?.color || "blue"
                                  : "primary",
                            }}
                            className={"dummyButtons"}
                          >
                            {item.status}
                          </Button>
                        </span>
                      </Grid>
                    </Grid>
                  </div>
                ))}
              {/* Dashboard for CEO */}
              {items && items.length > 0 && <CEODashboard items={items} />}
            </>
          )}
        </>
      ) : (
        <div className="loader-container">
          <Spinner
            size="large"
            label="Please wait. Loading all components..."
          />
        </div>
      )}
    </>
  );
};

export default CEOHome;
