import React, { useState, useEffect, useRef } from "react";
import utility from "../../classes/utility";
import config from "../../classes/config";
import Spinner from "../Common/ReusableComponents/Spinner";

import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import { ReactComponent as HorizontalDottedLine } from "../../images/HorizontalDottedLine.svg";

interface HomeProps {
  roleId: number | null;
  unitId: number | null;
  currentYearRecordExists: boolean;
}

interface CEOScoreCardsProps {
  items?: any[];
  loading?: boolean;
  callAfterInit?: boolean;
  getItemsData?: () => void;
}

const CEOScoreCards = (props: HomeProps) => {
  const [scoreItems, setScoreItems] = useState<any[]>([]);
  const [alertMessage, setAlertMessage] = useState<JSX.Element | string>("");
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [callAfterInit, setCallAfterInit] = useState<boolean>(false);
  const [statusItems, setStatusItems] = useState<any[]>([]);

  useEffect(() => {
    didMount();
  }, []);

  const didMount = () => {
    getStatusData();
    getItemsData();
  };

  const getStatusData = async () => {
    try {
      const data: any = await utility.genericGetAPICall(
        `${config.statusList}?showAll=true&pageSize=-1&pageNumber=-1`
      );
      let _items: any = [];
      if (data || data.length > 0) {
        data.forEach((r: any) => {
          _items.push({
            key: r.id,
            id: r.id,
            color: r.color,
            title: r.title,
          });
        });
        _items = utility._sortItems(_items, "id", true);
        setStatusItems(_items);
      }
    } catch (err: any) {
      console.log(err);
      setLoading(false);
    }
  };

  const getItemsData = async () => {
    let dataToPassToService: any = {};
    dataToPassToService.unitId = props.unitId;
    try {
      const data: any = await utility.genericPostAPIcall(
        `${config.searchScores}?showAll=true&pageSize=-1&pageNumber=-1`,
        dataToPassToService
      );
      let _items: any = [];
      const activeRecords = data?.filter((r: any) => r.isDeleted === false);
      if (!activeRecords || activeRecords.length === 0) {
        if (activeRecords.length <= 0) {
          setAlertMessage("No results found for this criteria");
          setShowAlert(true);
        }
        setScoreItems(_items);
      } else {
        activeRecords.forEach((r: any) => {
          _items.push({
            id: r.id,
            tenantId: r.tenantId,
            unitIndicatorId: r.unitId,
            score: r.score,
            year: r.year,
            status: r.status,
            statusId: r.statusId,
            percentComplete: r.percentComplete,
            priorityAreas: r.scorePriorityAreasList,
            totalScore: r.totalScore,
            maxTotalScore: r.maxTotalScore,
            unitName: r.unitName,
            submitDaysLeft: r.submitDaysLeft,
            genderLabelResponse: {
              label: r.genderLabelResponse?.label,
              value: r.genderLabelResponse?.value,
            },
            updatedOn: r.updatedOn ? r.updatedOn : null,
            updatedBy: r.updatedBy,
            createdBy: r.createdBy,
            createdOn: r.createdOn ? r.createdOn : null,
          });
        });
      }
      _items = utility._sortItems(_items, "id", true);
      setScoreItems(_items);
      setCallAfterInit(true);
      setLoading(false);
      //setPreItems(_items);
    } catch (err: any) {
      console.log(err);
      setLoading(false);
    }
  };

  return (
    <>
      {!loading ? (
        <>
          {scoreItems && scoreItems.length > 0 && (
            <div className="scorecards-main">
              <Grid container spacing={3} className="scoreCardsGrid">
                {scoreItems.map((item, index) => (
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    key={index}
                    className="pl-10 pr-10 score-cards"
                  >
                    <div className="ui-container score-card-detail">
                      <Grid container spacing={3}>
                        <Grid item xs={12} sm={6} className="">
                          <div className="header">
                            GES ID/Year :
                            <span className="header-values cardId">
                              {item.unitIndicatorId}
                            </span>
                          </div>
                          <p className="header-values year">
                            <a
                              href={`#/CEOGES?year=${item.year}&scoreId=${item.id}`}
                            >
                              {item.year}
                            </a>
                          </p>
                        </Grid>
                        <Grid item xs={12} sm={6} className="align-right">
                          <span className="header-values status-buttons">
                            <Button
                              variant="contained"
                              style={{
                                backgroundColor:
                                  statusItems && statusItems.length > 0
                                    ? statusItems.filter(
                                        (s: any) => s.id === item.statusId
                                      )[0]?.color || "blue"
                                    : "primary",
                              }}
                              className={"dummyButtons"}
                            >
                              {item.status}
                            </Button>
                          </span>
                          {item.statusId === 2 && (
                            <p className="daysLeft">
                              <span className="title">
                                Days left to submit:{" "}
                              </span>
                              <span className="value">
                                {item.submitDaysLeft}
                              </span>
                            </p>
                          )}
                        </Grid>
                      </Grid>
                      <div className="horizontal-line-container">
                        <HorizontalDottedLine />
                      </div>
                      <Grid xs={12}>
                        <div className="score-result-details">
                          <p className="header">Score obtained : </p>
                          <h2 className="score-status">
                            {item.status === "Draft"
                              ? "TBD"
                              : `${
                                  item.genderLabelResponse?.label
                                    ? item.genderLabelResponse.label
                                    : ""
                                } [${
                                  item.genderLabelResponse?.value
                                    ? item.genderLabelResponse.value
                                    : ""
                                }]`}
                          </h2>
                          {/* <h3 className="header-values m-0"> {item.genderLabel}</h3> */}
                          <h4 className="complete-score">
                            {item.totalScore}/{item.maxTotalScore}
                            {`${
                              item.status == "Draft"
                                ? " [Full Score Pending]"
                                : ""
                            }`}
                            {/* {item.percentComplete === 100
                        ? ""
                        : "[Full Score pending]"} */}
                          </h4>
                        </div>
                        <div className="result-area-details">
                          {item.priorityAreas.map((pArea: any, index: any) =>
                            item.priorityAreas.length > 0 ? (
                              <p key={index} className="ra-title">
                                {pArea.title}:
                                <span className="ra-values">
                                  Gender Aware | {pArea.subTotal}/
                                  {pArea.maxTotal}
                                </span>
                              </p>
                            ) : (
                              <></>
                            )
                          )}
                        </div>
                      </Grid>
                    </div>
                  </Grid>
                ))}
              </Grid>
            </div>
          )}
        </>
      ) : (
        <div className="loader-container">
          <Spinner size="large" label="loading..." />
        </div>
      )}
    </>
  );
};

export default CEOScoreCards;
