import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import utility from "../../classes/utility";
import Config from "../../classes/config";
import Grid from "@mui/material/Grid/Grid";
import TextBox from "../Common/ReusableComponents/TextBox";
import SelectBoxInput from "../Common/ReusableComponents/SelectInputBox";
import FormControl from "@mui/material/FormControl/FormControl";
import FormGroup from "@mui/material/FormGroup/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel/FormControlLabel";
import Switch from "@mui/material/Switch/Switch";
import Spinner from "../Common/ReusableComponents/Spinner";
import Paper from "@mui/material/Paper/Paper";
import config from "../../classes/config";
import CheckBoxInput from "../Common/ReusableComponents/CheckBox";
import AlertDialog from "../Common/AlertDialog";
import Button from "@mui/material/Button";
import SelectBox from 'devextreme-react/select-box';

import { ReactComponent as SubmitAlertIcon } from "../../images/SubmitAlert.svg";
import { ReactComponent as WarningAlertIcon } from "../../images/Warning.svg";

interface UnitIndicator {
  indicatorId: number | null;
  unitIndicatorId: number | null;
  selected: boolean;
}

interface UnitProps {
  id: number | null;
  title: string;
  indicatorsSelected: string;
  country: {
    id: number | null;
    title: string;
  };
  updatedBy: string;
  updatedOn: Date;
  createdBy: string;
  createdOn: Date;
}

const AdminUnitAddEdit = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParameters = new URLSearchParams(location.search);
  const unitId = queryParameters.get("ID");
  const currentUserEmail = localStorage.getItem("currentUserEmail");

  const [items, setItems] = useState<any[]>([]);
  const [countries, setCountries] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  // const [unit, setUnit] = useState<UnitProps>({
  //   id: null,
  //   title: "",
  //   indicatorsSelected: "",
  //   country: {
  //     id: null,
  //     title: "",
  //   },
  //   updatedBy: "",
  //   updatedOn: new Date(),
  //   createdBy: "",
  //   createdOn: new Date(),
  // });
  const [state, setState] = useState<any>({
    id: null,
    title: "",
    indicatorsSelected: "",
    country: {
      id: null,
      title: "",
    },
    updatedBy: "",
    updatedOn: new Date(),
    createdBy: "",
    createdOn: new Date(),
});
  const [unitIndicators, setUnitIndicators] = useState<UnitIndicator[]>([]);
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<JSX.Element | string>("");
  const [hideAlertButton, setHideAlertButton] = useState<boolean>(false);
  const [resultArea, setResultArea] = useState<any>(null);
  const [priorityArea, setPriorityArea] = useState<any>(null);
  const [isUnitIndicatorToggled, setIsUnitIndicatorToggled] =
    useState<boolean>(false);
  const [isResultAreaToggled, setIsResultAreaToggled] =
    useState<boolean>(false);
  const [isPriorityAreaToggled, setIsPriorityAreaToggled] =
    useState<boolean>(false);
  const [validated, setValidated] = useState<boolean>(false);
  const [validateAlertMessage, setValidateAlertMessage] = useState<JSX.Element | string>("");
  const [showValidateAlert, setShowValidateAlert] = useState<boolean>(false);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);

      try {
        const countriesData: any = await utility.genericGetAPICall(
          `${Config.country}?pageSize=-1&pageNumber=-1&showAll=true&previousId=-1`
        );
        const filteredCountries = countriesData.filter(
          (x: any) => x.isDeleted === false
        );
        const formattedCountries = filteredCountries.map((r: any) => ({
          key: r.id,
          id: r.id,
          title: r.title,
          updatedBy: r.updatedBy,
          updatedOn: r.updatedOn,
          createdBy: r.createdBy,
          createdOn: r.createdOn,
        }));
        setCountries(formattedCountries);

        if (unitId !== "0") {
          const unitData: any = await utility.genericGetAPICall(
            `${Config.getUnitDetails}`
          );
          const filteredUnits = unitData.filter(
            (x: any) => x.id === Number(unitId)
          );
          const formattedUnit = filteredUnits.map((r: any) => ({
            id: r.id,
            title: r.title,
            indicatorsSelected: r.indicatorsSelected,
            country: {
              id: r.unitCountryDetails.id,
              title: r.unitCountryDetails.title,
            },
            updatedBy: r.updatedBy,
            updatedOn: r.updatedOn,
            createdBy: r.createdBy,
            createdOn: r.createdOn,
          }))[0]; // Assuming there's only one unit with the given ID

          setState((prevState:any) => ({
            ...prevState,
            id: formattedUnit.id,
            title: formattedUnit.title,
            indicatorsSelected: formattedUnit.indicatorsSelected,
            country: {
              id: formattedUnit.country?.id,
              title: formattedUnit.country?.title,
            },
            updatedBy: formattedUnit.updatedBy,
            updatedOn: formattedUnit.updatedOn,
            createdBy: formattedUnit.createdBy,
            createdOn: formattedUnit.createdOn,
        }));
          // setUnit(formattedUnit);
          console.log(formattedUnit);
        }

        const priorityAreasData: any = await utility.genericPostAPIcall(
          `${Config.priorityAreaDetails}`,
          { unitId }
        );
        const itemsData = priorityAreasData.map((element: any) => {
          const unitIndicators: UnitIndicator[] = [];
          if (Number(unitId) > 0) {
            element.resultAreas.forEach((ra: any) => {
              ra.indicators.forEach((ind: any) => {
                if (ind.unitIndicatorId !== 0) {
                  unitIndicators.push({
                    indicatorId: ind.id,
                    unitIndicatorId: ind.unitIndicatorId,
                    selected: ind.selected,
                  });
                }
              });
            });
          }
          return {
            ...element,
            unitIndicators,
          };
        });
        setItems(itemsData);
        setUnitIndicators(unitIndicators);
      } catch (error) {
        console.error("Error fetching data:", error);
      }

      setLoading(false);
    };

    fetchData();
  }, [unitId]);

  useEffect(() => {
    const updateResultAreaAndPriorityArea = (
      resultArea: any,
      priorityArea: any,
      updatedUnitIndicators: any[]
    ) => {
      setLoading(true);

      const newItems = [...items];

      const paIndex = newItems.findIndex(
        (pa: any) => pa.id === priorityArea.id
      );
      const raIndex = newItems[paIndex].resultAreas.findIndex(
        (ra: any) => ra?.id === resultArea.id
      );

      // Update indicators when result area is toggled
      if (isResultAreaToggled) {
        newItems[paIndex].resultAreas[raIndex].indicators.forEach(
          (indicator: any) => {
            indicator.selected = resultArea.selected;
          }
        );
        newItems[paIndex].resultAreas[raIndex].selected = resultArea.selected;
      }

      // Update result area selection based on all indicator selection
      if (isUnitIndicatorToggled) {
        newItems[paIndex].resultAreas[raIndex].indicators.forEach(
          (indicator: any) => {
            const unitIndicator = updatedUnitIndicators.find(
              (ui: any) => ui.indicatorId === indicator.id
            );
            if (unitIndicator) {
              indicator.selected = unitIndicator.selected;
            }
          }
        );

        const allIndicatorsSelected = newItems[paIndex].resultAreas[
          raIndex
        ].indicators.every((indicator: any) => indicator.selected === true);

        newItems[paIndex].resultAreas[raIndex].selected = allIndicatorsSelected;
      }

      // Update priority area when all result areas are toggled
      const allResultAreasSelected = newItems[paIndex].resultAreas.every(
        (ra: any) => ra.selected === true
      );
      const allResultAreasDeselected = newItems[paIndex].resultAreas.every(
        (ra: any) => ra.selected === false
      );
      if (allResultAreasSelected && !allResultAreasDeselected) {
        newItems[paIndex].selected = true;
        priorityArea.selected = true;
      } else {
        priorityArea.selected = false;
      }

      setItems(newItems);

      setLoading(false);

      // setIsPriorityAreaToggled(false);
      // setIsResultAreaToggled(false);
      // setIsUnitIndicatorToggled(false);
    };

    if (resultArea && priorityArea && unitIndicators) {
      updateResultAreaAndPriorityArea(resultArea, priorityArea, unitIndicators);
    }
  }, [
    // isUnitIndicatorToggled,
    // isResultAreaToggled,
    unitIndicators,
    resultArea,
    priorityArea,
    //unitIndicators,
    // // isUnitIndicatorToggled,
    // // isResultAreaToggled,
    // // isPriorityAreaToggled,
    // // items,
  ]);

  const createUnit = async () => {
    try {
      await validate();
      if (validated) {
        console.log("createUnit");
        setLoading(true);

        let dataToPassToService: any = {};
        dataToPassToService.id = unitId;
        dataToPassToService.title = state.title;
        dataToPassToService.countryId = state.country.id;
        dataToPassToService.createdBy = currentUserEmail;
        dataToPassToService.updatedBy = currentUserEmail;
        dataToPassToService.createdOn = new Date();
        dataToPassToService.updatedOn = new Date();
        dataToPassToService.tenantId = 0;
        dataToPassToService.isDeleted = false;
        dataToPassToService.deletedBy = null;
        dataToPassToService.deletedOn = null;

        let unitIndicatorsData: any = [];
        unitIndicators.forEach((ui) => {
          unitIndicatorsData.push({
            id: 0,
            createdBy: currentUserEmail,
            updatedBy: currentUserEmail,
            createdOn: new Date(),
            updatedOn: new Date(),
            tenantId: 0,
            isDeleted: false,
            deletedBy: null,
            deletedOn: null,
            unitId: unitId,
            indicatorId: ui.indicatorId,
          });
        });

        dataToPassToService.unitIndicators = unitIndicatorsData;

        const data = await utility.genericPostAPIcall(
          `${Config.createUnitWithUnitIndicators}`,
          dataToPassToService
        );
        const ItemsData: any[] = [];
        if (data && data.length > 0) {
          data.forEach((element: any) => {
            ItemsData.push(element);
          });
        }
        console.log(ItemsData);
        setAlertMessage(
          <>
            <p className="AlertIcon">
              {" "}
              <SubmitAlertIcon />
            </p>
            <p className="AlertTitle">Success!</p>
            <p className="AlertMessage">
              Unit: {state.title} Created successfully.
            </p>
          </>
        );
        setShowAlert(true);
        setLoading(false);
      }
    } catch (err: any) {
      console.error(err);
      console.log(err.response.data);
      setAlertMessage(
        <>
          <p className="AlertIcon">
            {" "}
            <WarningAlertIcon />
          </p>
          <p className="AlertTitle">Error!</p>
          <p className="AlertMessage">
            Unable to Create Unit. Please try again later.
          </p>
        </>
      );
      setShowAlert(true);
      setLoading(false);
    }
  };

  const updateUnit = async () => {
    try {
      await validate();
      if (validated) {
        console.log("updateUnit");
        setLoading(true);
        let dataToPassToService: any = {};
        dataToPassToService.id = unitId;
        dataToPassToService.title = state.title;
        dataToPassToService.countryId = state.country.id;
        dataToPassToService.createdBy = currentUserEmail;
        dataToPassToService.updatedBy = currentUserEmail;
        dataToPassToService.createdOn = new Date();
        dataToPassToService.updatedOn = new Date();
        dataToPassToService.tenantId = 0;
        dataToPassToService.isDeleted = false;
        dataToPassToService.deletedBy = null;
        dataToPassToService.deletedOn = null;

        let savedUnitIndicators = unitIndicators;
        let unitIndicatorsData: any = [];
        savedUnitIndicators.forEach((ui) => {
          unitIndicatorsData.push({
            id: ui.unitIndicatorId,
            createdBy: currentUserEmail,
            updatedBy: currentUserEmail,
            createdOn: new Date(),
            updatedOn: new Date(),
            tenantId: 0,
            isDeleted: false,
            deletedBy: null,
            deletedOn: null,
            unitId: unitId,
            indicatorId: ui.indicatorId,
            selected: ui.selected,
          });
        });

        dataToPassToService.unitIndicators = unitIndicatorsData;

        //console.log("dataToPassToService");
        //console.log(dataToPassToService);

        const data = await utility.genericPostAPIcall(
          `${Config.updateUnitWithUnitIndicators}`,
          dataToPassToService
        );

        setAlertMessage(
          <>
            <p className="AlertIcon">
              {" "}
              <SubmitAlertIcon />
            </p>
            <p className="AlertTitle">Success!</p>
            <p className="AlertMessage">
              Unit: {state.title} updated successfully.
            </p>
          </>
        );
        setShowAlert(true);
        setLoading(false);
      }
    } catch (err: any) {
      console.log(err.response.data);
      setAlertMessage(
        <>
          <p className="AlertIcon">
            {" "}
            <WarningAlertIcon />
          </p>
          <p className="AlertTitle">Error!</p>
          <p className="AlertMessage">
            Unable to Update Unit. Please try again later.
          </p>
        </>
      );
      setShowAlert(true);
      setLoading(false);
    }
  };

  const handleIndicatorSelectionChange = (
    item: any,
    indId: number,
    unitIndId: number,
    resultArea: any,
    priorityArea: any
  ) => {
    //console.log("handleIndicatorSelectionChange");
    setLoading(true);
    const updatedUnitIndicators = [...unitIndicators];
    const unitIndicatorIndex = updatedUnitIndicators.findIndex(
      (item) => item.indicatorId === indId && item.unitIndicatorId === unitIndId
    );

    if (unitIndicatorIndex > -1) {
      updatedUnitIndicators[unitIndicatorIndex].selected = item.value;
    } else {
      updatedUnitIndicators.push({
        indicatorId: indId,
        unitIndicatorId: unitIndId,
        selected: item.value,
      });
    }

    if (item && item.event && item.event.type === "dxclick") {
      setPriorityArea(priorityArea);
      setResultArea(resultArea);
      setUnitIndicators(updatedUnitIndicators);
      setIsUnitIndicatorToggled(true);
      setIsResultAreaToggled(false);
      setIsPriorityAreaToggled(false);
    } else {
      setPriorityArea(priorityArea);
      setResultArea(resultArea);
      setUnitIndicators(updatedUnitIndicators);
      setIsUnitIndicatorToggled(false);
      setIsResultAreaToggled(false);
      setIsPriorityAreaToggled(false);
    }
    setLoading(false);
  };

  const handlePriorityAreaSelectionChange = (item: any, priorityArea: any) => {
    // console.log("handlePriorityAreaSelectionChange");
    const updatedItems = [...items];

    const paIndex = updatedItems.findIndex(
      (pa: any) => pa.id === priorityArea.id
    );
    updatedItems[paIndex].selected = item.target.checked;
    updatedItems[paIndex].resultAreas.forEach((ra: any) => {
      ra.selected = item.target.checked;
      ra.indicators.forEach((indicator: any) => {
        indicator.selected = item.target.checked;
      });
    });

    setItems(updatedItems);
    setPriorityArea(priorityArea);
    setUnitIndicators([]);
    setIsUnitIndicatorToggled(false);
    setIsResultAreaToggled(false);
    setIsPriorityAreaToggled(true);
  };

  const handleResultAreaSelectionChange = (
    item: any,
    priorityArea: any,
    resultArea: any,
    updatedUnitIndicators: any[]
  ) => {
    if (item && item.event && item.event.type === "dxclick") {
      const updatedItems = [...items];
      const updatedResultArea = { ...resultArea, selected: item.value };

      if (item && item.event && item.event.type === "dxclick") {
        setItems(updatedItems);
        setPriorityArea(priorityArea);
        setResultArea(updatedResultArea);
        setIsUnitIndicatorToggled(false);
        setIsResultAreaToggled(true);
        setIsPriorityAreaToggled(false);
      } else {
        // setItems(updatedItems);
        // setPriorityArea(priorityArea);
        // setResultArea(updatedResultArea);
        // setIsUnitIndicatorToggled(false);
        // setIsResultAreaToggled(false);
        // setIsPriorityAreaToggled(false);
      }
    }
  };

  const validate = async() => {
    if (!state || !state.title || state.title === "") {
      setValidateAlertMessage(
        <>
          <p className="AlertIcon">
            {" "}
            <WarningAlertIcon />
          </p>
          <p className="AlertTitle">Error!</p>
          <p className="AlertMessage">Please enter Unit Name.</p>
        </>
      );
      console.log('validate title');
      setShowValidateAlert(true);
      //setLoading(false);
      setValidated(false);
      return;
    } 
    
    if (!state || !state.country || state.country.id === null) {
      setValidateAlertMessage(
        <>
          <p className="AlertIcon">
            {" "}
            <WarningAlertIcon />
          </p>
          <p className="AlertTitle">Error!</p>
          <p className="AlertMessage">Please select Country.</p>
        </>
      );
      console.log('validate country');
      setShowValidateAlert(true);
      setLoading(false);
      setValidated(false);
      return;
    }

    setValidated(true);
    return;
  };

  const handleUnitNameChange = (event: any) => {
    setState((prevState: any) => ({ ...prevState, title: event.value }));
    //setUnit((prevUnit: any) => ({ ...prevUnit, title: event.value }));
  };

  const handleCountryChange = (e: any) => {
    setState((prevState: any) => ({ ...prevState, country: e.value }));
    //setUnit((prevUnit: any) => ({ ...prevUnit, country: e.value }));
    // let _unit = { ...unit };
    // _unit.country = e.value;
    // setUnit(_unit);
  };

  const handleCancelClick = () => {
    navigate(`/AdminUnits`);
  };

  const handleSaveClick = () => {
    if (Number(unitId) === 0) {
      createUnit();
    } else {
      updateUnit();
    }
  };

  const handleCloseDialog = () => {
    setAlertMessage("");
    setShowAlert(false);
    navigate(`/AdminUnits`);
  };

  const handleCloseValidateDialog = () => {
    setValidateAlertMessage("");
    setShowValidateAlert(false);
  };

  const alert = () => {
    return(
    <AlertDialog
      showDialog={showAlert}
      message={alertMessage}
      title={config.productTitle}
      onClose={handleCloseDialog}
      hideAlertButton={hideAlertButton}
      buttonText="OKAY"
    />
  )};


  const validateAlert = () => {
   return(
    <AlertDialog
      showDialog={showValidateAlert}
      message={validateAlertMessage}
      title={config.productTitle}
      onClose={() => handleCloseValidateDialog()}
      hideAlertButton={hideAlertButton}
      buttonText="OKAY"
      //onOkButtonClick={handleCloseValidateDialog}
    />
   )};
  
  return (
    <div>
      {showAlert && alert()}
      {showValidateAlert && validateAlert()}
      {/* <h2 className="main-title">Select priority areas / categories that apply:</h2> */}
      {/* {unitId !== undefined ? <h1>Edit Unit</h1> : <h1>Add Unit</h1>} */}
      {!loading ? (
        <>
          {state ? (
            <>
              <div className="ui-container admin-unit">
                <Grid container spacing={3}>
                  <Grid item container xs={10} sm={12} spacing={2}>
                    <Grid key={"1"} item xs={12} sm={3}>
                        <TextBox
                          stylingMode={"text"}
                          value={state ? state.title : ""}
                          onValueChanged={(e: any) => {
                            handleUnitNameChange(e);
                          }}
                          label={"Unit Name"}
                        />
                      </Grid>
                    <Grid key={"2"} item xs={12} sm={3}>
                        {/* <SelectBoxInput
                          stylingMode="outlined"
                          displayExpr="title"
                          valueExpr="id"
                          showClearButton={true}
                          label="Country"
                          items={countries}
                          selectedItem={unit ? unit.country : null}
                          onSelectionChanged={handleCountryChange}
                        /> */}
                        <SelectBox
                          stylingMode="outlined"
                          showClearButton={true}
                          label="Country"
                          valueExpr="id"
                          displayExpr="title"
                          dataSource={countries}
                          selectedItem={{id: state.country?.id, title: state.country?.title}}
                          defaultValue={state.country?.id}
                          onSelectionChanged={(e: any) => {
                            handleCountryChange(e);
                          }}
                        />
                    </Grid>
                    
                    <Grid item xs={12} sm={6} className="filter-buttons">
                      <div className="confirm-action-buttons">
                        <Button
                          className="btn btn-secondary"
                          onClick={handleCancelClick}
                        >
                          Cancel
                        </Button>
                        <Button
                          className="btn btn-primary"
                          onClick={handleSaveClick}
                        >
                          Save
                        </Button>
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
              </div>
              <div className="AdminPAHeader">
                Select priority areas/categories that apply:
              </div>
              {!loading ? (
                <>
                  {items && items.length > 0 && (
                    <>
                      {items.map((priorityArea: any, index: any) => (
                        <div
                          key={`${priorityArea.id}-${index}`}
                          className="GESDetailsContainer1"
                        >
                          <Paper
                            key={`${priorityArea.id}-${index}`}
                            className="GESDetailsPaper"
                          >
                            <Grid
                              container
                              spacing={3}
                              key={index}
                              className="pl-10 pr-10"
                            >
                              <Grid item xs={12} sm={8}>
                                <div className="AdminPA">
                                  {priorityArea.title}
                                </div>
                              </Grid>
                              <Grid
                                item
                                xs={12}
                                sm={4}
                                className="align-right status-button"
                              >
                                <span className="header-values toggle-buttons">
                                  <FormControl
                                    component="fieldset"
                                    variant="standard"
                                  >
                                    <FormGroup>
                                      <FormControlLabel
                                        control={
                                          <Switch
                                            checked={priorityArea.selected}
                                            onChange={(e: any) =>
                                              handlePriorityAreaSelectionChange(
                                                e,
                                                priorityArea
                                              )
                                            }
                                            name="active"
                                          />
                                        }
                                        label=""
                                      />
                                    </FormGroup>
                                  </FormControl>
                                </span>
                              </Grid>
                            </Grid>
                            {priorityArea.resultAreas &&
                              priorityArea.resultAreas.map(
                                (resultArea: any, rindex: any) => (
                                  <div
                                    key={`${priorityArea.id}-${resultArea.id}`}
                                    className={`${priorityArea.id}-${resultArea.id}`}
                                  >
                                    <div className="AdminRA">
                                      <CheckBoxInput
                                        value={resultArea.selected}
                                        onValueChanged={(e: any) =>
                                          handleResultAreaSelectionChange(
                                            e,
                                            priorityArea,
                                            resultArea,
                                            unitIndicators
                                          )
                                        }
                                        text={" "}
                                      ></CheckBoxInput>
                                      {resultArea.title}
                                      {!loading ? (
                                        <>
                                          {resultArea.indicators &&
                                            resultArea.indicators.map(
                                              (
                                                indicator: any,
                                                indIndex: any
                                              ) => (
                                                <div
                                                  className={`AdminIndicator ${priorityArea.id}-${resultArea.id}-${indicator.id}`}
                                                  key={`${priorityArea.id}-${resultArea.id}-${indicator.id}`}
                                                >
                                                  <CheckBoxInput
                                                    value={indicator.selected}
                                                    onValueChanged={(e: any) =>
                                                      handleIndicatorSelectionChange(
                                                        e,
                                                        indicator.id,
                                                        indicator.unitIndicatorId,
                                                        resultArea,
                                                        priorityArea
                                                      )
                                                    }
                                                    text={" "}
                                                  ></CheckBoxInput>
                                                  {indicator.title}
                                                </div>
                                              )
                                            )}
                                        </>
                                      ) : (
                                        <Spinner size="small" />
                                      )}
                                    </div>
                                  </div>
                                )
                              )}
                          </Paper>
                        </div>
                      ))}
                    </>
                  )}
                </>
              ) : (
                <Spinner size="large" />
              )}
            </>
          ) : (
            <div className="no-results">
              <h2 className="main-title">No Results Found</h2>
            </div>
          )}
        </>
      ) : (
        <Spinner size="large" />
      )}
    </div>
  );
};

export default AdminUnitAddEdit;
