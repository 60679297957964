import React, { useState, useEffect, useRef, useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import utility from "../../classes/utility";
import config from "../../classes/config";
import TabPanel from "../Common/ReusableComponents/TabPanel";
import Spinner from "../Common/ReusableComponents/Spinner";
import TextAreaInput from "../Common/ReusableComponents/TextArea";
import AlertDialog from "../Common/AlertDialog";
import DeleteDialog from "../Common/DeleteAlert";

import { ReactComponent as RadioUncheckedIcon } from "../../images/RadioUnchecked.svg";
import { ReactComponent as RadioCheckedIcon } from "../../images/RadioChecked.svg";
import { ReactComponent as Chat } from "../../images/Chat.svg";
import { ReactComponent as RightArrowIcon } from "../../images/RightArrow.svg";
import { ReactComponent as WarningIcon } from "../../images/Warning.svg";
import { ReactComponent as DeleteWarningIcon } from "../../images/DeleteWarning.svg";
import { ReactComponent as SubmitAlertIcon } from "../../images/SubmitAlert.svg";
import { ReactComponent as HorizontalDottedLine } from "../../images/HorizontalDottedLine.svg";

import Grid from "@mui/material/Grid";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Paper from "@mui/material/Paper";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Radio, RadioGroup, FormControlLabel, Button } from "@mui/material";

const GESRadioOptionsList = [0, 1, 2, 3, 4];

interface summaryStateProps {
  unitId: string;
  unitName: string;
  percentComplete: string;
  totalScore: string;
  maxTotalScore: string;
  statusId: string;
  genderLabelResponse: { label: string; value: string };
}

interface HomeProps {
  unitId: number | null;
  roleId: number | null;
  currentYearRecordExists: boolean;
}

interface GESScoreItemsType {
  scorePriorityAreasList: {
    title: string;
  }[];
  scoreResultAreasList: {
    title: string;
  }[];
  scoreIndicatorList: {
    title: string;
    indicatorId: number;
  }[];
  // other properties if any
}

const GRTGES = (homeProps: HomeProps) => {
  const location = useLocation();
  const summaryTab = { id: -1, title: "Summary" };
  const [loading, setLoading] = useState<boolean>(false);
  const [scoreYear, setScoreYear] = useState<string>("");
  const [scoreId, setScoreId] = useState<string>("");
  const [selectedTab, setSelectedTab] = useState(0);
  const [GESItems, setGESItems] = useState<any[]>([]);
  const [GESScoreItems, setGESScoreItems] = useState<GESScoreItemsType[]>([]);
  const [comments, setComments] = useState({});
  const [isDratftEnabled, setIsDraftEnabled] = useState<boolean>(false);
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<JSX.Element | string>("");
  const [scoreCardExists, setScoreCardExists] = useState<boolean>(false);
  // const [scorecardSaved, setScorecardSaved] = useState<boolean>(false);
  // const [tempScoreId, setTempScoreId] = useState(null);
  // const [focusedIndicatorId, setFocusedIndicatorId] = useState(null);
  const [isCommentBoxOpen, setIsCommentBoxOpen] = useState(false);
  const [isScoreCardReadOnly, setIsScoreCardReadOnly] =
    useState<boolean>(false);
  const [validationChecksForSubmission, setValidationChecksForSubmission] =
    useState<boolean>(false);
  const [deleteInfo, setDeleteInfo] = useState<any>({});
  // const [GESScoreItems, setGESScoreItems] = useState([]);
  const [indicatorResponseItems, setIndicatorResponseItems] = useState<any[]>(
    []
  );
  const tabsWithSummary = [...GESScoreItems, summaryTab];
  const [previousYearScoresList, setPreviousYearScoresList] = useState<any[]>(
    []
  );
  const [showActionButtons, setShowActionButtons] = useState<boolean>(false);
  const [disableSummarySection, setDisableSummarySection] =
    useState<boolean>(false);
  const [expandedState, setExpandedState] = useState<
    Record<number, Record<number, { expanded: boolean }>>
  >({});
  const [accordionTitles, setAccordionTitles] = useState<
    Record<string, string>
  >({});
  const currentYear = new Date().getFullYear();
  // const [hideAlertButton, setHideAlertButton] = useState<boolean>(false);
  const [showDeleteAlert, setShowDeleteAlert] = useState<boolean>(false);
  const [deleteAlertMessage, setDeleteAlertMessage] = useState<
    JSX.Element | string
  >("");
  const [submitAlertMessage, setSubmitAlertMessage] = useState<
    JSX.Element | string
  >("");
  const [showSubmitAlert, setShowSubmitAlert] = useState<boolean>(false);
  const [resetAlertMessage, setResetAlertMessage] = useState<
    JSX.Element | string
  >("");
  const [showResetAlert, setShowResetAlert] = useState<boolean>(false);
  const textBoxRef = useRef<any>(null);
  // const [isFocused, setIsFocused] = useState(false);
  // const [showTextArea, setShowTextArea] = useState(false);

  const [state, setState] = useState<summaryStateProps>({
    unitId: "",
    unitName: "",
    percentComplete: "",
    totalScore: "",
    maxTotalScore: "",
    statusId: "",
    genderLabelResponse: { label: "", value: "" },
  });

  const navigate = useNavigate();

  // const handleBlur = () => {
  //   setIsFocused(false);
  // };

  const generateScoreCard = useCallback((year: any, indDes: any) => {
    setLoading(true);
    const newScoreCardData: any = [];
    const _indicatorResponseData: any = [];

    if (indDes && indDes.length > 0) {
      const uniqueEntriesMap = new Map();

      indDes.forEach((r: any) => {
        if (config.priorityAreasListNames.includes(r.priorityAreaName)) {
          const key = `${r.priorityAreaName}-${r.priorityAreaId}`;

          if (!uniqueEntriesMap.has(key)) {
            uniqueEntriesMap.set(key, {
              title: r.priorityAreaName,
              id: r.priorityAreaId,
              unitId: r.unitId,
              scoreResultAreasList: [],
              genderLabelResponse: { label: 0, value: null },
            });
          }

          const resultArea = uniqueEntriesMap
            .get(key)
            ?.scoreResultAreasList.find(
              (ra: any) => ra.title === r.resultAreaName
            );

          if (!resultArea) {
            uniqueEntriesMap.get(key)?.scoreResultAreasList.push({
              title: r.resultAreaName,
              id: r.resultAreaId,
              scoreIndicatorList: [
                {
                  indicatorTitle: r.indicatorName,
                  indicatorId: r.indicatorId,
                  response: r.scoreIndicatorResponse,
                  scoreIndicatorId: r.scoreIndicatorId,
                  unitIndicatorDescriptions:
                    r.unitIndicatorDescriptionList?.map((desc: any) => ({
                      response: desc.response,
                      description: desc.description,
                    })) || [],
                },
              ],
            });
          } else {
            const indicator = resultArea.scoreIndicatorList.find(
              (ind: any) => ind.indicatorTitle === r.indicatorName
            );

            if (!indicator) {
              resultArea.scoreIndicatorList.push({
                indicatorTitle: r.indicatorName,
                indicatorId: r.indicatorId,
                response: r.scoreIndicatorResponse,
                scoreIndicatorId: r.scoreIndicatorId,
                unitIndicatorId: r.unitIndicatorId,
                unitIndicatorDescriptions:
                  r.unitIndicatorDescriptionList?.map((desc: any) => ({
                    response: desc.response,
                    description: desc.description,
                  })) || [],
              });
            } else {
              indicator.unitIndicatorDescriptions.push(
                ...(r.unitIndicatorDescriptionList?.map((desc: any) => ({
                  response: desc.response,
                  description: desc.description,
                })) || [])
              );
            }
          }
        }

        _indicatorResponseData.push({
          id: r.scoreIndicatorId,
          indicatorId: r.indicatorId,
          response: r.scoreIndicatorResponse?.toString() || null,
          comments: r.comments,
          unitIndicatorId: r.unitIndicatorId,
        });
      });

      uniqueEntriesMap.forEach((value) => {
        newScoreCardData.push(value);
      });
      setGESScoreItems(newScoreCardData);
      setIndicatorResponseItems(_indicatorResponseData);
      setDisableSummarySection(true);
      // console.log("manipulated indicator responses", _indicatorResponseData);
      // console.log("manipulated GES Score Items", newScoreCardData);
      setLoading(false);
    }
  }, []);

  const displayScoreCards = (activeRecords: any) => {
    setScoreCardExists(true);
    let _items: any = [];
    if (activeRecords && activeRecords.length > 0) {
      _items = activeRecords[0];
      if (_items.statusId === 3 || _items.statusId === 1) {
        setIsScoreCardReadOnly(true);
      }
      setState((prevState) => ({
        ...prevState,
        unitId: _items.unitId,
        unitName: _items.unitName,
        percentComplete: _items.percentComplete,
        statusId: _items.statusId,
        totalScore: _items.totalScore,
        maxTotalScore: _items.maxTotalScore,
        year: _items.year,
        genderLabelResponse: {
          label: _items.genderLabelResponse?.label,
          value: _items.genderLabelResponse?.value,
        },
      }));

      let _gesScoreData: any = [];
      let priorityAreaData: any = {};
      let resultAreaData: any = {};
      let indicatorData: any = {};
      let _indicatorResponseData: any = [];

      if (_items.scorePriorityAreasList) {
        _items.scorePriorityAreasList.forEach((priorityArea: any) => {
          // let priorityAreaStatus = "";
          priorityAreaData = {
            id: priorityArea.id,
            title: priorityArea.title,
            maxTotal: priorityArea.maxTotal,
            subTotal: priorityArea.subTotal,
            genderLabelResponse: {
              label: priorityArea.genderLabelResponse?.label,
              value: priorityArea.genderLabelResponse?.value,
            },
            scoreResultAreasList: [],
          };

          if (_items.scoreResultAreasList) {
            const relevantResultAreas = _items.scoreResultAreasList.filter(
              (resultArea: any) => resultArea.priorityAreaId === priorityArea.id
            );

            relevantResultAreas.forEach((resultArea: any) => {
              resultAreaData = {
                id: resultArea.id,
                title: resultArea.title,
                maxTotal: resultArea.maxTotal,
                subTotal: resultArea.subTotal,
                genderLabelResponse: {
                  label: resultArea.genderLabelResponse?.label,
                  value: resultArea.genderLabelResponse?.value,
                },
                scoreIndicatorList: [],
              };

              if (_items.scoreIndicatorList) {
                const relevantIndicators = _items.scoreIndicatorList.filter(
                  (indicator: any) =>
                    indicator.resultAreaId === resultArea.id &&
                    indicator.priorityAreaId === priorityArea.id
                );

                relevantIndicators.forEach((indicator: any) => {
                  indicatorData = {
                    id: indicator.id,
                    indicatorId: indicator.indicatorId,
                    indicatorTitle: indicator.indicatorTitle,
                    priorityAreaId: indicator.priorityAreaId,
                    priorityAreaTitle: indicator.priorityAreaTitle,
                    resultAreaId: indicator.resultAreaId,
                    resultAreaTitle: indicator.resultAreaTitle,
                    response: indicator.response,
                    comments: indicator.comments,
                  };

                  resultAreaData.scoreIndicatorList.push(indicatorData);

                  _indicatorResponseData.push({
                    id: indicator.id,
                    indicatorId: indicator.indicatorId,
                    response: indicator.response?.toString() || null,
                    comments: indicator.comments,
                  });
                });

                // Check the number of responses for the current priority area
                const numResponses = _items.scoreIndicatorList.filter(
                  (indicator: any) =>
                    indicator.priorityAreaId === priorityArea.id &&
                    indicator.response !== null
                ).length;

                let priorityAreaStatus = "";

                let pa = _items.scoreIndicatorList.filter(
                  (indicator: any) =>
                    indicator.priorityAreaId === priorityArea.id
                );

                if (numResponses === 0) {
                  priorityAreaStatus = "empty";
                } else if (numResponses < pa.length) {
                  priorityAreaStatus = "partial";
                } else {
                  priorityAreaStatus = "full";
                }
                priorityAreaData.priorityAreaStatus = priorityAreaStatus;
              }

              priorityAreaData.scoreResultAreasList.push(resultAreaData);

              return resultAreaData;
            });

            _gesScoreData.push(priorityAreaData);
          }
        });

        //sort result area along with indicator data by their ids
        _gesScoreData.forEach((priorityArea: any) => {
          priorityArea.scoreResultAreasList.forEach((resultArea: any) => {
            resultArea.scoreIndicatorList = utility._sortItems(
              resultArea.scoreIndicatorList,
              "id",
              false
            );
          });
          priorityArea.scoreResultAreasList = utility._sortItems(
            priorityArea.scoreResultAreasList,
            "id",
            false
          );
        });

        //sort based on priority area neames defined in config file
        const titleIndexMap = {};
        config.priorityAreasListNames.forEach((title: any, index: any) => {
          titleIndexMap[title] = index;
        });

        _gesScoreData.sort(
          (a: any, b: any) => titleIndexMap[a.title] - titleIndexMap[b.title]
        );

        setGESScoreItems(_gesScoreData);
        setIndicatorResponseItems(_indicatorResponseData);
        setDisableSummarySection(false);
        setIsDraftEnabled(false);

        // console.log("score data :::", _gesScoreData);
        // console.log("score responses :: ", _indicatorResponseData);
      }
    }
    setLoading(false);
  };

  const handleAccordionChange = (
    tabIndex: number,
    resultAreaId: number,
    indicatorId: number
  ) => {
    setExpandedState((prev) => {
      const newExpandedState: Record<
        number,
        Record<number, { expanded: boolean }>
      > = { ...prev };

      if (!newExpandedState[tabIndex]) {
        newExpandedState[tabIndex] = {};
      }

      if (!newExpandedState[tabIndex][resultAreaId]) {
        newExpandedState[tabIndex][resultAreaId] = { expanded: false };
      }

      newExpandedState[tabIndex][resultAreaId][indicatorId] = {
        expanded:
          !newExpandedState[tabIndex][resultAreaId][indicatorId]?.expanded,
      };

      // Update the accordion title based on the expanded state
      // const accordionTitle = newExpandedState[tabIndex][resultAreaId][indicatorId].expanded
      //   ? `*Response Descriptions for Result Area ${resultAreaId} - Indicator ${indicatorId}:*`
      //   : `*Click Here to view response Descriptions for Result Area ${resultAreaId} - Indicator ${indicatorId}*`;

      const accordionTitle = newExpandedState[tabIndex][resultAreaId][
        indicatorId
      ].expanded
        ? `*Response Descriptions*`
        : `*Click Here to view response Descriptions*`;

      setAccordionTitles((prevTitles) => ({
        ...prevTitles,
        [`tab-${tabIndex}-accordion-${resultAreaId}-${indicatorId}`]:
          accordionTitle,
      }));
      return newExpandedState;
    });
  };

  const closeAllAccordions = () => {
    setExpandedState({});
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  };

  const goToPreviousTab = () => {
    setSelectedTab((prevTab) => Math.max(prevTab - 1, 0));
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }, 0);
  };

  const goToNextTab = () => {
    if (disableSummarySection) {
      setSelectedTab((prevTab) =>
        Math.min(prevTab + 1, tabsWithSummary.length - 2)
      );
    } else {
      setSelectedTab((prevTab) =>
        Math.min(prevTab + 1, tabsWithSummary.length - 1)
      );
    }
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const goToSummaryTab = () => {
    setSelectedTab(tabsWithSummary.length - 1);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  // const focusOnTextArea = () => {
  //   setShowTextArea(true);
  // };

  const handleResetScoreCard = () => {
    // setSelectedTab(0);
    if (isDratftEnabled) {
      setResetAlertMessage(
        <>
          <p className="DeleteAlertIcon">
            {" "}
            <DeleteWarningIcon />
          </p>
          <p className="AlertTitle">Reset Scorecard?</p>
          <p className="AlertMessage">
            You have unsaved changes. Are you sure you want to continue?
          </p>
        </>
      );
      setShowResetAlert(true);
    } else {
      navigate(`/GRTHome`);
    }
  };

  const _textAreaChanged = (
    e: any,
    indicatorId: any,
    indicatorResponse: any
  ) => {
    const newComment = e.value;

    setComments((prevComments) => ({
      ...prevComments,
      [indicatorId]: {
        ...prevComments[indicatorId],
        value: newComment,
      },
    }));

    if (
      newComment !== "" &&
      newComment !== undefined &&
      newComment.length > 0
    ) {
      setDisableSummarySection(true);
      setIsDraftEnabled(true);
    } else {
      setDisableSummarySection(false);
      setIsDraftEnabled(false);
    }

    updateIndicatorResponses(indicatorId, indicatorResponse, newComment);
  };

  const alert = () => (
    <AlertDialog
      showDialog={showAlert}
      message={alertMessage}
      title={config.productTitle}
      onClose={() => {
        setAlertMessage("");
        setShowAlert(false);
      }}
      // hideAlertButton={hideAlertButton}
      buttonText="OKAY"
    />
  );

  const resetAlert = () => {
    return (
      <DeleteDialog
        showDialog={showResetAlert}
        message={resetAlertMessage}
        title={config.productTitle}
        onCancelButton={() => {
          setResetAlertMessage("");
          setShowResetAlert(false);
        }}
        onClose={() => {
          setResetAlertMessage("");
          setShowResetAlert(false);
        }}
        buttonText="RESET"
        onOkButton={() => {
          setResetAlertMessage("");
          setShowResetAlert(false);
          navigate(`/GRTHome`);
        }}
      />
    );
  };

  const toggleCommentVisibility = (
    indicatorId: any,
    indicatorResponse: any
  ) => {
    setComments((prevComments) => ({
      ...prevComments,
      [indicatorId]: {
        ...prevComments[indicatorId],
        isVisible: !prevComments[indicatorId]?.isVisible,
      },
    }));

    if (!comments[indicatorId]?.isVisible) {
      // setFocusedIndicatorId(indicatorId);
      setFocus(); // Set the focused indicator ID
    }
    setIsCommentBoxOpen(false);

    // updateIndicatorResponses(indicatorId, indicatorResponse, comments[indicatorId]);
  };

  const handleDeleteClick = (indicatorId: any, indicatorResponse: any) => {
    setDeleteAlertMessage(
      <>
        <p className="DeleteAlertIcon">
          {" "}
          <DeleteWarningIcon />
        </p>
        <p className="AlertTitle">Delete Comment?</p>
        <p className="AlertMessage">
          Are you sure you want to proceed? This action cannot be undone.
        </p>
      </>
    );
    setShowDeleteAlert(true);

    if (!isScoreCardReadOnly) {
      // Save the indicatorId and indicatorResponse in state for later use
      setDeleteInfo({ indicatorId, indicatorResponse });
    }
  };

  const handleDeleteConfirm = () => {
    const { indicatorId, indicatorResponse } = deleteInfo;
    deleteComment(indicatorId, indicatorResponse);
    setShowDeleteAlert(false);
    setDisableSummarySection(true);
    setIsDraftEnabled(true);
  };

  const deleteComment = (indicatorId: any, indicatorResponse: any) => {
    if (!isScoreCardReadOnly) {
      setComments((prevComments) => {
        const updatedComments = { ...prevComments };

        if (updatedComments[indicatorId]) {
          delete updatedComments[indicatorId];
        }
        return updatedComments;
      });
      updateIndicatorResponses(indicatorId, indicatorResponse, "", false);
    }
  };

  const updateIndicatorResponses = (
    indicatorId: any,
    response?: any,
    comments?: any,
    commentVisibility?: boolean
  ) => {
    // const indicatorResponseItem = {
    //   indicatorId: indicatorId,
    //   response:
    //     response !== undefined ? response : selectedRadio[indicatorId] || "", // Use selected radio value
    //   comments: comments !== undefined ? comments : "",
    // };

    const indicatorResponseItemIndex = indicatorResponseItems.findIndex(
      (item) => item.indicatorId === indicatorId
    );

    if (indicatorResponseItemIndex !== -1) {
      // Only update if the new value is provided
      if (response !== undefined) {
        indicatorResponseItems[indicatorResponseItemIndex].response = response;
      }

      if (comments !== undefined) {
        indicatorResponseItems[indicatorResponseItemIndex].comments = comments;
      }
    }
    // else {
    //   indicatorResponseItems.push(indicatorResponseItem);
    // }

    // if (commentVisibility !== undefined) {
    //   indicatorResponseItems[indicatorResponseItemIndex].commentVisibility = commentVisibility;
    // }
    // setIsDraftEnabled(true);
    // console.log("score responses :: ", indicatorResponseItems);
  };

  const handleRadioButtonChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    indicatorId: any,
    resultAreaId: any
  ) => {
    if (!isScoreCardReadOnly) {
      const newSelectedValue = event.target.value;
      // setSelectedResponse(newSelectedValue);
      setDisableSummarySection(true);
      setIsDraftEnabled(true);

      setGESScoreItems((prevData) => {
        // Find the correct result area and indicator in the existing data
        const updatedData = prevData.map((tab) => {
          if (tab.scoreResultAreasList) {
            tab.scoreResultAreasList = tab.scoreResultAreasList.map(
              (resultArea: any) => {
                if (
                  resultArea.id === resultAreaId &&
                  resultArea.scoreIndicatorList
                ) {
                  resultArea.scoreIndicatorList =
                    resultArea.scoreIndicatorList.map((indicator: any) => {
                      if (indicator.indicatorId === indicatorId) {
                        //push the new data to the indicatorResponseItems array

                        updateIndicatorResponses(
                          indicatorId,
                          newSelectedValue,
                          undefined
                        );
                        return {
                          ...indicator,
                          response: newSelectedValue,
                        };
                      }
                      return indicator;
                    });
                }
                return resultArea;
              }
            );
          }
          return tab;
        });
        // console.log("GESScoreItems", GESScoreItems);
        return updatedData;
      });
    }
  };

  const saveDraftData = async (isSubmit: boolean = false) => {
    setLoading(true);
    const dataToPassToService: any = {};
    dataToPassToService.updatedBy = "naga@atensystems.com";
    dataToPassToService.updatedOn = new Date();

    if (
      scoreCardExists &&
      scoreId &&
      scoreId !== null &&
      scoreId !== undefined
    ) {
      dataToPassToService.id = scoreId;
      dataToPassToService.statusId = isSubmit ? 3 : state.statusId;
      dataToPassToService.updateScoreIndicatorRequests = [];
      if (indicatorResponseItems && indicatorResponseItems.length > 0) {
        indicatorResponseItems.forEach((item: any) => {
          dataToPassToService.updateScoreIndicatorRequests.push({
            id: item.id,
            response: item.response,
            comments: item.comments ? item.comments : "",
            updatedBy: "naga@atensystems.com",
            updatedOn: new Date(),
          });
        });
      }
      try{
      const data:any = await utility.genericPostAPIcall(`${config.updateScore}`, dataToPassToService);
          if (data) {
            //console.log("score card updated");
            // setScorecardSaved(true);
            // setTempScoreId(null);
            setIsDraftEnabled(false);
            closeAllAccordions();
            setIsCommentBoxOpen(false);
            if (isSubmit) {
              setLoading(false);
              closeAllAccordions();
              setIsCommentBoxOpen(false);
              navigate("/GRTConfirmation", { state });
            } else {
              setAlertMessage(
                <>
                  <p className="AlertIcon">
                    {" "}
                    <SubmitAlertIcon />
                  </p>
                  <p className="AlertTitle">Success!</p>
                  <p className="AlertMessage">
                    Scorecard has been saved successfully.
                  </p>
                </>
              );
              setShowAlert(true);
              // setDisableSummarySection(false);
              setIsCommentBoxOpen(false);
              closeAllAccordions();
              getScoresData(data.year, data.id);
              setLoading(false);
            }
          }
        }catch(error: any){
          console.error("Error saving Draft", error);
          setAlertMessage(
            <>
              <p className="AlertIcon">
                {" "}
                <WarningIcon />
              </p>
              <p className="AlertTitle">Save Error!</p>
              <p className="AlertMessage">
                Error saving Scorecard. Please try again later.
              </p>
            </>
          );
          setShowAlert(true);
          setLoading(false);
        };
    } else {
      dataToPassToService.year = scoreYear;
      dataToPassToService.createdBy = "naga@atensystems.com";
      dataToPassToService.createdOn = new Date();
      dataToPassToService.statusId = 2;
      dataToPassToService.unitId = homeProps.unitId;
      dataToPassToService.scoreIndicatorRequests = [];

      if (indicatorResponseItems && indicatorResponseItems.length > 0) {
        indicatorResponseItems.forEach((item: any) => {
          dataToPassToService.scoreIndicatorRequests.push({
            id: 0,
            unitIndicatorId: item?.unitIndicatorId,
            response: item.response,
            comments: item.comments ? item.comments : "",
            createdBy: "naga@atensystems.com",
            createdOn: new Date(),
            scoreId: 0,
            updatedBy: "naga@atensystems.com",
            updatedOn: new Date(),
          });
        });
      }

      try{
      const data:any = await utility.genericPostAPIcall(`${config.createScore}`, dataToPassToService);
          if (data) {
            //console.log("score card created");
            // setTempScoreId(data.id);
            setScoreId(data.id);
            if (isSubmit) {
              setLoading(false);
              setState((prevState) => ({
                ...prevState,
                statusId: "3",
              }));
              closeAllAccordions();
              setIsCommentBoxOpen(false);
              navigate("/GRTConfirmation", { state });
            } else {
              setAlertMessage(
                <>
                  <p className="AlertIcon">
                    {" "}
                    <SubmitAlertIcon />
                  </p>
                  <p className="AlertTitle">Success!</p>
                  <p className="AlertMessage">
                    Scorecard {data.id} has been created successfully.
                  </p>
                </>
              );
              setShowAlert(true);
              setIsCommentBoxOpen(false);
              closeAllAccordions();
              getScoresData(data.year, data.id);
              setLoading(false);
              navigate("/GRTGES?year=" + data.year + "&scoreId=" + data.id);
            }
          }
        }catch(error: any) {
          console.error("Error creating score card:", error);
        };
    }
  };

  const handleSubmitScoreCard = () => {
    setValidationChecksForSubmission(true);
    let emptyResponseCount = 0;

    //loop through indicatorResponseItems and get the count of null responses
    if (indicatorResponseItems && indicatorResponseItems.length > 0) {
      indicatorResponseItems.forEach((item: any) => {
        if (item.response === null) {
          emptyResponseCount++;
        }
      });

      if (emptyResponseCount > 0) {
        setAlertMessage(
          <>
            <p className="AlertIcon">
              {" "}
              <WarningIcon />
            </p>
            <p className="AlertTitle">Cannot Submit!</p>
            <p className="AlertMessage">
              There are {emptyResponseCount} categories which have been left
              unanswered. Please complete and submit again.
            </p>
          </>
        );
        setShowAlert(true);
        return;
      } else {
        setSubmitAlertMessage(
          <>
            <p className="SubmitAlertIcon">
              {" "}
              <SubmitAlertIcon />
            </p>
            <p className="AlertTitle">Final Submission!</p>
            <p className="AlertMessage">
              Are you sure you want to submit? Scores post submission will be
              locked for any further edits.
            </p>
          </>
        );
        setShowSubmitAlert(true);
        //saveDraftData(isSubmit);
      }
    }
  };

  const DeleteAlert = () => {
    return (
      <DeleteDialog
        showDialog={showDeleteAlert}
        message={deleteAlertMessage}
        title={config.productTitle}
        onClose={() => setShowDeleteAlert(false)}
        onOkButton={handleDeleteConfirm}
        onCancelButton={() => setShowDeleteAlert(false)}
        buttonText="DELETE"
      />
    );
  };

  const SubmitAlert = () => {
    return (
      <DeleteDialog
        showDialog={showSubmitAlert}
        message={submitAlertMessage}
        title={config.productTitle}
        onClose={() => setShowSubmitAlert(false)}
        onOkButton={() => saveDraftData(true)}
        onCancelButton={() => setShowSubmitAlert(false)}
        buttonText="SUBMIT"
      />
    );
  };

  const handleScroll = () => {
    setShowActionButtons(window.scrollY > 100);
  };

  const getScoresData = useCallback(async (year: any, scoreID?: any, indicatorDesc?: any) => {
      setLoading(true);
      let dataToPassToService: any = {};
      dataToPassToService.unitId = homeProps.unitId;
      dataToPassToService.scoreId = scoreID ? +scoreID : 0;
      dataToPassToService.yearFrom = +year;
      dataToPassToService.yearTo = +year;
      try {
        const data:any = await utility
          .genericPostAPIcall(
            `${config.searchScores}?showAll=true&pageSize=-1&pageNumber=-1`,
            dataToPassToService
          );
         
            const activeRecords = data?.filter(
              (item: any) => item.isDeleted === false
            );

            if (scoreID === null || scoreID === undefined) {
              if (activeRecords && activeRecords.length > 0) {
                setScoreCardExists(true);
                // console.log(
                //   `Score card already exists for the year ${currentYear}`
                // );
                navigate(
                  `/GRTGES?year=${currentYear}&scoreId=${activeRecords[0].id}`
                );
                setLoading(false);
              } else {
                setScoreCardExists(false);
                generateScoreCard(year, indicatorDesc);
              }
            } else {
              displayScoreCards(activeRecords);
            }
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    },
    [currentYear, homeProps.unitId, setLoading, navigate, generateScoreCard]
  );

  useEffect(() => {
    const search = location.search;
    const params = new URLSearchParams(search);
    const searchYear = params.get("year");
    const searchScoreId = params.get("scoreId");

    const getPreviousYearScoresData = async() => {
      let dataToPassToService: any = {};
      dataToPassToService.unitId = homeProps.unitId;
      // dataToPassToService.scoreId = scoreID ? +scoreID : 0;
      dataToPassToService.yearFrom = +scoreYear - 1;
      dataToPassToService.yearTo = +scoreYear - 1;
      try{
      const data: any = await utility
        .genericPostAPIcall(
          `${config.searchScores}?showAll=true&pageSize=-1&pageNumber=-1`,
          dataToPassToService
        );
          const activeRecords = data?.filter((r: any) => r.isDeleted === false);
          let _items: any = [];
          if (activeRecords && activeRecords.length === 1) {
            let prevItems = activeRecords[0].scoreIndicatorList;
            if (prevItems) {
              prevItems.forEach((r: any) => {
                _items.push({
                  indicatorId: r.id,
                  response: r.response,
                });
              });
              prevItems = utility._sortItems(prevItems, "indicatorId", false);
            }
            setPreviousYearScoresList(prevItems);
          }
        }catch(error: any){
          console.error(error);
        };
    };

    const getGESDescriptionsData: any = async () => {
      try {
        const dataToPassToService: any = {};
        dataToPassToService.unitId = homeProps.unitId;
        dataToPassToService.scoreId = scoreId ? +scoreId : 0;

        const data: any = await utility.genericPostAPIcall(
          `${config.searchUnitIndicatorDetails}`,
          dataToPassToService
        );
        // .then((data: any) => {
        const GESItemsData: any[] = [];

        if (data && data.length > 0) {
          data.forEach((r: any) => {
            const unitIndicatorDescriptionList: any[] = [];

            r.unitIndicatorDescriptionList?.forEach((item: any) => {
              unitIndicatorDescriptionList.push({
                response: item?.response,
                description: item?.description,
              });
            });

            GESItemsData.push({
              key: r.indicatorId,
              indicatorId: r.indicatorId,
              unitIndicatorId: r.unitIndicatorId,
              scoreIndicatorResponse: r.scoreIndicatorResponse,
              unitIndicatorDescriptionList: unitIndicatorDescriptionList,
            });
          });
        }

        setGESItems(GESItemsData);
        // console.log("descriptions data", GESItemsData);
        setLoading(false);
        // });
        return data; // Explicitly return the data
      } catch (error) {
        console.error("Error fetching priorityAreas data:", error);
        throw error; // Rethrow the error if needed
      }
    };

    if (searchYear) {
      setScoreYear(searchYear);
    }

    if (searchScoreId) {
      setScoreId(searchScoreId);
    }

    const fetchData = async () => {
      setLoading(true);
      getPreviousYearScoresData();
      const descriptionsData = await getGESDescriptionsData();
      await getScoresData(searchYear, searchScoreId, descriptionsData);
    };

    if (scoreId || scoreYear) {
      fetchData();
    }
  }, [scoreYear, scoreId, getScoresData, location.search, homeProps.unitId]);

  const setFocus = () => {
    // setIsFocused(true);
    if (textBoxRef.current) {
      textBoxRef.current.instance.focus();
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    const checkScroll = () => {
      const hasScrollbar = document.body.scrollHeight > window.innerHeight;
      const container = document.querySelector(".gesButtonContainer");

      if (container) {
        if (hasScrollbar) {
          container.classList.remove("floatingButtons");
        } else {
          container.classList.add("floatingButtons");
        }

        // Check if scroll has reached the bottom
        const isAtBottom =
          window.innerHeight + window.scrollY >= document.body.offsetHeight;

        if (isAtBottom) {
          // Scroll has reached the bottom, add both classes
          container.classList.add("bottom");
          container.classList.add("floatingButtons");
        } else {
          // If not at bottom, remove bottom class
          container.classList.remove("bottom");
          container.classList.add("floatingButtons");
        }
      }
    };

    window.addEventListener("resize", checkScroll);
    window.addEventListener("scroll", checkScroll); // Add scroll event listener

    return () => {
      window.removeEventListener("resize", checkScroll);
      window.removeEventListener("scroll", checkScroll); // Remove scroll event listener
    };
  }, []);

  return (
    <>
      {showAlert && alert()}
      {showDeleteAlert && DeleteAlert()}
      {showSubmitAlert && SubmitAlert()}
      {showResetAlert && resetAlert()}
      {!loading ? (
        <>
          {GESScoreItems && GESScoreItems.length > 0 ? (
            <>
              <div className="mainContent">
                {/* <h2 className="main-title">Priority Areas</h2> */}

                <div className="">
                  <Grid container>
                    <Grid item xs={12}>
                      <div className="tabs-container">
                        <Tabs
                          value={selectedTab}
                          onChange={handleTabChange}
                          className="tabs-section"
                          variant="scrollable"
                          scrollButtons="auto"
                        >
                          {tabsWithSummary.map((tab: any, index: number) => (
                            <Tab
                              key={tab.id}
                              label={`${tab.title}`}
                              disabled={
                                tab.title === "Summary" && disableSummarySection
                              }
                              className={`
                                ${
                                  index !== tabsWithSummary.length - 1 &&
                                  (tab.priorityAreaStatus === "empty"
                                    ? "emptyRes"
                                    : tab.priorityAreaStatus === "partial"
                                    ? "partialRes"
                                    : tab.priorityAreaStatus === "full"
                                    ? "fullRes"
                                    : "")
                                }
                              `}
                            />
                          ))}
                        </Tabs>
                      </div>
                    </Grid>

                    <Grid item xs={12}>
                      {tabsWithSummary.map((tab: any, index: any) => (
                        <TabPanel
                          key={tab.id}
                          value={selectedTab}
                          index={index}
                        >
                          {tab.id === -1 ? (
                            <div className="GESSummaryContainer">
                              <h2 className="main-title">Summary</h2>
                              <Grid container spacing={3}>
                                {GESScoreItems &&
                                  GESScoreItems.length > 0 &&
                                  GESScoreItems.map(
                                    (pItem: any, pIndex: any) => (
                                      <Grid item xs={12} sm={6} key={pIndex}>
                                        <Paper
                                          key={pIndex}
                                          className="GESSummaryPaper"
                                        >
                                          <h4>{`Priority Area ${pIndex + 1}: ${
                                            pItem.title
                                          }`}</h4>
                                          {pItem.scoreResultAreasList &&
                                            pItem.scoreResultAreasList.length >
                                              0 &&
                                            pItem.scoreResultAreasList.map(
                                              (rItem: any, rIndex: any) => (
                                                <div key={rIndex}>
                                                  <div className="raNameWithScores">
                                                    <p className="raTitle">{`${
                                                      rIndex + 1
                                                    }. ${rItem.title}`}</p>
                                                    <p className="dottedSvgContainer">
                                                      <HorizontalDottedLine />
                                                    </p>
                                                    <p className="raScores">{`${rItem.subTotal}/${rItem.maxTotal}`}</p>
                                                    {/* {rItem.scoreIndicatorList &&
                                                      rItem.scoreIndicatorList
                                                        .length > 0 &&
                                                      rItem.scoreIndicatorList.map(
                                                        (
                                                          iItem: any,
                                                          iIndex: any
                                                        ) => (
                                                          <div key={iIndex}>
                                                            <p>{`Indicator ${
                                                              iIndex + 1
                                                            }: ${
                                                              iItem.indicatorTitle
                                                            }`}</p>
                                                            <p>{`Response: ${iItem.response}`}</p>
                                                          </div>
                                                        )
                                                      )} */}
                                                  </div>
                                                </div>
                                              )
                                            )}

                                          <div className="paNameWithScores">
                                            <p className="ScoresTopLine">
                                              <HorizontalDottedLine />
                                            </p>
                                            <div className="scoresContent">
                                              <p className="paTitle">
                                                {
                                                  pItem?.genderLabelResponse
                                                    ?.label
                                                }
                                                <span className="paScoreTitle">
                                                  [
                                                  {
                                                    pItem?.genderLabelResponse
                                                      .value
                                                  }
                                                  ]
                                                </span>
                                              </p>
                                              <p className="paScores">{`${pItem.subTotal}/${pItem.maxTotal}`}</p>
                                            </div>
                                          </div>
                                        </Paper>
                                      </Grid>
                                    )
                                  )}
                              </Grid>
                              <h2 className="main-title">Total </h2>
                              <Grid container spacing={3}>
                                {!isScoreCardReadOnly && (
                                  <>
                                    <Grid item xs={12} sm={9}>
                                      <div className="total-section">
                                        <p>
                                          Your unit is:{" "}
                                          <span>{`${
                                            state.genderLabelResponse?.value ??
                                            "TBD"
                                          } [${
                                            state.genderLabelResponse?.label
                                          }]`}</span>
                                        </p>
                                        <p>
                                          Total Score:{" "}
                                          <span>{`${state.totalScore}/${state.maxTotalScore}`}</span>
                                        </p>
                                      </div>
                                    </Grid>
                                    <Grid item xs={12} sm={3}>
                                      <div className="submit-section">
                                        <p>
                                          <Button
                                            onClick={() =>
                                              handleSubmitScoreCard()
                                            }
                                          >
                                            Submit Score <RightArrowIcon />
                                          </Button>
                                        </p>
                                      </div>
                                    </Grid>
                                  </>
                                )}

                                {isScoreCardReadOnly && (
                                  <Grid item xs={12}>
                                    <div className="total-section">
                                      <p>
                                        Your unit is:{" "}
                                        <span>{`${
                                          state.genderLabelResponse?.value ??
                                          "TBD"
                                        } [${
                                          state.genderLabelResponse?.label
                                        }]`}</span>
                                      </p>
                                      <p>
                                        Total Score:{" "}
                                        <span>{`${state.totalScore}/${state.maxTotalScore}`}</span>
                                      </p>
                                    </div>
                                  </Grid>
                                )}
                              </Grid>
                            </div>
                          ) : (
                            <>
                              {index === selectedTab && (
                                <div className="GESDetailsContainer">
                                  <div>
                                    {tab.scoreResultAreasList &&
                                      tab.scoreResultAreasList.map(
                                        (resultArea: any, rindex: any) => (
                                          <div key={resultArea.id}>
                                            {/* Add a key to the parent element */}
                                            <h2 className="main-title">
                                              {resultArea.title}
                                            </h2>
                                            {resultArea.scoreIndicatorList &&
                                              resultArea.scoreIndicatorList.map(
                                                (
                                                  indicator: any,
                                                  indIndex: any
                                                ) => (
                                                  <Paper
                                                    key={indicator.id}
                                                    className="GESDetailsPaper"
                                                  >
                                                    <div className="raWithScores">
                                                      <h4>
                                                        {
                                                          indicator.indicatorTitle
                                                        }

                                                        {validationChecksForSubmission &&
                                                          indicatorResponseItems &&
                                                          indicatorResponseItems
                                                            .filter(
                                                              (res: any) =>
                                                                res.indicatorId ===
                                                                indicator.indicatorId
                                                            )
                                                            .map(
                                                              (item: any) =>
                                                                item.response ===
                                                                  null && (
                                                                  <span
                                                                    key={
                                                                      item.id
                                                                    }
                                                                    className="response-error-message"
                                                                  >
                                                                    <WarningIcon />{" "}
                                                                    Mandatory to
                                                                    answer
                                                                  </span>
                                                                )
                                                            )}
                                                      </h4>
                                                      <div className="response-section">
                                                        <div className="prev-response">
                                                          <p>
                                                            [
                                                            {`${
                                                              +scoreYear - 1
                                                            } SCORE: ${
                                                              (
                                                                previousYearScoresList.find(
                                                                  (ps: any) =>
                                                                    ps.indicatorId ===
                                                                    indicator.indicatorId
                                                                ) || {}
                                                              ).response ||
                                                              "N/A"
                                                            }`}
                                                            ]
                                                          </p>
                                                        </div>
                                                        <div className="current-response">
                                                          <p>
                                                            Your Current
                                                            Response :
                                                          </p>
                                                          <div
                                                            className={`radio-container ${
                                                              isScoreCardReadOnly
                                                                ? "readOnly"
                                                                : ""
                                                            }`}
                                                          >
                                                            <RadioGroup
                                                              aria-label="response-options"
                                                              name="response-options"
                                                              value={
                                                                indicator.response !=
                                                                null
                                                                  ? indicator.response
                                                                  : "" || ""
                                                              }
                                                              onChange={(
                                                                event
                                                              ) =>
                                                                handleRadioButtonChange(
                                                                  event,
                                                                  indicator.indicatorId,
                                                                  resultArea.id
                                                                )
                                                              }
                                                              row
                                                            >
                                                              {GESRadioOptionsList.map(
                                                                (option) => (
                                                                  <FormControlLabel
                                                                    key={option}
                                                                    value={option.toString()}
                                                                    control={
                                                                      <Radio
                                                                        color="primary"
                                                                        icon={
                                                                          <RadioUncheckedIcon />
                                                                        }
                                                                        checkedIcon={
                                                                          <RadioCheckedIcon />
                                                                        }
                                                                      />
                                                                    }
                                                                    label={option.toString()}
                                                                  />
                                                                )
                                                              )}
                                                            </RadioGroup>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                    <div
                                                      className={`accordion-container ${
                                                        isScoreCardReadOnly
                                                          ? "readOnly"
                                                          : ""
                                                      }`}
                                                    >
                                                      <Accordion
                                                        expanded={
                                                          !!expandedState[
                                                            index
                                                          ]?.[resultArea.id]?.[
                                                            indicator.id
                                                          ]?.expanded
                                                        }
                                                        onChange={() =>
                                                          handleAccordionChange(
                                                            index,
                                                            resultArea.id,
                                                            indicator.id
                                                          )
                                                        }
                                                        id={`accordion-${index}-${resultArea.resultAreaId}-${indicator.id}`}
                                                        className="indicator-accordion"
                                                        key={`accordion-${index}-${resultArea.resultAreaId}-${indicator.id}`}
                                                      >
                                                        <AccordionSummary
                                                          expandIcon={
                                                            <ExpandMoreIcon />
                                                          }
                                                          aria-controls={`panel-${index}-${resultArea.resultAreaId}-${indicator.indicatorId}-content`}
                                                          id={`panel-${index}-${resultArea.id}-${indicator.indicatorId}-header`}
                                                          key={`accordion-summary-${index}-${resultArea.resultAreaId}-${indicator.id}`}
                                                        >
                                                          <div>
                                                            <p className="indicator-desc-titles">
                                                              {accordionTitles[
                                                                `tab-${index}-accordion-${resultArea.id}-${indicator.indicatorId}`
                                                              ] ||
                                                                // `*Click Here to view response Descriptions for Result Area ${resultArea.id} - score Indicator ${indicator.id}*`}
                                                                `*Click Here to view response Descriptions*`}
                                                            </p>

                                                            {/* Render the selected option outside of AccordionDetails */}
                                                            {!expandedState[
                                                              index
                                                            ]?.[
                                                              resultArea.id
                                                            ]?.[indicator.id]
                                                              ?.expanded &&
                                                              GESItems.filter(
                                                                (indDes: any) =>
                                                                  indDes.indicatorId ===
                                                                  indicator.indicatorId
                                                              ).flatMap(
                                                                (
                                                                  matchingIndDes: any
                                                                ) =>
                                                                  matchingIndDes.unitIndicatorDescriptionList.map(
                                                                    (
                                                                      descriptionItem: any
                                                                    ) =>
                                                                      descriptionItem.response ===
                                                                        indicator.response && (
                                                                        <p
                                                                          key={
                                                                            descriptionItem.id
                                                                          } // Assuming there is a unique ID for each descriptionItem
                                                                          className="selected"
                                                                        >
                                                                          {
                                                                            descriptionItem.response
                                                                          }{" "}
                                                                          -{" "}
                                                                          {
                                                                            descriptionItem.description
                                                                          }
                                                                        </p>
                                                                      )
                                                                  )
                                                              )}
                                                          </div>
                                                        </AccordionSummary>
                                                        <AccordionDetails>
                                                          {GESItems && (
                                                            <>
                                                              {GESItems.filter(
                                                                (indDes: any) =>
                                                                  indDes.indicatorId ===
                                                                  indicator.indicatorId
                                                              ).map(
                                                                (
                                                                  matchingIndDes: any
                                                                ) =>
                                                                  matchingIndDes.unitIndicatorDescriptionList.map(
                                                                    (
                                                                      descriptionItem: any,
                                                                      index: number
                                                                    ) => (
                                                                      <p
                                                                        key={
                                                                          index
                                                                        }
                                                                        className={
                                                                          descriptionItem.response ===
                                                                          indicator.response
                                                                            ? "selected"
                                                                            : ""
                                                                        }
                                                                      >
                                                                        {
                                                                          descriptionItem.response
                                                                        }{" "}
                                                                        -{" "}
                                                                        {
                                                                          descriptionItem.description
                                                                        }
                                                                      </p>
                                                                    )
                                                                  )
                                                              )}
                                                            </>
                                                          )}
                                                        </AccordionDetails>
                                                      </Accordion>
                                                    </div>
                                                    {indicatorResponseItems &&
                                                      indicatorResponseItems
                                                        .filter(
                                                          (res: any) =>
                                                            res.indicatorId ===
                                                            indicator.indicatorId
                                                        )
                                                        .map((item) => (
                                                          <Grid
                                                            item
                                                            xs={12}
                                                            sm={12}
                                                          >
                                                            {!isScoreCardReadOnly && (
                                                              <div
                                                                className="comments-section"
                                                                style={{
                                                                  display:
                                                                    "flex",
                                                                  justifyContent:
                                                                    "space-between",
                                                                  alignItems:
                                                                    "center",
                                                                }}
                                                              >
                                                                <p
                                                                  className="commentsTitle"
                                                                  onClick={() => {
                                                                    toggleCommentVisibility(
                                                                      indicator.indicatorId,
                                                                      indicator.response?.toString()
                                                                    );
                                                                    setIsCommentBoxOpen(
                                                                      true
                                                                    );
                                                                  }}
                                                                  style={{
                                                                    cursor:
                                                                      "pointer",
                                                                  }}
                                                                >
                                                                  <Chat />{" "}
                                                                  {comments[
                                                                    indicator
                                                                      .indicatorId
                                                                  ]
                                                                    ?.isVisible &&
                                                                  isCommentBoxOpen
                                                                    ? "Comment: "
                                                                    : item.comments
                                                                    ? "View Comment"
                                                                    : "Add Comment"}
                                                                </p>

                                                                {comments[
                                                                  indicator
                                                                    .indicatorId
                                                                ]?.isVisible &&
                                                                  item.comments && (
                                                                    <>
                                                                      <p
                                                                        className={`deleteCommentTitle`}
                                                                        onClick={() =>
                                                                          handleDeleteClick(
                                                                            indicator.indicatorId,
                                                                            indicator.response?.toString()
                                                                          )
                                                                        }
                                                                      >
                                                                        Delete
                                                                        Comment
                                                                      </p>
                                                                    </>
                                                                  )}
                                                              </div>
                                                            )}

                                                            {comments[
                                                              indicator
                                                                .indicatorId
                                                            ]?.isVisible &&
                                                              !isScoreCardReadOnly &&
                                                              isCommentBoxOpen && (
                                                                <Grid
                                                                  item
                                                                  xs={12}
                                                                  sm={12}
                                                                >
                                                                  <TextAreaInput
                                                                    inputRef={
                                                                      textBoxRef
                                                                    }
                                                                    value={
                                                                      item.comments ||
                                                                      ""
                                                                    }
                                                                    minHeight={
                                                                      50
                                                                    }
                                                                    stylingMode="outlined"
                                                                    label="comment"
                                                                    // focused={
                                                                    //   comments[
                                                                    //     indicator
                                                                    //       .indicatorId
                                                                    //   ]
                                                                    //     ?.isVisible &&
                                                                    //   isCommentBoxOpen
                                                                    // }
                                                                    className={`textBox-${index}-${resultArea.id}-${indicator.indicatorId}-input`}
                                                                    onValueChanged={(
                                                                      e: any
                                                                    ) =>
                                                                      _textAreaChanged(
                                                                        e,
                                                                        indicator.indicatorId,
                                                                        indicator.response?.toString()
                                                                      )
                                                                    }
                                                                    onKeyUp={(
                                                                      e: any
                                                                    ) =>
                                                                      _textAreaChanged(
                                                                        e,
                                                                        indicator.indicatorId,
                                                                        indicator.response?.toString()
                                                                      )
                                                                    }
                                                                  />
                                                                </Grid>
                                                              )}

                                                            {isScoreCardReadOnly &&
                                                              item.comments && (
                                                                <Grid
                                                                  item
                                                                  xs={12}
                                                                  sm={12}
                                                                >
                                                                  <div
                                                                    className="comments-section"
                                                                    style={{
                                                                      display:
                                                                        "flex",
                                                                      justifyContent:
                                                                        "space-between",
                                                                      alignItems:
                                                                        "center",
                                                                    }}
                                                                  >
                                                                    <p
                                                                      className="commentsTitle"
                                                                      onClick={() =>
                                                                        toggleCommentVisibility(
                                                                          indicator.indicatorId,
                                                                          indicator.response?.toString()
                                                                        )
                                                                      }
                                                                      style={{
                                                                        cursor:
                                                                          "pointer",
                                                                      }}
                                                                    >
                                                                      <Chat />{" "}
                                                                      {comments[
                                                                        indicator
                                                                          .indicatorId
                                                                      ]
                                                                        ?.isVisible
                                                                        ? "Comment: "
                                                                        : "View Comment"}
                                                                    </p>
                                                                  </div>

                                                                  {item.comments &&
                                                                    comments[
                                                                      indicator
                                                                        .indicatorId
                                                                    ]
                                                                      ?.isVisible && (
                                                                      <Grid
                                                                        item
                                                                        xs={12}
                                                                        sm={12}
                                                                      >
                                                                        <TextAreaInput
                                                                          value={
                                                                            item.comments ||
                                                                            ""
                                                                          }
                                                                          stylingMode="outlined"
                                                                          label="comment"
                                                                          readOnly={
                                                                            true
                                                                          }
                                                                          minHeight={
                                                                            50
                                                                          }
                                                                          onValueChanged={() => {}}
                                                                        />
                                                                      </Grid>
                                                                    )}
                                                                </Grid>
                                                              )}
                                                          </Grid>
                                                        ))}
                                                  </Paper>
                                                )
                                              )}
                                          </div>
                                        )
                                      )}
                                  </div>
                                </div>
                              )}
                            </>
                          )}
                        </TabPanel>
                      ))}
                    </Grid>
                  </Grid>
                </div>

                {showActionButtons &&
                  !(selectedTab === tabsWithSummary.length - 1) && (
                    <div className="gesButtonContainer floatingButtons">
                      <Grid container spacing={3}>
                        <Grid item xs={12}>
                          <div className="navigate-section">
                            {/* <Grid
                              container
                              alignItems="center"
                              justifyContent="space-between"
                            > */}
                            {/* Left Buttons with 1/4th width */}
                            <Grid container item xs={6} sm={3} spacing={1}>
                              <Grid item xs={6}>
                                <div className="buttonsLHS">
                                  <Button
                                    className="primary"
                                    onClick={goToPreviousTab}
                                    disabled={selectedTab === 0}
                                  >
                                    Previous
                                  </Button>

                                  <Button
                                    className="primary"
                                    onClick={goToNextTab}
                                    disabled={
                                      disableSummarySection
                                        ? selectedTab ===
                                          tabsWithSummary.length - 2
                                        : selectedTab ===
                                          tabsWithSummary.length - 1
                                    }
                                  >
                                    Next
                                  </Button>
                                </div>
                              </Grid>
                            </Grid>

                            {!isScoreCardReadOnly && (
                              <Grid container item xs={6} sm={6} spacing={1}>
                                <Grid item style={{ marginLeft: "auto" }}>
                                  <div className="buttonsRHS">
                                    <Button
                                      className="secondary"
                                      onClick={handleResetScoreCard}
                                    >
                                      Cancel
                                    </Button>
                                    <Button
                                      className="primary"
                                      onClick={() => saveDraftData(false)}
                                      disabled={!isDratftEnabled}
                                    >
                                      Save Draft
                                    </Button>
                                    <Button
                                      className="primary"
                                      onClick={goToSummaryTab}
                                      disabled={
                                        selectedTab ===
                                          tabsWithSummary.length - 1 ||
                                        disableSummarySection
                                      }
                                    >
                                      Go to Summary
                                    </Button>
                                  </div>
                                </Grid>
                              </Grid>
                            )}

                            {isScoreCardReadOnly && (
                              <Grid container item xs={6} sm={6} spacing={1}>
                                <Grid
                                  item
                                  xs={6}
                                  marginLeft="auto"
                                  className="singleButton"
                                >
                                  <div className="buttonsRHS">
                                    <Button
                                      className="primary"
                                      onClick={goToSummaryTab}
                                      disabled={
                                        selectedTab ===
                                          tabsWithSummary.length - 1 ||
                                        disableSummarySection
                                      }
                                      fullWidth
                                    >
                                      Go to Summary
                                    </Button>
                                  </div>
                                </Grid>
                              </Grid>
                            )}
                            {/* </Grid> */}
                          </div>
                        </Grid>
                      </Grid>
                    </div>
                  )}
              </div>
            </>
          ) : (
            <div className="no-results">
              <h2 className="main-title">No Results Found</h2>
            </div>
          )}
        </>
      ) : (
        <div className="loader-container">
          <Spinner size="large" label="Loading ..." />
        </div>
      )}
    </>
  );
};

export default GRTGES;
