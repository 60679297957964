import global from "./global";
import { loginRequest, msalConfig } from "./authConfig";
import * as msal from "@azure/msal-browser";
import config from "./config";
import { pca } from "../App";

// const hardcodedToken =
//   "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsIng1dCI6IlQxU3QtZExUdnlXUmd4Ql82NzZ1OGtyWFMtSSIsImtpZCI6IlQxU3QtZExUdnlXUmd4Ql82NzZ1OGtyWFMtSSJ9.eyJhdWQiOiI2NjY2MmYwZS0zZGViLTQ5MzctOGExNC0wNjU5MDlmMTllNTgiLCJpc3MiOiJodHRwczovL3N0cy53aW5kb3dzLm5ldC80Njk3ZDJmMy1kM2I3LTRiZDAtOGJjNi1lMjQzZjRmNzFkN2MvIiwiaWF0IjoxNzAxMzAyMzAxLCJuYmYiOjE3MDEzMDIzMDEsImV4cCI6MTcwMTMwNjIwMSwiYWlvIjoiRTJWZ1lJZzRFK0E0dHk1NlJYSkNnNjFMdW1nM0FBPT0iLCJhcHBpZCI6IjY2NjYyZjBlLTNkZWItNDkzNy04YTE0LTA2NTkwOWYxOWU1OCIsImFwcGlkYWNyIjoiMSIsImlkcCI6Imh0dHBzOi8vc3RzLndpbmRvd3MubmV0LzQ2OTdkMmYzLWQzYjctNGJkMC04YmM2LWUyNDNmNGY3MWQ3Yy8iLCJvaWQiOiJiNWMxZTQ0ZC05YjQyLTQ1OWYtOWFiNS00OGUyYWViNmYzZWYiLCJyaCI6IjAuQVdZQTg5S1hScmZUMEV1THh1SkQ5UGNkZkE0dlptYnJQVGRKaWhRR1dRbnhubGhtQUFBLiIsInN1YiI6ImI1YzFlNDRkLTliNDItNDU5Zi05YWI1LTQ4ZTJhZWI2ZjNlZiIsInRpZCI6IjQ2OTdkMmYzLWQzYjctNGJkMC04YmM2LWUyNDNmNGY3MWQ3YyIsInV0aSI6IjdEdVhOWGJTR1VpSVRHbl9RS01lQVEiLCJ2ZXIiOiIxLjAifQ.cXZzSeXMbRkhaNeGfve8-RCFUkR3BaMLCvvbJNA4viTElyiM4xTm2cJGdO0iBPFUHRUk9bK-5ppWPHj3SayoHiOI8lSR-8hC5BGk887Xb9T2Nf8AfoJQG2tEAmTR4NOQqKoAH_WBv5-fIQlKZMwxn7OVhdluTisU9Wt6Ymt_DUB_NJCMAzSGnyBd62a0v4oG7skZtRDXzd9F8JSJdObSveb1qVErRynqiastfaY32OEgfyxWP9dNz8XMAdao_WfKfnTn_2CPhD7qaC8mO8x1dGu8sI7Tih5jiOeCjW6gbeTtRDn3C0V9kvrIct5W8Wbcmka3GiABpx_ZdoVOFE1JEg";

let utility = {

  async getTokenUsingMsal() {
    let currentUserEmail = localStorage.getItem("currentDetail");

    try {
        const storagedata = localStorage.getItem(global.accessToken);
        if (storagedata !== null) {
            const data = JSON.parse(storagedata);
            const currentDateTime = new Date();
            const endDate = data.idTokenClaims.exp;

            let expiredate = new Date(0);
            expiredate.setUTCSeconds(endDate);
            expiredate = new Date(expiredate.getTime() - 30 * 60000);

            if (currentDateTime.getTime() >= expiredate.getTime()) {
                return await utility.tokenAPI(currentUserEmail);
            } else {
                return data;
            }
        } else {
            return await utility.tokenAPI(currentUserEmail);
        }
    } catch (error) {
        console.error("Error getting token:", error);
        throw error;
    }
  },

  async tokenAPI(currentUserEmail: any) {
    try {
      // const msalInstance = new msal.PublicClientApplication(msalConfig);
      // msalInstance.initialize();
      const msalInstance = pca;
      const accounts = msalInstance.getAllAccounts();

      let ssoRequest = {
        scopes: [global.AzureADScope],
        loginHint: currentUserEmail,
      };
      let tokenRequest = {
        scopes: [global.AzureADScope],
      };

      if (accounts.length > 0) {
        msalInstance.setActiveAccount(accounts[0]);
      }

      let currentAccount = msalInstance.getActiveAccount();
      if (!currentAccount) {
        currentAccount = msalInstance.getAccount(currentUserEmail);
      }

      if (currentAccount) {
        const response: any = await msalInstance.acquireTokenSilent({
          ...loginRequest,
          account: currentAccount,
        });

        localStorage.setItem(global.accessToken, JSON.stringify(response));
        localStorage.setItem("currentUserEmail", response.account.username);

        const data: any = utility.genericGetAPICall(
          `${config.getUserByEmail}/${response.account.username}`
        );
        if (data && data.email === response.account.username) {
          localStorage.setItem("currentUser", JSON.stringify(data));
        }
        return response;
      } else {
        try {
          const response = await msalInstance.ssoSilent(ssoRequest);
          localStorage.setItem(global.accessToken, JSON.stringify(response));
          return response;
        } catch {
          const response = await msalInstance.acquireTokenRedirect(tokenRequest);
          localStorage.setItem(global.accessToken, JSON.stringify(response));
          return response;
        }
      }
    } catch (error) {
      console.error("Error getting token:", error);
      throw error;
    }
  },

  async genericGetAPICall(apiname: string) {
    let token: any = await utility.getTokenUsingMsal();
    try {
        const result = await fetch(`${global.spAPIURL}/${apiname}`, {
            method: "GET",
            // mode: "no-cors",
            headers: new Headers({
                authorization: `Bearer ${token.accessToken}`,
            }),
        });

        if (result.ok) {
            return result.json();
        } else {
            throw new Error("Network response was not ok.");
        }
    } catch (error) {
        console.error(error);
        throw error;
    }
  },

  async genericGetDataByIdAPICall(apiname: string, id: any) {
    // console.log("Before promise " + new Date());
    try {
        const token: any = await utility.getTokenUsingMsal();
        const result = await fetch(`${global.spAPIURL}/${apiname}/${id}`, {
            method: "GET",
            headers: new Headers({
                authorization: `Bearer ${token.accessToken}`,
            }),
        });

        if (result.ok) {
            return result.json();
        } else {
            throw new Error("Network response was not ok.");
        }
    } catch (error) {
        console.error(error);
        throw error;
    }
  },

  async genericPostAPIcall(apiName: string, data: any) {
    let token: any = await utility.getTokenUsingMsal();
    if (token != null) {
        try {
            let result = await fetch(`${global.spAPIURL}/${apiName}`, {
                method: "POST",
                body: JSON.stringify(data),
                // mode: "no-cors",
                headers: new Headers({
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token.accessToken}`,
                    credentials: "include",
                }),
            });

            if (result.ok) {
                return result.json();
            } else {
                let errorText = await result.text();
                throw new Error(errorText);
            }
        } catch (error) {
            console.error(error);
            throw error;
        }
    } else {
        throw new Error("Token is null.");
    }
  },

  async genericDeleteAPICall(apiname: string, data: any) {
    try {
        const token: any = await utility.getTokenUsingMsal();

        if (token != null) {
            const result = await fetch(`${global.spAPIURL}/${apiname}`, {
                method: "DELETE",
                body: JSON.stringify(data),
                headers: new Headers({
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token.accessToken}`,
                    // credentials: "include",
                    // "Access-Control-Allow-Origin": "*",
                }),
            });

            if (result.ok) {
                return await result.json();
            } else {
                const errorText = await result.text();
                throw new Error(errorText);
            }
        }
    } catch (error) {
        console.error(error);
        throw error;
    }
  },

  async genericPutByIdAPICall(apiname: string, data: any) {
    try {
        const token: any = await utility.getTokenUsingMsal();

        if (token != null) {
            const result = await fetch(`${global.spAPIURL}/${apiname}`, {
                method: "PUT",
                body: JSON.stringify(data),
                headers: new Headers({
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token.accessToken}`,
                    credentials: "include",
                    // "Access-Control-Allow-Origin": "*",
                }),
            });

            if (result.ok) {
                return await result.json();
            } else {
                const errorResponse = await result.json();
                throw new Error(errorResponse.message || "Network response was not ok.");
            }
        }
    } catch (error) {
        console.error(error);
        throw error;
    }
  },


  _sortItems: (items: any[], sortBy: string, descending = false): any[] => {
    if (descending) {
      return items.sort((a: any, b: any) => {
        if (a[sortBy] < b[sortBy]) {
          return 1;
        }
        if (a[sortBy] > b[sortBy]) {
          return -1;
        }

        return 0;
      });
    } else {
      return items.sort((a: any, b: any) => {
        if (a[sortBy] < b[sortBy]) {
          return -1;
        }
        if (a[sortBy] > b[sortBy]) {
          return 1;
        }

        return 0;
      });
    }
  },

  groupBy(xs: any, key: any) {
    if (xs && xs.length > 0) {
      return xs.reduce(function (rv: any, x: any) {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
      }, {});
    } else {
      return false;
    }
  },
};

export default utility;
