import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import utility from "../../classes/utility";
import Config from "../../classes/config";
import ViewMoreDialog from "../Common/ReusableComponents/ViewMore";
import DataTable from "../Common/ReusableComponents/DataTable";
import Spinner from "../Common/ReusableComponents/Spinner";

const AdminDueDates = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const [items, setItems] = useState<any[]>([]);
  const [showMoreAlert, setShowMoreAlert] = React.useState(false);
  const [moreItem, setMoreItem] = React.useState([] as any);
  const [morealertMessage, setMorealertMessage] = React.useState("");

  const columns = [
    {
      field: "id",
      title: "ID",
      type: "string",
      allowHiding: false,
    },
    {
      field: "year",
      title: "Year",
      type: "html",
      allowHiding: false,
    },
    {
      field: "due_Date",
      title: "Due Date",
      type: "date",
      allowHiding: false,
    },
    {
      field: "createdBy",
      title: "CreatedBy",
      type: "string",
      allowHiding: true,
    },
    {
      field: "updatedBy",
      title: "UpdatedBy",
      type: "string",
      allowHiding: false,
    },
  ];

  useEffect(() => {
    didMount();
  }, []);

  const didMount = () => {
    setLoading(true);
    getUnitData();
  };

  const getUnitData = async () => {
    try {
      const data = await utility.genericGetAPICall(`${Config.duedate}`);
      let _items: any = [];
      let result = data.filter((x: any) => x.isDeleted === false);
      for (let r of result) {
        const yearHtml = `<p>${r.year}</p><p class='subText'></p>`;
        _items.push({
          id: r.id,
          year: yearHtml,
          due_Date: r.due_Date,
          updatedBy: r.updatedBy,
          updatedOn: r.updatedOn,
          createdBy: r.createdBy,
          createdOn: r.createdOn,
        });
      }
      _items = utility._sortItems(_items, "id", false);
  
      setItems(_items);
      setLoading(false);
    } catch (err) {
      console.error(err);
      setLoading(false);
    }
  };

  const handleTableActionsEdit = (action: any) => {
    navigate(`/AdminDueDateAddEdit?ID=${action.id}`);
  };

  const handleTableActionsDelete = (action: any) => {
    navigate(`/AdminDueDateAddEdit?ID=${action}`);
  };

  // const subMenu = [
  //   {
  //     name: "Edit",
  //     link: "/AdminDueDateAddEdit?ID=" + moreItem.id,
  //   },
  //   {
  //     name: "Delete",
  //     link: "/AdminDueDateAddEdit?ID=" + moreItem.id,
  //   },
  // ];

  // const OnViewMoreClose = () => {
  //   setMorealertMessage("");
  //   setShowMoreAlert(false);
  // };

  // const MoreItemDialog = (item: any) => {
  //   setShowMoreAlert(true);
  //   setMoreItem(item);
  // };

  // const MoreItemPopUp = () => {
  //   return (
  //     <ViewMoreDialog
  //       showDialog={showMoreAlert}
  //       message={morealertMessage}
  //       title={""}
  //       onClose={() => OnViewMoreClose()}
  //       menuItems={subMenu}
  //     />
  //   );
  // };

  return (
    <div className="">
      {/* {showMoreAlert && MoreItemPopUp()} */}
      {!loading ? (
        <div className="adminUser-container dueDates">
          <DataTable
            style={{ width: "100%" }}
            filterable={false}
            groupable={false}
            rows={items}
            columns={columns}
            hideSearchPanel={true}
            fileName={"DueDates"}
            showHideColumnMenu={false}
            hideExport={true}
            // moreItem={(a: any) => MoreItemDialog(a)}
            deleteItem={(a: any) => handleTableActionsDelete(a)}
            editItem={(a: any) => handleTableActionsEdit(a)}
            showCommandBtn={false}
            showMoreBtn={true}
            hideCommandViewBtn={true}
          />
        </div>
      ) : (
        <Spinner size="large" />
      )}
    </div>
  );
};

export default AdminDueDates;
