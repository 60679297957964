import React, { useState, useEffect, useRef } from "react";
import DataTable from "../Common/ReusableComponents/DataTable";
import Spinner from "../Common/ReusableComponents/Spinner";
import utility from "../../classes/utility";
import Config from "../../classes/config";
import ExportLogo from "../../images/Export.svg";

import Grid from "@mui/material/Grid";
import SelectBoxInput from "../Common/ReusableComponents/SelectInputBox";
import SelectBox from "devextreme-react/select-box";
import DateBox from "devextreme-react/date-box";
import { Workbook } from "exceljs";
import { saveAs } from "file-saver-es";
import { exportDataGrid } from "devextreme/excel_exporter";

interface HomeProps {
  roleId: number | null;
  unitId: number | null;
  currentYearRecordExists: boolean;
}

const GRTReports = (props: HomeProps) => {
  const [items, setItems] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [status, setStatus] = useState<any>({});
  const [statusItems, setStatusItems] = useState<any[]>([]);
  const [user, setUser] = useState<any>({});
  const [userItems, setUserItems] = useState<any[]>([]);
  const [reportFrom, setReportFrom] = useState<any>(null);
  const [reportTo, setReportTo] = useState<any>(null);
  // const [reportBy, setReportBy] = useState<string>("");
  const dataGridRef = useRef(null);

  const columns = [
    {
      field: "idWithYear",
      title: "Scorecard No./Year",
      // width: 80,
      type: "html",
      allowHiding: false,
    },

    {
      field: "updatedBy",
      title: "Updated By",
      // width: 160,
      type: "string",
      allowHiding: true,
    },
    {
      field: "policyAndPrioritySetting",
      title: "Policy And Priority Setting",
      // width: 160,
      type: "html",
      allowHiding: false,
    },
    {
      field: "genderRepresentation",
      title: "Gender Representation",
      // width: 160,
      type: "html",
      allowHiding: false,
    },
    {
      field: "capacityCompetence",
      title: "Capacity & Competence",
      // width: 160,
      type: "html",
      allowHiding: false,
    },
    {
      field: "partnershipsCommunications",
      title: "Partnerships & Communications",
      // width: 160,
      type: "html",
      allowHiding: false,
    },
    {
      field: "resultsAccountabilityFramework",
      title: "Results & Accountability Framework",
      // width: 160,
      type: "html",
      allowHiding: false,
    },
    {
      field: "leadershipOrganisationalCulture",
      title: "Leadership & Organisational Culture",
      // width: 160,
      type: "html",
      allowHiding: false,
    },
    {
      field: "totalScore",
      title: "TotalScore",
      // width: 160,
      type: "html",
      allowHiding: false,
    },
    {
      field: "status",
      title: "Status",
      // width: 160,
      type: "html",
      allowHiding: false,
    },
  ];

  useEffect(() => {
    setLoading(true);
    const statusItems = getStatusData();
    getUsersData();
    getScoreData(props?.unitId, 0, 0, 0, 0, 0, statusItems);
  }, []);

  const getScoreData = async(
    unitId: any,
    statusId: number,
    yearFrom: number,
    yearTo: number,
    scoreId: number,
    userId: number,
    statusItems: any
  ) => {
    let dataToPassToService: any = {};
    dataToPassToService.unitId = unitId;
    dataToPassToService.statusId = statusId;
    dataToPassToService.yearFrom = yearFrom;
    dataToPassToService.yearTo = yearTo;
    dataToPassToService.scoreId = scoreId;
    dataToPassToService.userId = userId;

    try{
    const data:any = utility
      .genericPostAPIcall(
        `${Config.searchScores}?showAll=true&pageSize=-1&pageNumber=-1`,
        dataToPassToService
      );
          const activeRecords = data?.filter((r: any) => r.isDeleted === false);
          let _items: any = [];
          if (activeRecords || activeRecords.length > 0) {
            for (let r of activeRecords) {
              let score: any = {};
              let combinedIdYear = `<p class='combinedYear'><a href='#/GRTGES?year=${r.year}&scoreId=${r.id}'>${r.id}</a></p><p>/${r.year}</p>`;
              let statusColor = "blue",
                statusHtml = "";

              if (statusItems && statusItems.length > 0) {
                statusColor = statusItems.filter(
                  (s: any) => s.id === r.statusId
                )[0]?.color;
              }
              statusHtml = `<Button style="background:${statusColor}; border:2px solid ${statusColor}" class="datatable dummyButtons">${r.status}</Button>`;

              let totalScoreHtml = `
                <p>${r.genderLabelResponse?.label} [${r.genderLabelResponse?.value}]</p>
                <p class='subText'>${r.totalScore}/${r.maxTotalScore}</p>`;
              score = {
                id: r.id,
                idWithYear: combinedIdYear,
                totalScore: totalScoreHtml,
                status: statusHtml,
                updatedBy: r.updatedByFullName,
                updatedOn: r.updatedOn,
                createdBy: r.createdBy,
                createdOn: r.createdOn,
                policyAndPrioritySetting: 0,
                genderRepresentation: 0,
                capacityCompetence: 0,
                partnershipsCommunications: 0,
                resultsAccountabilityFramework: 0,
                leadershipOrganisationalCulture: 0,
              };

              let scorePAList: any = [];
              scorePAList = r.scorePriorityAreasList;

              for (let p of scorePAList) {
                let scorehtml = `
                <p>${p.genderLabelResponse?.label} [${p.genderLabelResponse?.value}]</p>
                <p class='subText'>${p.subTotal}/${p.maxTotal}</p>`;

                switch (p.title) {
                  case "Policy & Priority Setting":
                    score.policyAndPrioritySetting = scorehtml;
                    break;

                  case "Gender Representation":
                    score.genderRepresentation = scorehtml;
                    break;

                  case "Capacity & Competence":
                    score.capacityCompetence = scorehtml;
                    break;

                  case "Partnerships & Communications":
                    score.partnershipsCommunications = scorehtml;
                    break;

                  case "Results & Accountability Framework":
                    score.resultsAccountabilityFramework = scorehtml;
                    break;

                  case "Leadership & Organisational Culture":
                    score.leadershipOrganisationalCulture = scorehtml;
                    break;

                  default:
                    break;
                }
              }
              _items.push(score);
            }
          }
          _items = utility._sortItems(_items, "id", true);

          setItems(_items);
          //setCallAfterInit(true);
          setLoading(false);
        }catch (err: any) {
          setLoading(false);
        }
  };

  const getStatusData = async () => {
    try {
      const data: any = await utility.genericGetAPICall(
        `${Config.statusList}?pageSize=-1&pageNumber=-1&showAll=true&previousId=-1`
      );

      let _statusItems = data.map((r: any) => ({
        id: r.id,
        title: r.title,
        color: r.color,
        updatedBy: r.updatedBy,
        updatedOn: r.updatedOn,
        createdBy: r.createdBy,
        createdOn: r.createdOn,
        isDeleted: r.isDeleted,
      }));

      setStatusItems(_statusItems);

      return _statusItems;
    } catch (err) {
      console.error("Error fetching status data:", err);
      throw err;
    }
  };

  const getUsersData = async () => {
    try{
    const data:any = await utility
      .genericGetAPICall(
        `${Config.users}?pageSize=-1&pageNumber=-1&showAll=true&previousId=-1`
      );
          let _userItems: any = [];
          for (let r of data) {
            _userItems.push({
              id: r.id,
              fullName: r.fullName,
              updatedBy: r.fullName,
              updatedOn: r.updatedOn,
              createdBy: r.createdBy,
              createdOn: r.createdOn,
              isDeleted: r.isDeleted,
            });
          }
          setUserItems(_userItems);
        }catch (err: any) {
          console.error(err);
        }
  };

  const searchResults = () => {
    setLoading(true);

    let unit = props.unitId ? props.unitId : 0;
    let yearFrom = reportFrom ? reportFrom.getFullYear() : 0;
    let yearTo = reportTo ? reportTo.getFullYear() : 0;
    let selectedUser = user && user.id ? user.id : 0;

    getScoreData(unit, status?.id, yearFrom, yearTo, 0, selectedUser, statusItems);
  };

  const handleExportButtonClick = async () => {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet("Main sheet");

    if (dataGridRef.current) {
      try {
        await exportDataGrid({
        component: (dataGridRef.current as any).instance,
        worksheet: worksheet,
      });
      const buffer = await workbook.xlsx.writeBuffer();
          saveAs(
            new Blob([buffer], { type: "application/octet-stream" }),
            "GRTReports.xlsx"
          );
        } catch (error) {
          console.error("Error exporting data:", error);
        }
      }
    };

  return (
    <div className="">
      <div className="search-container">
        <Grid container>
          <Grid item container xs={12} sm={12} md={9} spacing={1}>
            <Grid item xs={12} sm={6} md={3}>
              {/* <SelectBoxInput
                stylingMode="outlined"
                displayExpr="title"
                valueExpr="id"
                showClearButton={true}
                label="Status"
                items={statusItems}
                selectedItem={status}
                onSelectionChanged={(e: any) => setStatus(e.selectedItem)}
              /> */}
              <SelectBox
                  stylingMode="outlined"
                  showClearButton={true}
                  label="Status"
                  valueExpr="id"
                  displayExpr="title"
                  dataSource={statusItems}
                  selectedItem={status}
                  defaultValue={status}
                  onSelectionChanged={(e: any) => setStatus(e.selectedItem)}
                />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <DateBox
                stylingMode="outlined"
                label="From"
                type="date"
                value={reportFrom}
                pickerType="calendar"
                onValueChanged={(e: any) => setReportFrom(e.value)}
                calendarOptions={{
                  zoomLevel: "decade",
                  minZoomLevel: "decade",
                  maxZoomLevel: "decade",
                }}
                displayFormat={"yyyy"}
                showClearButton={true}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <DateBox
                stylingMode="outlined"
                label="To"
                type="date"
                value={reportTo}
                pickerType="calendar"
                onValueChanged={(e: any) => setReportTo(e.value)}
                calendarOptions={{
                  zoomLevel: "decade",
                  minZoomLevel: "decade",
                  maxZoomLevel: "decade",
                }}
                displayFormat={"yyyy"}
                showClearButton={true}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              {/* <SelectBoxInput
                stylingMode="outlined"
                displayExpr="fullName"
                valueExpr="id"
                showClearButton={true}
                label="By"
                items={userItems}
                selectedItem={user}
                onSelectionChanged={(e: any) => setUser(e.selectedItem)}
              /> */}
              <SelectBox
                  stylingMode="outlined"
                  showClearButton={true}
                  label="By"
                  valueExpr="id"
                  displayExpr="fullName"
                  dataSource={userItems}
                  selectedItem={user}
                  defaultValue={user}
                  onSelectionChanged={(e: any) => setUser(e.selectedItem)}
                />
            </Grid>
          </Grid>
          {/* <div className="filter-buttons"> */}
          <Grid
            item
            xs={12}
            md={3}
            container
            spacing={1}
            className="filter-buttons"
          >
            <Grid>
              <button className="btn btn-primary" onClick={searchResults}>
                Search
              </button>
            </Grid>
            <Grid>
              <button
                className="btn btn-export"
                onClick={handleExportButtonClick}
              >
                <img src={ExportLogo} alt="" />
                Export
              </button>
            </Grid>
          </Grid>
          {/* </div> */}
        </Grid>
      </div>

      {!loading ? (
        <div className="reports-container">
          <DataTable
            style={{ width: "100%" }}
            filterable={false}
            groupable={false}
            rows={items}
            columns={columns}
            fileName={"GRTReports"}
            columnChooser={"GRTReportColumns"}
            showHideColumnMenu={false}
            allowExportSelectedData={false}
            hideSearchPanel={true}
            hideExport={true}
            showDetailTable={false}
            dataGridRef={dataGridRef}
          />
        </div>
      ) : (
        <Spinner size="large" />
      )}
    </div>
  );
};

export default GRTReports;
