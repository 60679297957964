import React, { useState, useEffect } from 'react';
import { Popup, ToolbarItem } from 'devextreme-react/popup';

interface AlertDialogProps {
  showDialog: boolean;
  message: string | JSX.Element;
  title: string;
  onClose: () => void;
  hideAlertButton?: boolean;
  buttonText?: string;
  onOkButtonClick?: () => void; // New optional prop
}

const AlertDialog: React.FC<AlertDialogProps> = ({
  showDialog,
  message,
  title,
  onClose,
  hideAlertButton = false,
  buttonText = 'OK',
  onOkButtonClick // New optional prop
}) => {
  const [isVisible, setIsVisible] = useState(showDialog);

  useEffect(() => {
    setIsVisible(showDialog);
  }, [showDialog]);

  const closeDialog = () => {
    setIsVisible(false);
    onClose();
  };

  const handleOkButtonClick = () => {
    if (onOkButtonClick) {
      onOkButtonClick();
    }
    closeDialog();
  };

  return (
    <div>
      <Popup
        width={378}
        maxHeight={400}
        minHeight={200}
        height={'auto'}
        showTitle={false}
        showCloseButton={false}
        title={title}
        dragEnabled={false}
        hideOnOutsideClick={false}
        visible={isVisible}
        onVisibleChange={closeDialog}
      >
        <div className='popContentDiv' style={{ fontSize: '16px' }}>
          {message}
        </div>
        {!hideAlertButton && (
          <ToolbarItem
            widget="dxButton"
            toolbar="bottom"
            location="after"
            options={{
              text: buttonText,
              stylingMode: 'outlined',
              onClick: handleOkButtonClick
            }}
          />
        )}
      </Popup>
    </div>
  );
};

export default AlertDialog;